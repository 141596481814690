import { Button, Col, Row } from "react-bootstrap";
import { formatDateRange } from "../../../util/format";
import GradientDiv from "../../Gradient";
import { OfferListing } from "./OfferListing";

export const DeliveryItem = ({
  delivery,
  setActiveDelivery,
  refreshAllDeliveries
}) => {
  return (
    <GradientDiv>
      <Row className="mb-2">
        <Col xs={12}>
          <h5 className="nowrap" style={{ marginBottom: "13px" }}>
            {delivery.origin.postOffice + " - " + delivery.destination.postOffice}
            <span style={{ marginLeft: "16px", fontSize: "16px" }}>
              {formatDateRange(delivery.dateRange)}
            </span>
            <p
              style={{ fontSize: "16px", margin: 0 }}
            >{delivery.origin.street} - {delivery.destination.street}</p>
          </h5>
          <p
            style={{
              opacity: 0.6,
              fontWeight: 500
            }}
          >Tilaustunniste: {delivery.orderId}</p>
          <Button
            className="btn-primary-color"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "1rem"
            }}
            onClick={() => setActiveDelivery(delivery)}
          >
            <span className="material-symbols-outlined">
              {
                "visibility"
              }
            </span>
            {
              "Näytä"
            }
          </Button>
        </Col>
        <Col xs={12}>
          <OfferListing
            delivery={delivery}
            refreshAllDeliveries={refreshAllDeliveries}
          />
        </Col>
      </Row>
    </GradientDiv >
  );
};