// noinspection CommaExpressionJS

import create from "zustand";
import {useCallback} from "react";

const useStore = create(set => ({
  isApp: localStorage["mobile_app"] === "true",
  setIsApp: isApp => {
    localStorage["mobile_app"] = isApp;
    set({
      isApp: true
    });
  }
}));

const useAppCheck = (enableRefresh = false) => {
  const [isApp] = useStore(({isApp}) => [
    isApp
  ]);

  const setIsApp = useStore(({setIsApp}) => setIsApp);

  return useCallback(() => {
    return [isApp, setIsApp];
  }, [isApp, setIsApp])();
};

export default useAppCheck;