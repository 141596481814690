import { Row, Col } from "react-bootstrap";
import GradientDiv from "../Gradient";
import { BusinessItem } from "./BusinessItem";

export const StatusContainer = ({
  title,
  noDataText,
  type,
  businesses,
  setActiveBusiness
}) => {
  return (
    <GradientDiv
      className="main"
    >
      <h3 className="mb-4">{title}</h3>
      <Row>
        {
          businesses?.length > 0 ? (
            businesses?.map((business) => (
              <Col
                key={business._id}
                xs={12}
                sm={6}
                lg={4}
              >
                <BusinessItem
                  key={business._id}
                  type={type}
                  business={business}
                  setActiveBusiness={setActiveBusiness}
                />
              </Col>
            ))
          ) : (
            <p>{noDataText}</p>
          )
        }
      </Row>
    </GradientDiv>
  );
};