import useToken from "./useToken";

export const useAuth = () => {
  const [token] = useToken();

  return {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  };
};