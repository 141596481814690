import { useEffect, useState } from "react";
import { Stack } from "react-bootstrap";
import { useUserContext } from "../../../../contexts/UserContext";
import { useOrganizationService } from "../../../../hooks/services/useOrganizationService";
import GradientDiv from "../../../Gradient";
import { OrganizationInvitation } from "./OrganizationInvitation";

export const JoinStep = ({
  register,
  backButton
}) => {
  const [invitations, setInvitations] = useState([]);

  const { user } = useUserContext();
  const organizationService = useOrganizationService();

  useEffect(() => {
    const getInvitations = async () => {
      const resp = await organizationService.getInvitations({ phoneNumber: user.phoneNumber });
      setInvitations(resp);
    };

    if(user) {
      const interval = setInterval(() => {
        getInvitations();
      }, 2000);
      return () => clearInterval(interval);
    }
  }, [user]);

  return (
    <Stack>
      <GradientDiv>
        <h4>Liity kutsulla</h4>
        {
          invitations.length > 0 ?
            invitations.map((invite) => (
              <OrganizationInvitation
                key={invite._id}
                invite={invite}
              />
            ))
            : <p>Ei kutsuja</p>
        }
      </GradientDiv>
      {/*
      <GradientDiv>
        <h4>Liity koodilla</h4>
        <Form.Group className="mb-3">
          <Form.Label htmlFor="invitationCode">Anna liittymiskoodi</Form.Label>
          <InputGroup className="mb-4" md="2">
            <FormControl
              id="invitationCode"
              {...register("joinCode")}
              size="lg"
            />
          </InputGroup>
        </Form.Group>
        <Row style={{ justifyContent: "space-between" }}>
          {backButton()}

          <Col xs={12} xl={5}>
            <Button
              className="btn-primary-color wizard-button w-100"
            >
              Liity
            </Button>
          </Col>
        </Row>
      </GradientDiv>
      */}
    </Stack>
  );
};