import "./MobileNavBar.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import useToken from "../hooks/useToken";
import { getJwtRole } from "../util/jwt";
import { NavLinksAdmin } from "./admin/NavLinksAdmin";
import { NavLinksConsumer } from "./consumer/NavLinks";
import { NavLinksCourier } from "./courier/NavLinks";
import { Link } from "react-router-dom";

export const MobileNavBar = () => {
  const [token] = useToken();
  const role = getJwtRole(token);

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      collapseOnSelect={true}
      style={{ marginBottom: "1rem" }}
    >
      <Container>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {
              role === "courier" ?
                <NavLinksCourier
                  mobile={true}
                /> : null
            }
            {
              role === "customer" ?
                <NavLinksConsumer
                  mobile={true}
                /> : null
            }
            {
              role === "admin" ?
                <NavLinksAdmin
                  mobile={true}
                /> : null
            }
            {
              !token ?
                <>
                  <Nav.Link
                    className="sidebar-item mobile-nav-item"
                    as={Link}
                    to="register"
                    href="register"
                  >
                    Rekisteröidy
                  </Nav.Link>
                  <Nav.Link
                    className="sidebar-item mobile-nav-item"
                    as={Link}
                    to="login"
                    href="login"
                  >
                    Kirjaudu sisään
                  </Nav.Link>
                </>
                :
                <>
                  <hr
                    style={{ color: "white", margin: "6px" }}
                  />
                  <Nav.Link
                    className="sidebar-item mobile-nav-item"
                    as={Link}
                    to="logout"
                    href="logout"
                  >
                    Kirjaudu ulos
                  </Nav.Link>
                </>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};