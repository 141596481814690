import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

export function NavLinksAdmin({ mobile }) {
  return (
    <>
      <Nav.Link
        className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
        as={Link} to="admin/users"
        href="adminUsers"
      >
        {
          !mobile &&
          <span className="material-symbols-outlined sidebar-item-icon">account_circle</span>
        }
        <span className={mobile ? "" : "sidebar-item-text"}>Käyttäjät</span>
      </Nav.Link>
      <Nav.Link
        className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
        as={Link} to="admin/deliveries"
        href="adminDeliveries"
      >
        {
          !mobile &&
          <span className="material-symbols-outlined sidebar-item-icon">list</span>
        }
        <span className={mobile ? "" : "sidebar-item-text"}>Kuljetukset</span>
      </Nav.Link>
    </>
  );
}