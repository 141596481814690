import {useUserContext} from "../../contexts/UserContext";
import { VAT } from "../../util/constants";

const vatStyle = {
  fontSize: 12,
  fontStyle: "italic"
};

export function CurrencyWithTax () {
  const {user} = useUserContext();

  let vatText;
  if(user.customerType === "person") {
    vatText = ` sis. alv ${VAT}%`;
  } else {
    vatText = " alv 0%";
  }
  
  return (
    <span>€
      <span style={vatStyle}>{vatText}</span>
    </span>
  );
}

export function AmountWithTax ({amt, floor}) {
  const {user} = useUserContext();

  const result = getAmountWithTax(amt, user);

  if(floor) {
    return Math.floor(result);
  } else {
    return result;
  }
}

export function getAmountWithTax(amt, user) {
  if(user.customerType === "person") {
    const taxMultiplier = 1 + VAT / 100;
    return Math.round(amt * taxMultiplier) / 100 ;
  } else {
    return amt / 100;
  }
}

export function RemovePotentialTax (amt, user) {
  if(user.customerType === "person") {
    const taxDivider = 1 + VAT / 100;
    return Math.round(amt / taxDivider * 100) / 100;
  } else {
    return amt;
  }
}