import {Container, Nav, Navbar} from "react-bootstrap";
import {Link} from "react-router-dom";
import useToken from "../hooks/useToken";


export default function NavigationBar() {
  const [token] = useToken();

  return (
    <Navbar className="navbar" expand="xxl">
      <Container className="justify-content-end">
        <Nav style={{flexDirection: "row"}}>
          {
            !token ?
              <>
                <Nav.Link
                  as={Link}
                  to="register"
                  href="register"
                  style={{paddingLeft: "2rem"}}>Rekisteröidy</Nav.Link>
                <Nav.Link
                  as={Link}
                  to="login"
                  href="login"
                  style={{whiteSpace: "nowrap", paddingLeft: "2rem"}}>
                  Kirjaudu sisään
                </Nav.Link>
              </>
              :
              <Nav.Link
                as={Link}
                to="logout"
                href="logout"
                style={{whiteSpace: "nowrap", paddingLeft: "2rem"}}>
                Kirjaudu ulos
              </Nav.Link>
          }
        </Nav>
      </Container>
    </Navbar>
  );
}