import axios from "axios";
import { useCache } from "../useCache";
import { useCallback } from "react";
import { useAuth } from "../useAuth";

/**
 * @typedef {{
 * name: string,
 * phoneNumber: string,
 * email: string,
 * password: string,
 * password2: string
 * }} NewCourierData
 */

/**
 * @typedef {{
 *   name: string=,
 *   phoneNumber: string=,
 *   email: string=,
 * }} CourierData
 */

/**
 * @typedef {{
 *   email: string=,
 *   password: string=
 * }} CourierLoginForm
 */


export const useCourierService = () => {
  const auth = useAuth();
  const basePath = "/api/couriers";

  const getCouriersByIds = useCache(basePath, "couriers").current;

  return useCallback(() => ({
    create: async data => (await axios.post(basePath, data))?.data,
    update: async data => (await axios.patch(basePath, data, auth))?.data,
    login: async data => (await axios.post(`${basePath}/login`, data))?.data,
    verify: async (verifyType, hash) => await axios.get(`${basePath}/verify/${verifyType}/${hash}`),
    renewVerify: async data => (await axios.post(`${basePath}/renew-verify`, data))?.data,
    renewVerifyWithCreds: async verifyType => (
      await axios.post(`${basePath}/renew-verify-with-creds`, { verifyType }, auth)
    )?.data,
    forgotPassword: async data => (await axios.post(`${basePath}/forgot-password`, data))?.data,
    recoverPassword: async data => (await axios.post(`${basePath}/recover-password`, data))?.data,
    getCouriersByIds,
    profile: async () => (await axios.get(`${basePath}/profile`, auth))?.data,
    deleteAccount: async (password) =>
      (await axios.post(`${basePath}/delete-account`, {password}, auth))?.data,
  }), [getCouriersByIds, auth])();
};