import { useState, useEffect } from "react";
import { Row, Form, FormControl, InputGroup } from "react-bootstrap";
import {
  AddressInput,
  GoodsInput,
  OffloadingInput,
  PriceInput,
  WaybillInput,
  DateRangeInputs
} from "./CreateDeliveryFormElements";
import "./wizard.css";
import { FormErrorMessage } from "../../form/FormErrorMessage";
import { useUserContext } from "../../../contexts/UserContext";

const commonTabs = [
  (props) => (
    <Form>
      <h3>Mistä haluat lähettää paketin?</h3>
      <AddressInput
        key="1"
        title="origin"
        {...props}
      />
    </Form>
  ),
  (props) => (
    <Form>
      <h3>Minne haluat lähettää paketin?</h3>
      <AddressInput
        key="2"
        title="destination"
        {...props}
      />
    </Form>
  ),
  (props) => (
    <Form>
      <h3>Lisää lähetettävät tuotteet</h3>
      <p>
        Lisää tuotteet riveille mahdollisimman tarkasti.
        Nimi, koko, paino ja määrä.
        Huomioi tietojen oikeellisuus.
        Lisätietoihin voit lisätä tarkempia
        tietoja tuotteesta tai kuljetuksesta.
        Järjestelmä laskee automaattisesti
        tuotteiden kokonaismitat yhteen.
      </p>
      <p>
        Huomaathan
        että seuraavia tuotteita emme voi
        hyväksyä lähetettäväksi:
      </p>
      <p>
        Räjähteet; mm. aseet, ilotulitusvälineet,
        nallit, panokset yms.
        Kaasut; mm. helium, kaasupullot, mm. muut
        paineistetut pullot.
        Palavat nesteet; mm. bensiini, asetoni,
        metanoli, tärpätti, tinneri
        Myrkylliset, radioaktiiviset ja syövyttävät
        aineet; mm. syanidi, elohopea, happo, emäs,
        rikkihappo, uraani, asbesti, kuivajää.
        Huumeet; kaikki huumeiksi luokiteltavat
        aineet sekä niiden käyttöön tarkoitetut
        välineet.
        Eläimet; kuolleet tai elävät eläimet.
        Muut laittomat tuotteet; sellaiset tuotteet
      </p>
      <GoodsInput {...props} />
    </Form>
  ),
  (props) => (
    <Form>
      <h3>Millä aikavälillä haluat tuotteen toimituksen?</h3>
      <p>
        Merkitse toivottu toimitusaika.
        Tuotteiden tulee olla lähtövalmiina
        tuolloin. Huomioithan että laittamalla
        enemmän aikaa mahdollistat
        useamman tarjouksen.
      </p>
      <DateRangeInputs {...props} />
    </Form>
  ),
  (props) => (
    <Form>
      <h3>Miten kuorma puretaan?</h3>
      <p>
        Valitse kuorman purkutapa.
        Voit myös vastata "ei tietoa" mikäli
        et tiedä sopivaa purkutapaa.
      </p>
      <OffloadingInput {...props} />
    </Form>
  ),
  (props) => (
    <Form>
      <h3>Hintaehdotuksesi</h3>
      <p>
        Voit jättää tähän hintatoiveesi.

        Kuljetusyritykset voivat lähettää
        vastatarjouksia ehdotukseesi.

        Kentän voi jättää myös tyhjäksi.
      </p>
      <PriceInput {...props} />
    </Form>
  ),
  (props) => (
    <Form>
      <h3>Lisää sekä omasi että vastaanottajan yhteystiedot</h3>
      <p>
        Muista tarkistaa tietojen oikeellisuus!
      </p>
      <Form.Group className="mb-2">
        <Form.Label htmlFor="senderPhonenumber">Lähettäjän puhelinnumero</Form.Label>
        <InputGroup>
          <FormControl
            id="senderPhonenumber"
            type="text"
            isInvalid={props.errors.senderPhonenumber}
            onInput={() => props.clearErrors("senderPhonenumber")}
            {...props.register("senderPhonenumber", {
              required: "Vaadittu kenttä"
            })}
          />
        </InputGroup>
        <FormErrorMessage
          error={props.errors.senderPhonenumber}
          errorMessage={props.errors.senderPhonenumber?.message}
        />
      </Form.Group>

      <Form.Group className="mb-2">
        <Form.Label htmlFor="recipientPhonenumber">Vastaanottajan puhelinnumero</Form.Label>
        <InputGroup>
          <FormControl
            id="recipientPhonenumber"
            type="text"
            isInvalid={props.errors.recipientPhonenumber}
            onInput={() => props.clearErrors("recipientPhonenumber")}
            {...props.register("recipientPhonenumber", {
              required: "Vaadittu kenttä"
            })}
          />
        </InputGroup>
        <FormErrorMessage
          error={props.errors.recipientPhonenumber}
          errorMessage={props.errors.recipientPhonenumber?.message}
        />
      </Form.Group>
    </Form>
  ),
  (props) => (
    <Form>
      <h3>Lisätiedot</h3>
      <p>
        Kerro tähän vielä lisätietoja mitkä liittyvät
        kuljetukseesi.
      </p>
      <Form.Label htmlFor="info">Lisätiedot</Form.Label>
      <Form.Control
        as="textarea"
        rows={3}
        {...props.register("info")}
        disabled={props.disabledFields.includes("info")}
      />
    </Form>
  ),
  /*() => <>
    <h3>Asiakastiedot</h3>
    <ConsumerRegister mode={Modes.LIGHT} />
  </>,*/
  (props) => (
    <>
      <h3>Huom.</h3>
      <p>
        Saat sähköpostiisi ilmoitukset
        saapuneista kuljetustarjouksista.
        Tarjoukselta näet kuljettajan tarjoaman
        hinnan, noutopäivän sekä mahdolliset
        lisätiedot koskien kuljetusta.
        Valitse itsellesi sopivin tarjous hyväksymällä se.
      </p><p>
        Kuljetusliike saa
        ilmoituksen hyväksytystä kuljetuksesta ja
        kuljettaja saa itselleen tilaajan ja
        vastaanottajan puhelinnumerot. Myös
        sinulle tulee sähköpostiin tieto
        kuljetusliikkeestä, sekä kuljettajan
        puhelinnumero.
        Huolehdi että tuotteet
        on asianmukaisesti pakattuna ja valmiina
        nostettavaksi auton kyytiin sovittuna
        ajankohtana.
      </p>
      <p>
        Seuraavaksi näet vielä yhteenvedon tilauksestasi
      </p>
    </>
  ),
  () => <></>
];

const companyTabs = [
  (props) => (
    <Form>
      <h3>Rahtikirjan numero tai viitteenne</h3>
      <p>
        Voit antaa kuljetukselle rahtikirjan numeron tai haluamanne laskun viitteen

        Kentän voi jättää myös tyhjäksi.
      </p>
      <WaybillInput {...props} />
    </Form>
  )
];

export default function Wizard(props) {
  const { user } = useUserContext();
  const [tabs, setTabs] = useState([]);
  const [tab, setTab] = useState(0);

  useEffect(() => {
    if (props.fields.length === 0) {
      props.append({});
    }
  }, []);

  useEffect(() => {
    console.log(tab + "/" + tabs.length);
    if (tab === tabs.length - 1) {
      props.shutWizard();
    }
  }, [tab]);

  useEffect(() => {
    const setWizardTabs = () => {
      setTabs(user.customerType === "company" ?
        companyTabs.concat(commonTabs) : commonTabs);
    };
    if (user) setWizardTabs();
  }, [user]);

  const changeTab = async (e, num) => {
    e.preventDefault();
    const result = await props.triggerValidation();
    if (result)
      setTab(tab + 1);
  };

  return (
    <div className="wizard">
      <Row style={{ justifyContent: "center" }}>
        <div className="col-sm-8 col-md-7 mb-3">
          {tabs.length > 0 && tabs[tab](props)}
          <button
            type="submit"
            onClick={(evt) => changeTab(evt)}
            className="btn-primary-color wizard-button col-12 col-xl-5"
            id="wizard-forward"
          >
            <span>Eteenpäin</span>
            <span className="material-symbols-outlined">chevron_right</span>
          </button>
          {
            tab !== 0 ?
              <button
                onClick={() => setTab(tab - 1)}
                className="btn-primary-color wizard-button col-12 col-xl-5"
                id="wizard-back"
              >
                <span className="material-symbols-outlined">chevron_left</span>
                <span>Taaksepäin</span>
              </button> :
              null
          }
        </div>
      </Row>
    </div>
  );
}