import { Button, Col, Row } from "react-bootstrap";
import GradientDiv from "../Gradient";
import moment from "moment";

export const SuggestedTransports = ({
  delivery,
  matchingCourierDeliveries,
  setActiveDelivery,
  setActiveCourierDelivery,
  setActiveCounterOffer
}) => {
  const matchingDeliveries = matchingCourierDeliveries[delivery.id] ?? [];

  return (
    <Col xs={12} className="mt-4">
      <h5 className="nowrap">Ehdotetut kuljetukset:</h5>
      <Row>
        {
          matchingDeliveries.length > 0 ? (
            matchingDeliveries.map((courierDelivery, index) => {

              const foundRequest = courierDelivery.deliveryRequests.find((request) =>
                request.deliveryId === delivery.id);

              return (
                <Col
                  key={index}
                  xs={12}
                  sm={6}
                  lg={4}
                >
                  <GradientDiv>
                    <p>Yritys: {courierDelivery.courier.organization.name}</p>
                    <p>{`${courierDelivery.origin} - ${courierDelivery.destination}`}</p>
                    <p>
                      {
                        moment(courierDelivery.deliveryDate)
                          .format("D.M.YYYY")
                      }
                    </p>
                    <p>max.
                      {" " + courierDelivery.dimensions.x}m x
                      {" " + courierDelivery.dimensions.x}m x
                      {" " + courierDelivery.dimensions.z}m ja
                      {" " + courierDelivery.weight}kg
                    </p>
                    <Button
                      id="sendRequest"
                      className="btn-primary-color"
                      active={foundRequest ? true : false}
                      onClick={
                        foundRequest
                          ? foundRequest.status === "WAITING"
                            ? () => setActiveCounterOffer(foundRequest.counterOffer)
                            : null
                          : () => {
                            setActiveCourierDelivery(courierDelivery);
                            setActiveDelivery(delivery);
                          }}
                    >
                      {foundRequest
                        ? foundRequest.status === "OPEN"
                          ? "Pyyntö lähetetty"
                          : "Vastatarjous saatu"
                        : "Lähetä kuljetuspyyntö"}
                    </Button>
                  </GradientDiv>
                </Col>
              );
            }
            ))
            : (
              <p>Sopivia kuljetuksia ei löytynyt</p>
            )
        }
      </Row>
    </Col>
  );
};