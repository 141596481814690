import {useNavigate} from "react-router-dom";
import {useCallback} from "react";
import {fitPoints} from "../../../util/maps";
import {Col, Row} from "react-bootstrap";
import SearchBar from "./SearchBar";
import DeliveryList from "./DeliveryList";
import useAppCheck from "../../../hooks/useAppCheck";

export default function CourierSearchListing(
  {
    coordinates,
    highlightCoordinates, setHighlightCoordinates,
    setHighlightedMarkerPosition,
    searchBounds,
    deliveries,
    mapRef
  }) {
  const navigate = useNavigate();

  /*
    Runs when hovering on a delivery listing
   */
  const onDeliveryHover = useCallback((delivery, mouseOn) => {
    const {origin: {location: {coordinates: [longitude, latitude]}}} = delivery;
    if (mouseOn) {
      setHighlightedMarkerPosition({latitude, longitude});
    } else {
      setHighlightedMarkerPosition(
        old => {
          if(!old) {
            return null;
          }
          const {latitude: oldLatitude, longitude: oldLongitude} = old;
          if (oldLatitude === latitude && oldLongitude === longitude) {
            return null;
          }

          return old;
        }
      );
    }
  }, [setHighlightedMarkerPosition]);

  /*
    Runs when user clicks "Show on map"
   */
  const onClickLocate = useCallback((
    {origin: {location: {coordinates: [longitude, latitude]}}}, e
  ) => {
    fitPoints(mapRef.current, [{latitude, longitude}]);

    // Show "animation" by highlighting the located listing
    setHighlightCoordinates({latitude, longitude});

    window.setTimeout(() => {
      // Require that at least 50% of the listing is visible when locating
      // if not, go to anchor #search at the beginning of the page
      const searchY = document.getElementById("deliveryList").getBoundingClientRect().y;
      const listingHeight = e.target.closest(".listing").getBoundingClientRect().height;
      if (2 * searchY + listingHeight < 0) {
        navigate("/courier/search#search");
      }

      window.setTimeout(() => {
        setHighlightCoordinates(old => {
          if(!old) {
            return null;
          }
          const {latitude: oldLatitude, longitude: oldLongitude} = old;
          if (oldLatitude === latitude && oldLongitude === longitude) {
            return null;
          }

          return old;
        });
      }, 1500);
    }, 500);

  }, [mapRef, navigate, setHighlightCoordinates]);

  const setActiveDelivery = useCallback(({orderId}) => {
    navigate(`/courier/search/${orderId}`);
  }, [navigate]);

  const [isMobileApp] = useAppCheck();

  return <>
    {
      !isMobileApp?
        <Row>
          <SearchBar doSearch={() => {
          }} style={{
            visibility: "hidden",
            borderRadius: 5,
          }}/>
        </Row>
        :
        null
    }
    <Col
      xl={6}
      id="deliveryList"
      style={{
        paddingTop: 10,
      }}
    >
      <DeliveryList
        center={coordinates ?? {latitude: 64.5, longitude: 26}}
        searchBounds={searchBounds}
        deliveries={deliveries}
        setActiveDelivery={setActiveDelivery}
        highlightCoordinates={highlightCoordinates}
        onDeliveryHover={onDeliveryHover}
        onClickLocate={onClickLocate}
      />
    </Col>
    <Col xl={6}/>
  </>;
};

