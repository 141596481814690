import {useState, useEffect} from "react";
import { useCourierService } from "../../../hooks/services/useCourierService";
import CourierRegister, { CourierModes } from "../../register/CourierRegister";
import { useUserContext } from "../../../contexts/UserContext";
import CompanyInfo from "./CompanyInfo";

export default function Profile(props) {
  const courierService = useCourierService();
  const [defaultValues, setDefaultValues] = useState();
  const { user } = useUserContext();

  useEffect(() => {
    const getDefaultValues = async () => {
      setDefaultValues(await courierService.profile());
    };

    getDefaultValues();
  }, []);

  return (
    <div className="p-2">
      {
        user &&
        user.organization.couriers.find(courier => courier.courier === user._id).role === "user" ?
          <CompanyInfo organization={user.organization}/> :
          null
      }
      <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3">
        <h3>Muokkaa tietojasi</h3>
      </div>
      {defaultValues &&
        <CourierRegister mode={CourierModes.EDIT} defaultValues={defaultValues}/>
      }
    </div>
  );
}