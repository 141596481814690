import { Button, Col, ListGroup, Row } from "react-bootstrap";
import GradientDiv from "../../Gradient";
import { formatDate, formatDateRange } from "../../../util/format";
import { DeliveryStatus } from "../../../util/constants";
import moment from "moment";
import React from "react";
import { CurrencyWithTax, AmountWithTax } from "../../common/PriceComponents";

export const DeliveryItem = ({
  delivery,
  setActiveDelivery
}) => {
  const showCourierInfo = delivery.status !== DeliveryStatus.OPEN;

  let dateString;
  if (delivery.status === DeliveryStatus.OPEN) {
    dateString = formatDateRange(delivery.dateRange);
  } else if (delivery.status === DeliveryStatus.ACCEPTED) {
    dateString = formatDate(delivery.accepted.date);
  } else if (delivery.status === DeliveryStatus.DELIVERED) {
    dateString = formatDateRange(
      { from: delivery.accepted.date, to: delivery.confirmedDeliveryDate }
    );
  }

  return (
    <GradientDiv>
      <Row className="mb-2" id={"delivery-" + delivery._id}>
        <Col>
          <h5 className="nowrap" style={{ marginBottom: "13px" }}>
            {delivery?.origin?.postOffice + " - " + delivery?.destination?.postOffice}
            <span style={{ marginLeft: "16px", fontSize: "16px" }}>{dateString}</span>
          </h5>
          <p
            style={{
              opacity: 0.6,
              fontWeight: 500
            }}
          >Tilaustunniste: {delivery.orderId}</p>
          {
            showCourierInfo && (
              <p>
                <b>Kuljettaja</b><br />
                {delivery.accepted.courier?.organization?.name}<br />
                {delivery.accepted.courier?.firstName} {delivery.accepted.courier?.lastName}<br />
                +{delivery.accepted.courier?.phoneNumber}<br />
                {delivery.accepted.courier?.email}<br />
                {delivery.accepted.courier?.organization?.address}
              </p>
            )
          }
        </Col>
      </Row>
      <Row>
        <Col lg={2} className="mb-3">
          <Button
            className="btn-primary-color"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "1rem"
            }}
            onClick={() => setActiveDelivery(delivery)}>
            <span className="material-symbols-outlined">
              visibility
            </span>
            Näytä
          </Button>
        </Col>
      </Row>
      {
        (() => {
          const from = moment(delivery.dateRange.from);
          const to = moment(delivery.dateRange.to);

          const counterOffers = (delivery?.counterOffers ?? []).map(offer => {
            if (offer.amount > delivery.price) {
              return offer;
            }

            const date = moment(offer.date);

            if (!date.isSameOrAfter(from) || !date.isSameOrBefore(to)) {
              return offer;
            }

            return { ...offer, matchesListing: true };
          });

          counterOffers.sort((a, b) => {
            if (a.matchesListing === b.matchesListing) {
              return moment(a.date).toDate().getTime() - moment(b.date).toDate().getTime();
            }

            if (a.matchesListing) {
              return -1;
            } else {
              return 1;
            }
          });

          return <>
            <Row>
              <Col xs={12}>
                <h5 className="nowrap">Vastatarjoukset:</h5>
                {
                  counterOffers.length > 0 ?
                    <ListGroup>
                      {
                        counterOffers.map((offer, index) =>
                          <React.Fragment
                            key={index}
                          >
                            <ListGroup.Item>
                              <p>Yritys: {offer?.courier?.organization?.name}</p>
                              <p
                                style={{
                                  display: "inline"
                                }}
                              >
                                {offer.matchesListing && (
                                  <span style={{
                                    color: "#12C312",
                                    marginRight: 7,
                                    display: "inline"
                                  }}>
                                    Ilmoituksen mukainen
                                  </span>)
                                }
                                {
                                  moment(offer.date).format("D.M.YYYY")
                                } - <AmountWithTax amt={offer.amount} />
                                <CurrencyWithTax />
                              </p>
                            </ListGroup.Item>
                          </React.Fragment>
                        ) ?? []
                      }
                    </ListGroup>
                    :
                    <>
                      <h6 className="px-3">Ei tarjouksia</h6>
                    </>
                }
              </Col>
            </Row>
          </>;
        })()
      }
    </GradientDiv >
  );
};
