import ConsumerRegister, { Modes } from "../register/ConsumerRegister";
import { useParams } from "react-router-dom";
import CourierRegister, { CourierModes } from "../register/CourierRegister";

export default function RecoverPassword() {
  const { userType, hash } = useParams();

  if (hash) {
    return (
      userType === "customer" ? (
        <ConsumerRegister mode={Modes.PASSWORD} recoverPasswordHash={hash} />
      )
        : (
          <CourierRegister mode={CourierModes.PASSWORD} recoverPasswordHash={hash} />
        )
    );
  }
}