import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";

export function SubmitButton(props) {

  const { formState, submitValue, successValue, disabled, icon } = props;
  const { isSubmitSuccessful, isSubmitting, errors } = formState;

  useEffect(() => {
    if(!isSubmitting && isSubmitSuccessful && successValue) {
      toast.success(successValue);
    }
  }, [isSubmitting, isSubmitSuccessful, successValue]);

  return (
    <>
      <button
        type="submit"
        className="btn-primary-color"
        disabled={isSubmitting || errors.submit || disabled}
        style={{ display: "flex", justifyContent: "center", alignItems: "center", float: "right" }}
      >
        {icon}
        {!isSubmitting ? submitValue : <Spinner animation="border" size="sm" />}
      </button>
    </>
  );
}