import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import UserProvider from "./contexts/UserContext";
import { GoogleOAuthProvider } from "@react-oauth/google";
import VerifyContextProvider from "./contexts/VerifyContext";
import LoadingProvider from "./contexts/LoadingContext";

Modal.setAppElement("#root");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <LoadingProvider>
    <UserProvider>
      <VerifyContextProvider>
        <BrowserRouter>
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          >
            <App />
          </GoogleOAuthProvider>
        </BrowserRouter>
      </VerifyContextProvider>
    </UserProvider>
  </LoadingProvider >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
