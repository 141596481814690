export const extendBounds = (bounds, minSize) => {
  const center = bounds.getCenter();

  // Hacky solution to prevent zooming too close (WTF?)
  const spherical = window.google.maps.geometry.spherical;
  const [ne, sw] = [bounds.getNorthEast(), bounds.getSouthWest()];
  const [width, height] = [
    spherical.computeDistanceBetween(new window.google.maps.LatLng(ne.lat(), sw.lng()), ne),
    spherical.computeDistanceBetween(new window.google.maps.LatLng(sw.lat(), ne.lng()), ne)
  ];


  if(width < minSize) {
    const left = spherical.computeOffset(center, minSize / 2, 270);
    const right = spherical.computeOffset(center, minSize / 2, 90);
    bounds.extend(left);
    bounds.extend(right);
  }

  if(height < minSize) {
    const up = spherical.computeOffset(center, minSize / 2, 0);
    const down = spherical.computeOffset(center, minSize / 2, 180);
    bounds.extend(up);
    bounds.extend(down);
  }
};

export const fitPoints = (map, points) => {
  const bounds = new window.google.maps.LatLngBounds();

  points.forEach(
    ({latitude, longitude}) => bounds.extend(new window.google.maps.LatLng(latitude, longitude))
  );

  extendBounds(bounds, 5000);

  map.fitBounds(bounds);
};