import {useState, useEffect} from "react";
import {useSearchParams, useNavigate} from "react-router-dom";
import {Container} from "react-bootstrap";
import {usePaymentService} from "../../../hooks/services/usePaymentService";

export default function PaymentStatus(props) {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState();

  const paymentService = usePaymentService();

  useEffect(() => {
    if(loading) {
      return;
    }
    const getStatus = async () => {
      const query = Object.fromEntries(searchParams);
      setStatus(await paymentService.updateStatus(query));
    };

    if(!status) {
      setLoading(true);
      getStatus().finally(() => setLoading(false));
    }
  }, [loading, searchParams]);

  return (
    <Container>
      <div>
        <div style={{textAlign: "center"}}>
          {status ? status.message : "Ladataan..."}
          <br/>
          {status &&
            <div style={{marginBottom: 16}}>
              {status.successful ?
                <span
                  style={{color: "green", fontSize: 64}}
                  className="material-symbols-outlined"
                >done</span> :
                <span
                  style={{color: "red", fontSize: 64}}
                  className="material-symbols-outlined"
                >cancel</span>
              }
              <br/>
              <button
                onClick={() => navigate("/consumer/deliveries")}
                className="mt-3 btn-primary-color"
              >
                Palaa kuljetuksiin
              </button>
            </div>
          }
        </div>
      </div>
    </Container>
  );
}