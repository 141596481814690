import { useAuth } from "../useAuth";
import axios from "axios";

export const useCourierDeliveryService = () => {
  const auth = useAuth();
  const basePath = "/api/courierDeliveries";

  return {
    getAll: async () => (await axios.get(basePath, auth))?.data,
    create: async data => (await axios.post(basePath, data, auth))?.data,
    modify: async (id, delivery) => (await axios.put(`${basePath}/${id}`, delivery, auth))?.data,
    delete: async id => (await axios.delete(`${basePath}/${id}`, auth))?.data,
    getByDeliveryIds: async ids => (
      await axios.post(`${basePath}/getmatchingdeliveries`, {ids: ids}, auth)
    )?.data,
    requestDelivery: async (courierDeliveryId, deliveryId) => (
      await axios.post(`${basePath}/${courierDeliveryId}/requestdelivery/${deliveryId}`, {}, auth)
    )?.data,
    rejectRequest: async (id, requestId, rejectReason) => (
      await axios.post(`${basePath}/${id}/rejectrequest/${requestId}`, rejectReason, auth)
    )?.data,
  };
};