import {useState} from "react";
import {Button, Form, InputGroup} from "react-bootstrap";

export const SearchInput = (
  {
    placeholder,
    handleSearch,
    upperCase,
    style
  }) => {
  const [searchValue, setSearchValue] = useState("");
  const [statusFilter, setStatusFilter] = useState(void 0);

  return (
    <Form
      onSubmit={(evt) =>
        handleSearch(evt, searchValue, {status: statusFilter})}
      style={style}
    >
      <InputGroup>
        <Form.Control
          placeholder={placeholder}
          value={searchValue}
          onChange={(e) => {
            const {value} = e.target;
            setSearchValue(upperCase ? value.toUpperCase() : value);
          }}
        />
        <Button
          type="submit"
          id="admin-search-submit"
        >
          <span className="material-symbols-outlined icon-button">search</span>
        </Button>
      </InputGroup>
      <InputGroup>
        {
          [
            {
              label: "Kaikki",
              value: void 0,
            },
            {
              label: "Avoimet",
              value: "OPEN",
            },
            {
              label: "Hyväksytyt",
              value: "ACCEPTED",
            },
            {
              label: "Toimitetut",
              value: "DELIVERED",
            },
          ].map(({label, value}, i) =>
            <Form.Check
              key={i.toString()}
              inline label={label}
              name="group1"
              type={"radio"}
              value={""}
              checked={value === statusFilter}
              onChange={e => {
                if(e.target.checked) {
                  setStatusFilter(value);
                }
              }}
            />
          )
        }
      </InputGroup>
    </Form>
  );
};