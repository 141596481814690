import {useState} from "react";
import {useOnError} from "../../../hooks/useOnError";
import {useDeliveryService} from "../../../hooks/services/useDeliveryService";
import {useDebounce} from "../../../hooks/useDebounce";
import {DeliveryListings} from "../DeliveryListings";
import {Col} from "react-bootstrap";
import {useUpdateEffect} from "../../../hooks/useUpdateEffect";
import {Paginator} from "../../Paginator";

export default function DeliveryList(props) {
  const onError = useOnError();
  const deliveryService = useDeliveryService();
  const PAGE_SIZE = 6;

  const {
    deliveries, setActiveDelivery, center, searchBounds: {ne: neCorner, sw: swCorner} = {
      ne: {latitude: 90, longitude: 180}, sw: {latitude: -90, longitude: -180}
    },
    highlightCoordinates, onDeliveryHover, onClickLocate
  } = props;

  const [listingResultsIds, setListingResultsIds] = useState([]);
  const [listingResultsDisplay, setListingResultsDisplay] = useState(null);

  const pageCount = Math.ceil(listingResultsIds.length / PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(1);

  const debouncedSearch = useDebounce(async () => {
    try {
      const centerLatLng = new window.google.maps.LatLng(center.latitude, center.longitude);

      const ids = deliveries.filter(d =>
        swCorner.longitude <= d.longitude && d.longitude <= neCorner.longitude
        && swCorner.latitude <= d.latitude && d.latitude <= neCorner.latitude
      )
        .map(d => ({
          ...d,
          distanceFromCenter: window.google.maps.geometry.spherical.computeDistanceBetween(
            centerLatLng,
            new window.google.maps.LatLng(d.latitude, d.longitude)
          )
        }))
        .sort(({distanceFromCenter: a}, {distanceFromCenter: b}) => a - b)
        .map(d => d._id);

      setCurrentPage(1);
      setListingResultsIds(ids);
    } catch (e) {
      onError(e);
    }
  }, 500);

  useUpdateEffect(([debouncedSearch]) => () => {
    debouncedSearch();
  }, [debouncedSearch], [props.searchBounds, props.deliveries]);

  useUpdateEffect(([listingResultsIds, currentPage]) => _ => {
    if (!(listingResultsIds?.length > 0)) {
      setListingResultsDisplay([]);
      return;
    }

    let canceled = false;
    (async () => {
      const ids = listingResultsIds.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);
      if (!canceled) {
        setListingResultsDisplay(await deliveryService.getDeliveriesByIds(ids));
      }
    })();
    return () => {
      canceled = true;
    };
  }, [listingResultsIds, currentPage, deliveryService], [listingResultsIds, currentPage]);

  return <div
    style={{
      display: "flex",
      flex: 1,
      flexDirection: "column",
    }}
  >
    <div
      style={{
        width: "100%",
        flex: 1
      }}
    >
      {
        (!deliveries || !listingResultsDisplay) ? <Col style={{
          textAlign: "center"
        }}>
          <h4>Ladataan...</h4>
        </Col> : (
          listingResultsDisplay?.length > 0 ?
            listingResultsDisplay
              .filter(delivery => !!delivery)
              .map(delivery => <DeliveryListings
                key={delivery._id}
                delivery={delivery}
                setActiveDelivery={setActiveDelivery}
                highlight={highlightCoordinates && (
                  (
                    /* Compare highlightCoordinates and delivery origin coordinates
                    * and return true if they are equal */
                    {latitude: hiLat, longitude: hiLon},
                    {origin: {location: {coordinates: [deLon, deLat]}}}
                  ) => hiLat === deLat && hiLon === deLon)(highlightCoordinates, delivery)}
                onMouseEnter={() => onDeliveryHover(delivery, true)}
                onMouseLeave={() => onDeliveryHover(delivery, false)}
                onClickLocate={e => onClickLocate(delivery, e)}
              />)
            : <h4 style={{
              textAlign: "center"
            }}>Kuljetuksia ei löytynyt</h4>
        )
      }
      {pageCount > 1 && <Paginator
        pageCount={pageCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />}
    </div>
  </div>;
}