import { useContext, createContext, useState } from "react";
import { LoadingOverlay } from "../components/common/LoadingOverlay";

const LoadingContext = createContext();

function LoadingProvider(props) {

  const { children } = props;

  const [isLoading, setIsLoading] = useState([]);

  const addLoading = (id) => {
    setIsLoading((prevState) => ([
      ...prevState,
      id
    ]));
  };

  const removeLoading = (id) => {
    setIsLoading((prevState) => ([
      ...prevState.filter((loading) => loading !== id)
    ]));
  };

  return (
    <LoadingContext.Provider value={{
      isLoading,
      addLoading,
      removeLoading
    }}>
      {
        isLoading.length > 0 && (
          <LoadingOverlay />
        )
      }
      {children}
    </LoadingContext.Provider >
  );
}

export const useLoadingContext = () => {
  const context = useContext(LoadingContext);
  if (context === undefined)
    throw new Error("LoadingContext must be used within it's provider");
  return context;
};


export default LoadingProvider;