import { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Modal from "react-modal";
import { useOnError } from "../../hooks/useOnError";
import { toast } from "react-toastify";
import moment from "moment";
import { useDeliveryService } from "../../hooks/services/useDeliveryService";
import { formatPriceToEuros } from "../../util/format";
import DatePicker from "react-datepicker";
import { useUserContext } from "../../contexts/UserContext";
import { Controller, useForm } from "react-hook-form";
import { FormErrorMessage } from "../form/FormErrorMessage";
import {defaultModalStyles} from "../../styles/defaultModalStyles";
import useAppCheck from "../../hooks/useAppCheck.js";

const modalStyles = {
  content: {
    ...defaultModalStyles.content,
    width: "40%",
  }
};

export const accept = async (params) => {
  const {
    delivery,
    deliveryService,
    requestId,
    onError,
    data,
    amount,
    toastMessage,
    watch,
    close,
    refreshAllDeliveries
  } = params;
  const parsedDeliverDate = moment(watch("deliveryDate"), "YYYY-MM-DD");
  if (!parsedDeliverDate.isSameOrAfter(moment().startOf("day"))) {
    toast.error("Kuljetuksen päivämäärä ei voi olla menneisyydessä");
    return;
  }

  const { dateRange: { from, to } } = delivery;
  const parsedFrom = moment(from).startOf("day");
  const parsedTo = moment(to).startOf("day");

  if (parsedDeliverDate.isBefore(parsedFrom) || parsedDeliverDate.isAfter(parsedTo)) {
    toast.error("Valittu päivämäärä ei ole ilmoitukseen merkityllä aikavälillä");
    return;
  }

  try {
    await deliveryService.counterOffer(
      delivery.id,
      {
        courierId: data.courierId,
        date: data.deliveryDate.toISOString(),
        amount,
        updatedAt: delivery.updatedAt
      },
      requestId
    );
  } catch (e) {
    onError(e);

    if (e?.response?.status === 409) {
      alert(e?.response?.data);

      window.location.reload();
    }

    return;
  }
  close();
  toast.success(toastMessage);
  refreshAllDeliveries();
};


export const AcceptDeliveryModal = ({
  delivery,
  close,
  refreshAllDeliveries,
  requestedDeliveryDate,
  requestId,
  isOrganizationAdmin,
  organizationCouriers
}) => {
  const deliveryService = useDeliveryService();

  const onError = useOnError();
  const { user } = useUserContext();

  const {
    handleSubmit,
    formState,
    register,
    control,
    setValue,
    watch,
    clearErrors
  } = useForm({
    defaultValues:
      {}
  });

  
  const [isApp] = useAppCheck();
  if(isApp) {
    modalStyles.content.width = "90%";
  }

  useEffect(() => {
    isOrganizationAdmin && setValue("courierId", user._id);
  }, [isOrganizationAdmin]);

  useEffect(() => {
    if (requestedDeliveryDate) {
      setValue("deliveryDate", new Date(requestedDeliveryDate));
    }
  }, [requestedDeliveryDate]);

  const handleDateChange = (field, date) => {
    field.onChange(date);
  };

  const toastMessage =
    "Hyväksyit toimituksen onnistuneesti. Toimitus odottaa nyt tilaajan hyväksyntää";

  return (
    <Modal
      isOpen={delivery !== null}
      onRequestClose={close}
      style={modalStyles}
    >
      {
        delivery ?
          <Form
            onSubmit={
              handleSubmit((data) =>
                accept({
                  amount: formatPriceToEuros(delivery.price),
                  delivery,
                  deliveryService,
                  requestId,
                  onError,
                  data,
                  toastMessage,
                  watch,
                  close,
                  refreshAllDeliveries
                })
              )
            }
          >
            <Row style={{ height: "100px" }}>
              <Col className="mb-5">
                <h4>Olet hyväksymässä kuljetusta</h4>
                <p>Hyväksyminen on sitova.</p>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="deliveryDate">Kuljetuksen päivämäärä</Form.Label>
                  <Controller
                    name="deliveryDate"
                    control={control}
                    rules={{
                      required: "Vaadittu kenttä"
                    }}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          locale="fi"
                          className="form-control"
                          dateFormat="d.M.yyyy"
                          minDate={new Date()}
                          placeholderText="Valitse päivä"
                          onChange={(date) => {
                            handleDateChange(field, date);
                            clearErrors("deliveryDate");
                          }}
                          selected={field.value}
                        />
                      );
                    }}
                  />
                  <FormErrorMessage
                    error={formState.errors.deliveryDate}
                    errorMessage={formState.errors.deliveryDate?.message}
                  />
                </Form.Group>
                {
                  isOrganizationAdmin && (
                    <Form.Group className="mb-4">
                      <Form.Label htmlFor="courierId">Valitse kuljettaja</Form.Label>
                      <Form.Select
                        name="courierId"
                        {...register("courierId")}
                      >
                        {
                          organizationCouriers.map((courier) => (
                            <option
                              key={courier._id}
                              value={courier._id}
                            >
                              {courier.firstName} {courier.lastName}
                            </option>
                          ))
                        }
                      </Form.Select>
                    </Form.Group>
                  )}
              </Col>
            </Row>
            <Row style={{marginTop: 150}}>
              <Col xs={6}>
                <Button
                  id="acceptDelivery"
                  className="btn-primary-color"
                  type="submit"
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <span className="material-symbols-outlined">check</span>
                  Hyväksy
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  id="cancelDelivery" className="btn-text" onClick={close}
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <span className="material-symbols-outlined">close</span>
                  Peruuta
                </Button>
              </Col>

            </Row>
          </Form> :
          null
      }
    </Modal>
  );
};