import { useEffect, useState } from "react";
import { Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { getMunicipalities } from "../../../util/publicapis";
import {
  AddressInput,
  DateRangeInputs,
  GoodsInput,
  OffloadingInput,
  PriceInput,
  WaybillInput
} from "../../consumer/Create/CreateDeliveryFormElements";
import { FormErrorMessage } from "../../form/FormErrorMessage";
import { DeliveryStatus } from "../../../util/constants";
import { formatPriceToEuros } from "../../../util/format";

export const DeliveryInputs = ({
  delivery,
  disabledFields,
  isEditing
}) => {
  const [municipalities, setMunicipalities] = useState([]);

  const {
    register,
    setValue,
    formState,
    getValues,
    control,
    clearErrors,
    watch
  } = useForm({
    reValidateMode: "onSubmit",
    defaultValues: {
      ...delivery,
      price: delivery.price ? formatPriceToEuros(delivery.price) : "",
      dateRange: {
        from: new Date(delivery.dateRange.from),
        to: new Date(delivery.dateRange.to)
      }
    }
  });

  useEffect(() => {
    getMunicipalities("fi")
      .then(municipalities => {
        setMunicipalities(municipalities);
      });
  }, []);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "goods",
  });

  return (
    <Row style={{ justifyContent: "center" }}>
      <Form
        className="col-12"
      >
        {
          delivery.customer.customerType === "company" && (
            <WaybillInput
              register={register}
              disabledFields={disabledFields}
              isDisabled={!isEditing}
            />
          )
        }
        <Form.Group className="mb-3">
          <AddressInput
            title="origin"
            setValue={setValue}
            delivery={delivery}
            municipalities={municipalities}
            register={register}
            disabledFields={disabledFields}
            getValues={getValues}
            errors={formState.errors}
            control={control}
            clearErrors={clearErrors}
            isDisabled={!isEditing}
          />
          <Form.Group className="mb-2">
            <Form.Label htmlFor="senderPhonenumber">Lähettäjän puhelinnumero</Form.Label>
            <InputGroup>
              <FormControl
                id="senderPhonenumber"
                type="text"
                isInvalid={formState.errors.senderPhonenumber}
                onInput={() => clearErrors("senderPhonenumber")}
                {...register("senderPhonenumber", {
                  required: "Vaadittu kenttä"
                })}
                disabled={!isEditing}
              />
            </InputGroup>
            <FormErrorMessage
              error={formState.errors.senderPhonenumber}
              errorMessage={formState.errors.senderPhonenumber?.message}
            />
          </Form.Group>
          <AddressInput
            title="destination"
            setValue={setValue}
            delivery={delivery}
            municipalities={municipalities}
            register={register}
            disabledFields={disabledFields}
            getValues={getValues}
            errors={formState.errors}
            control={control}
            clearErrors={clearErrors}
            isDisabled={!isEditing}
          />
          <Form.Group className="mb-2">
            <Form.Label htmlFor="recipientPhonenumber">Vastaanottajan puhelinnumero</Form.Label>
            <InputGroup>
              <FormControl
                id="recipientPhonenumber"
                type="text"
                isInvalid={formState.errors.recipientPhonenumber}
                onInput={() => clearErrors("recipientPhonenumber")}
                {...register("recipientPhonenumber", {
                  required: "Vaadittu kenttä"
                })}
                disabled={!isEditing}
              />
            </InputGroup>
            <FormErrorMessage
              error={formState.errors.recipientPhonenumber}
              errorMessage={formState.errors.recipientPhonenumber?.message}
            />
          </Form.Group>
          <GoodsInput
            register={register}
            fields={fields}
            append={append}
            remove={remove}
            disabledFields={disabledFields}
            errors={formState.errors}
            clearErrors={clearErrors}
            isDisabled={!isEditing}
          />
          <br></br>
          <DateRangeInputs
            errors={formState.errors}
            clearErrors={clearErrors}
            disabledFields={disabledFields}
            watch={watch}
            control={control}
          />
          <OffloadingInput
            register={register}
            disabledFields={disabledFields}
            user={delivery.customer}
            isDisabled={!isEditing}
          />
          <PriceInput
            register={register}
            disabledFields={disabledFields}
            errors={formState.errors}
            clearErrors={clearErrors}
            isDisabled={!isEditing}
            notOpenStatus={delivery.status !== DeliveryStatus.OPEN}
          />
          <Form.Label htmlFor="info">Lisätiedot</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            {...register("info")}
            disabled={disabledFields.includes("info") || !isEditing}
          />
        </Form.Group>
      </Form>
    </Row >
  );

};