import { useEffect, useState } from "react";
import { Button, CloseButton, Col, Row } from "react-bootstrap";
import { DeliveryStatus } from "../../util/constants";
import { Delivery } from "./Delivery";
import Modal from "react-modal";
import { AcceptDeliveryModal } from "./AcceptDeliveryModal";
import { CounterOfferModal } from "./CounterOfferModal";
import { useOnError } from "../../hooks/useOnError";
import { toast } from "react-toastify";
import { useDeliveryService } from "../../hooks/services/useDeliveryService";
import { RejectModal } from "./RejectModal";
import { useUserContext } from "../../contexts/UserContext";
import { useCourierService } from "../../hooks/services/useCourierService";
import { ChangeCourierModal } from "./ChangeCourierModal";
import { ConfirmDelivery } from "./ConfirmDelivery";
import {useLoadingContext} from "../../contexts/LoadingContext";
import {defaultModalStyles} from "../../styles/defaultModalStyles";
import useAppCheck from "../../hooks/useAppCheck";
import { QuestionModal } from "./QuestionModal";

const modalStyles = {
  content: {
    ...defaultModalStyles.content,
    width: "80%",
    padding: "32px",
  }
};

// TODO: Show preview of the delivery
export const DeliveryModal = ({
  delivery,
  close,
  refreshAllDeliveries,
  courierDelivery,
  requestId,
  hideButtons
}) => {
  const [deliveryToAccept, setDeliveryToAccept] = useState(null);
  const [counterOffer, setCounterOffer] = useState(null);
  const [showQuestionModal, setQuestionModalOpen] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [organizationCouriers, setOrganizationCouriers] = useState([]);
  const [showChangeCourier, setShowChangeCourier] = useState(false);
  const [deliveredModalOpen, setDeliveredModalOpen] = useState(false);
  const [acceptingDelivery, setAcceptingDelivery] = useState(false);

  const courierService = useCourierService();
  const deliveryService = useDeliveryService();
  const { user, getOrganizationRole } = useUserContext();
  const { addLoading, removeLoading } = useLoadingContext();

  const [isMobileApp] = useAppCheck();

  if(isMobileApp) {
    modalStyles.content.width = "90%";
  }


  const isOrganizationAdmin = getOrganizationRole() === "admin";

  const onError = useOnError();
  const openDeliveryToAccept = () => setDeliveryToAccept(delivery);
  const closeAcceptDelivery = () => {
    setDeliveryToAccept(null);
    close();
  };

  const openCounterOffer = () => setCounterOffer(delivery);
  const openQuestionModal = () => setQuestionModalOpen(true);
  
  const closeCounterOffer = () => {
    setCounterOffer(null);
    close();
  };

  const closeQuestionModal = () => {
    setQuestionModalOpen(null);
    close();
  };

  const setAsDelivered = async (image) => {
    if(acceptingDelivery) {
      return;
    }
    addLoading("setAsDeliveredRequest");
    setAcceptingDelivery(true);

    try {
      const data = new FormData();
      data.append("img", image);
      await deliveryService.delivered(delivery.id, data);
    } catch (e) {
      onError(e);
      return;
    } finally {
      setAcceptingDelivery(false);
      removeLoading("setAsDeliveredRequest");
    }
    close();
    toast.success("Kuljetus merkitty toimitetuksi onnistuneesti");
    refreshAllDeliveries();
  };

  useEffect(() => {
    const getCouriers = async () => {
      try {
        const courierIds = user.organization.couriers.reduce((acc, memberData) => {
          if (memberData.courier) {
            return [
              ...acc,
              memberData.courier
            ];
          }
          return acc;
        }, []);

        const resp = await courierService.getCouriersByIds(courierIds);
        setOrganizationCouriers(resp);
      } catch (e) {
        onError(e);
      }
    };
    isOrganizationAdmin && getCouriers();
  }, [isOrganizationAdmin]);

  return (
    <Modal
      isOpen={delivery !== null}
      onRequestClose={close}
      style={modalStyles}
    >
      {
        delivery ?
          <div>
            <Row className="align-items-md-center">
              <Col xs={8}>
                <h3>{delivery.origin.postOffice + " -> " + delivery.destination.postOffice}</h3>
              </Col>
              <Col xs={4} className="d-flex justify-content-end">
                <CloseButton id="close" onClick={close} />
              </Col>
            </Row>
            <Delivery delivery={delivery} />
            {
              !hideButtons && (
                <Row>
                  <Col lg={{ span: 6, offset: 6 }}>
                    <BottomButtons
                      delivery={delivery}
                      openAccept={openDeliveryToAccept}
                      openCounterOffer={openCounterOffer}
                      openQuestionModal={openQuestionModal}
                      setAsDelivered={setAsDelivered}
                      isRequest={!!courierDelivery}
                      setShowRejectModal={setShowRejectModal}
                      isOrganizationAdmin={isOrganizationAdmin}
                      setShowChangeCourier={setShowChangeCourier}
                      deliveredModalOpen={deliveredModalOpen}
                      setDeliveredModalOpen={setDeliveredModalOpen}
                    />
                  </Col>
                </Row>
              )
            }
            <AcceptDeliveryModal
              delivery={deliveryToAccept}
              close={closeAcceptDelivery}
              refreshAllDeliveries={refreshAllDeliveries}
              requestedDeliveryDate={
                courierDelivery?.deliveryDate ?
                  courierDelivery?.deliveryDate : ""
              }
              requestId={requestId}
              isOrganizationAdmin={isOrganizationAdmin}
              organizationCouriers={organizationCouriers}
            />
            <CounterOfferModal
              delivery={counterOffer}
              close={closeCounterOffer}
              requestId={requestId}
              refreshAllDeliveries={refreshAllDeliveries}
              isOrganizationAdmin={isOrganizationAdmin}
              organizationCouriers={organizationCouriers}
            />
            <QuestionModal 
              delivery={delivery}
              isOpen={showQuestionModal}
              close={closeQuestionModal}
              requestId={requestId}
              refreshAllDeliveries={refreshAllDeliveries}
            />
            <RejectModal
              isOpen={showRejectModal}
              close={() => {
                setShowRejectModal(false);
                close();
              }}
              requestId={requestId}
              refreshAllDeliveries={refreshAllDeliveries}
              courierDelivery={courierDelivery}
            />
            {
              isOrganizationAdmin && (
                <ChangeCourierModal
                  isOpen={showChangeCourier}
                  close={() => {
                    setShowChangeCourier(false);
                    close();
                  }}
                  organizationCouriers={organizationCouriers}
                  currentCourier={delivery.accepted?.courier}
                  delivery={delivery}
                  refreshAllDeliveries={refreshAllDeliveries}
                />
              )
            }
          </div> :
          null
      }
    </Modal>
  );
};

function BottomButtons({
  delivery,
  openAccept,
  openCounterOffer,
  openQuestionModal,
  deliveredModalOpen,
  setDeliveredModalOpen,
  setAsDelivered,
  //closeSetAsDeliveredModal,
  isRequest,
  setShowRejectModal,
  isOrganizationAdmin,
  setShowChangeCourier
}) {
  if (delivery.status === DeliveryStatus.OPEN) {
    return (
      <>
        <Row>
          {
            isRequest && (
              <p>Muista ennen hyväksyntää varmistaa että kuljetus on mahdollinen</p>
            )
          }
        </Row>
        <Row>
          {
            isRequest && (
              <Col xs={12} sm={4}>
                <Button
                  id="reject"
                  variant="danger"
                  className="btn-base-style"
                  onClick={() => setShowRejectModal(true)}
                >
                  Hylkää kuljetus
                </Button>
              </Col>
            )
          }
          <Col xs={12} sm={4}>
            {
              delivery.price !== 0 ?
                <Button id="accept" className="btn-primary-color" onClick={openAccept}>
                  Hyväksy kuljetus
                </Button> :
                ""
            }
          </Col>
          <Col xs={12} sm={4}>
            <Button id="counterOffer" className="btn-text" onClick={openCounterOffer}>
              Tee vastatarjous
            </Button>
          </Col>
          {
            !isRequest && (
              <Col xs={12} sm={4}>
                <Button id="question" className="btn-text" onClick={openQuestionModal}>
                  Kysy kysymys
                </Button>
              </Col>
            )
          }
        </Row>
      </>
    );
  } else if (delivery.status === DeliveryStatus.ACCEPTED) {
    return (
      <Row
        style={{
          justifyContent: "flex-end"
        }}
      >
        {
          isOrganizationAdmin &&
            (
              <Col xs={6}>
                <Button
                  id="changeCourier"
                  className="btn-primary-color"
                  onClick={() => setShowChangeCourier(true)}
                >
                  Vaihda kuljettaja
                </Button>
              </Col>
            )
        }
        <Col xs={6}>
          <Button
            id="openSetAsDeliveredModal"
            className="btn-primary-color"
            onClick={() => setDeliveredModalOpen(true)}>
            Merkitse toimitetuksi
          </Button>
        </Col>
        <ConfirmDelivery
          isOpen={deliveredModalOpen}
          close={() => setDeliveredModalOpen(false)}
          markDelivered={setAsDelivered}
        />
      </Row>
    );
  }
}