import { Col, Form, FormControl, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useState } from "react";
import { SubmitButton } from "../SubmitButton.js";
import { RegisterModal } from "./RegisterModal";
import FormNewPassword from "../form/FormNewPassword";
import { useCourierService } from "../../hooks/services/useCourierService";
import { useOnError } from "../../hooks/useOnError";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { FormErrorMessage } from "../form/FormErrorMessage.js";
import { useUserContext } from "../../contexts/UserContext";
import useToken from "../../hooks/useToken";
import { AgreementModal } from "./AgreementModal.js";
import { AgreementInput } from "./AgreementInput.js";

//Password mode = change password for forgot password
export const CourierModes = {
  FULL: "FULL",
  EDIT: "EDIT",
  PASSWORD: "PASSWORD"
};

export default function CourierRegister({ mode, defaultValues, recoverPasswordHash }) {
  const courierService = useCourierService();
  const onError = useOnError();
  const [activeAgreement, setActiveAgreement] = useState("");

  const [,setToken] = useToken();

  // TODO Replace this with userContext
  const { setUser } = useUserContext();

  const form = useForm({
    reValidateMode: "onSubmit",
    defaultValues: {
      ...(defaultValues ? defaultValues : {}),
      password: ""
    }
  });
  const {
    register,
    handleSubmit,
    formState,
    reset,
    watch,
    control,
    clearErrors,
    setValue,
    getValues
  } = form;

  const [registrationSuccessful, setRegistrationSuccessful] = useState(false);

  const attemptRegister = async data => {
    try {
      setUser(await courierService.create(data));
      setRegistrationSuccessful(true);
      setToken(await courierService.login(data));
      reset();
      toast.success("Rekisteröityminen onnistui");
    } catch (e) {
      onError(e);
    }
  };

  const attemptSave = async data => {
    try {
      setUser(await courierService.update(data));
      setRegistrationSuccessful(true);
      setValue("oldPassword", "");
      setValue("password", "");
      setValue("password2", "");
    } catch (e) {
      onError(e);
    }
  };

  const attemptRecoverPassword = async data => {
    try {
      setUser(await courierService.recoverPassword(
        { ...data, recoverPasswordHash}
      ));
      setRegistrationSuccessful(true);
      setValue("password", "");
      setValue("password2", "");
    } catch (e) {
      onError(e);
    }
  };

  const passwordsMatch = () => {
    if (watch("password") !== watch("password2"))
      return "Salasanat eivät täsmää";
  };

  let submitFunction;
  if (mode === CourierModes.FULL) {
    submitFunction = attemptRegister;
  } else if (mode === CourierModes.EDIT) {
    submitFunction = attemptSave;
  } else if (mode === CourierModes.PASSWORD) {
    submitFunction = (data) => attemptRecoverPassword({ ...data, recoverPasswordHash });
  }

  return (
    <div>
      <Row style={{ justifyContent: "center" }}>
        <Form
          noValidate
          onSubmit={handleSubmit(submitFunction)}
          className="col-sm-8 col-md-6">
          {mode !== CourierModes.PASSWORD &&
            <>
              <Form.Group className="mb-2" md="2">
                <Form.Label htmlFor="firstName">Etunimi</Form.Label>
                <FormControl
                  id="firstName"
                  type="text"
                  isInvalid={formState.errors.name}
                  onInput={() => clearErrors("firstName")}
                  {...register("firstName", {
                    required: "Vaadittu kenttä"
                  })}
                />
                <FormErrorMessage
                  error={formState.errors.firstName}
                  errorMessage={formState.errors.firstName?.message}
                />
              </Form.Group>
              <Form.Group className="mb-2" md="2">
                <Form.Label htmlFor="lastName">Sukunimi</Form.Label>
                <FormControl
                  id="lastName"
                  type="text"
                  isInvalid={formState.errors.name}
                  onInput={() => clearErrors("lastName")}
                  {...register("lastName", {
                    required: "Vaadittu kenttä"
                  })}
                />
                <FormErrorMessage
                  error={formState.errors.lastName}
                  errorMessage={formState.errors.lastName?.message}
                />
              </Form.Group>
              <Form.Group className="mb-2" md="2">
                <Form.Label>Puhelinnumero</Form.Label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: "Vaadittu kenttä"
                  }}
                  render={(props) => {
                    return (
                      <PhoneInput
                        value={props.field.value}
                        onChange={phone => {
                          clearErrors("phoneNumber");
                          props.field.onChange(phone);
                        }}
                        containerStyle={{
                          width: "100%"
                        }}
                        inputStyle={{
                          width: "100%"
                        }}
                        autoFormat={true}
                        specialLabel="Puhelinnumero"
                        preferredCountries={["fi"]}
                        country={"fi"}
                        masks={{ fi: ".. ......." }}
                        placeholder={"+358 45 1234567"}
                        isValid={() => !formState.errors.phoneNumber}
                      />
                    );
                  }}
                />
                <FormErrorMessage
                  error={formState.errors.phoneNumber}
                  errorMessage={formState.errors.phoneNumber?.message}
                />
              </Form.Group>

              <Form.Group className="mb-2" md="2">
                <Form.Label htmlFor="email">Sähköposti</Form.Label>
                <FormControl
                  id="email"
                  type="text"
                  isInvalid={formState.errors.email}
                  onInput={() => clearErrors("email")}
                  {...register("email", {
                    required: getValues("primaryCommunication") === "email" ?
                      "Vaadittu kenttä" : false,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Sähköposti väärässä muodossa"
                    }
                  })}
                />
                <FormErrorMessage
                  error={formState.errors.email}
                  errorMessage={formState.errors.email?.message}
                />
              </Form.Group>

              <Form.Group className="mb-2" md="2">
                <Form.Label htmlFor="name">Viestien toimitustapa</Form.Label>
                <Controller
                  name="primaryCommunication"
                  control={control}
                  rules={{
                    required: "Valitse tapa jolla sovelluksen lähettämät viestit toimitetaan"
                  }}
                  render={(props) => {
                    return (
                      <div style={{ display: "flex" }}>
                        <Form.Check
                          name="primaryCommunication"
                          type="radio"
                          value="phone"
                          aria-label="radio_phone"
                          id="radio_phone"
                          label="Puhelin"
                          isInvalid={formState.errors.primaryCommunication}
                          onChange={evt => {
                            clearErrors("primaryCommunication");
                            props.field.onChange(evt.target.value);
                          }}
                          checked={getValues("primaryCommunication") === "phone"}
                          style={{ marginRight: "1.5rem" }}
                        />
                        <Form.Check
                          name="primaryCommunication"
                          type="radio"
                          value="email"
                          aria-label="radio_email"
                          id="radio_email"
                          label="Sähköposti"
                          isInvalid={formState.errors.primaryCommunication}
                          onChange={evt => {
                            clearErrors("primaryCommunication");
                            props.field.onChange(evt.target.value);
                          }}
                          checked={getValues("primaryCommunication") === "email"}
                          style={{ marginRight: "1.5rem" }}
                        />
                      </div>
                    );
                  }}
                />
                <FormErrorMessage
                  error={formState.errors.primaryCommunication}
                  errorMessage={formState.errors.primaryCommunication?.message}
                />
              </Form.Group>
            </>
          }
          {mode === CourierModes.EDIT &&
            <>
              <h3 className="mt-5 mb-3">Vaihda salasana</h3>
              <Form.Group className="mb-2" md="2">
                <Form.Label htmlFor="oldpassword">Vanha salasana</Form.Label>
                <FormControl
                  id="oldpassword"
                  type="password"
                  {...register("oldPassword")}
                />
              </Form.Group>
            </>
          }

          {mode !== CourierModes.LIGHT &&
            <>
              <FormNewPassword
                name="password"
                form={form}
                edit={mode === CourierModes.EDIT || mode === CourierModes.PASSWORD}
              />

              <Form.Group className="mb-2" md="2">
                <Form.Label htmlFor="confirm_password">
                  {mode === CourierModes.EDIT ? "Uusi s" : "S"}alasana uudelleen
                </Form.Label>
                <FormControl
                  id="password2"
                  type="password"
                  isInvalid={formState.errors.password2}
                  onInput={() => clearErrors("password2")}
                  {...register("password2", {
                    validate: passwordsMatch
                  })}
                />
                <FormErrorMessage
                  error={formState.errors.password2}
                  errorMessage={formState.errors.password2?.message}
                />
              </Form.Group>
            </>
          }
          {
            mode === CourierModes.FULL && (
              <>
                <AgreementInput
                  register={register}
                  clearErrors={clearErrors}
                  formState={formState}
                  agreementType="tos"
                  linkText="yleiset sopimusehdot"
                  linkTextPrefix={"Hyväksyn "}
                  setActiveAgreement={setActiveAgreement}
                />
                <AgreementInput
                  register={register}
                  clearErrors={clearErrors}
                  formState={formState}
                  agreementType="privacy"
                  linkText="tietosuojaselosteen"
                  linkTextPrefix={"Olen lukenut "}
                  setActiveAgreement={setActiveAgreement}
                />
              </>
            )
          }
          <Col style={{ display: "flex", justifyContent: "center", margin: 20 }}>
            <SubmitButton
              formState={formState}
              submitValue={mode === CourierModes.FULL ? "Rekisteröidy" : "Tallenna"}
              icon={<span className="material-symbols-outlined">account_circle</span>} />
          </Col>
        </Form>
        <RegisterModal
          registrationSuccessful={registrationSuccessful}
          userType={"courier"}
          mode={mode}
        />
        <AgreementModal
          activeAgreement={activeAgreement}
          setActiveAgreement={setActiveAgreement}
        />
      </Row>
    </div>
  );
}