import { Col, Form, FormControl, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { RegisterModal } from "./RegisterModal";
import { useState } from "react";
import { SubmitButton } from "../SubmitButton.js";
import { useCustomerService } from "../../hooks/services/useCustomerService";
import { useOnError } from "../../hooks/useOnError";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import useToken from "../../hooks/useToken";
import { toast } from "react-toastify";
import FormNewPassword from "../form/FormNewPassword";
import { FormErrorMessage } from "../form/FormErrorMessage";
import { useUserContext } from "../../contexts/UserContext";
import { ConsumerBusinessFields } from "./ConsumerBusinessFields";
import { AgreementModal } from "./AgreementModal";
import { AgreementInput } from "./AgreementInput";

//Light mode = light registering without creating a password
//Edit mode = edit own data
//Full mode = full registration
//Password mode = change password for forgot password
export const Modes = {
  LIGHT: "LIGHT",
  FULL: "FULL",
  EDIT: "EDIT",
  PASSWORD: "PASSWORD"
};

export default function ConsumerRegister({ mode, defaultValues, recoverPasswordHash }) {
  const customerService = useCustomerService();
  const [, setToken] = useToken();
  const onError = useOnError();
  const [registrationSuccessful, setRegistrationSuccessful] = useState(false);
  const [activeAgreement, setActiveAgreement] = useState("");

  const form = useForm({
    reValidateMode: "onSubmit",
    defaultValues: {
      ...(defaultValues ? defaultValues : {}),
      password: ""
    }
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState,
    reset,
    clearErrors,
    control,
    getValues,
    setValue,
    watch
  } = form;

  const { setUser } = useUserContext();

  const attemptRegister = async data => {
    try {
      setUser(await customerService.create(data));
      setRegistrationSuccessful(true);
      setToken(await customerService.login(data));
      reset();
      toast.success("Rekisteröityminen onnistui");
    } catch (e) {
      onError(e);
    }
  };

  const attemptSave = async data => {
    try {
      setUser(await customerService.update(data));
      setRegistrationSuccessful(true);
      setValue("oldPassword", "");
      setValue("password", "");
      setValue("password2", "");
    } catch (e) {
      onError(e);
    }
  };

  const attemptRecoverPassword = async data => {
    try {
      setUser(await customerService.recoverPassword({ ...data, recoverPasswordHash }));
      setRegistrationSuccessful(true);
      setValue("password", "");
      setValue("password2", "");
    } catch (e) {
      onError(e);
    }
  };

  const passwordsMatch = () => {
    if (getValues("password") !== getValues("password2"))
      return "Salasanat eivät täsmää";
  };

  const radioStatus = watch("customerType");

  let submitFunction;
  if (mode === Modes.FULL || mode === Modes.LIGHT) {
    submitFunction = attemptRegister;
  } else if (mode === Modes.EDIT) {
    submitFunction = attemptSave;
  } else if (mode === Modes.PASSWORD) {
    submitFunction = (data) => attemptRecoverPassword({ ...data, recoverPasswordHash });
  }

  return (
    <div>
      <Row style={{ justifyContent: "center" }}>
        <Form
          noValidate
          onSubmit={handleSubmit(submitFunction)}
          className="col-sm-8 col-md-6">
          {mode !== Modes.PASSWORD &&
            <>
              <Form.Group className="mb-2" md="2">
                <Form.Label htmlFor="name">Asiakkuustyyppi</Form.Label>
                <Controller
                  name="customerType"
                  control={control}
                  rules={{
                    required: "Valitse asiakkuustyyppi"
                  }}
                  render={(props) => {
                    return (
                      <div style={{ display: "flex" }}>
                        <Form.Check
                          name="customerType"
                          type="radio"
                          value="person"
                          aria-label="radio_person"
                          id="radio_person"
                          label="Yksityishenkilö"
                          isInvalid={formState.errors.customerType}
                          onChange={evt => {
                            clearErrors("customerType");
                            props.field.onChange(evt.target.value);
                            unregister("businessId");
                          }}
                          checked={getValues("customerType") === "person"}
                          style={{ marginRight: "1.5rem" }}
                        />
                        <Form.Check
                          name="customerType"
                          type="radio"
                          value="company"
                          aria-label="radio_company"
                          id="radio_company"
                          label="Yritys"
                          isInvalid={formState.errors.customerType}
                          onChange={evt => {
                            clearErrors("customerType");
                            props.field.onChange(evt.target.value);
                          }}
                          checked={getValues("customerType") === "company"}
                          style={{ marginRight: "1.5rem" }}
                        />
                      </div>
                    );
                  }}
                />
                <FormErrorMessage
                  error={formState.errors.customerType}
                  errorMessage={formState.errors.customerType?.message}
                />
              </Form.Group>

              {
                radioStatus === "company" &&
                <ConsumerBusinessFields
                  register={register}
                  formState={formState}
                  clearErrors={clearErrors}
                  setValue={setValue}
                  getValues={getValues}
                  control={control}
                />
              }

              <Form.Group className="mb-2" md="2">
                <Form.Label htmlFor="name">
                  {(radioStatus === "company" ? "Tilaajan n" : "N") + "imi"}
                </Form.Label>
                <FormControl
                  isInvalid={formState.errors.name}
                  id="name"
                  type="text"
                  onInput={() => clearErrors("name")}
                  {...register("name", { required: "Vaadittu kenttä" })}
                />

                <FormErrorMessage
                  error={formState.errors.name}
                  errorMessage={formState.errors.name?.message}
                />
              </Form.Group>

              <Form.Group className="mb-2" md="2">
                <Form.Label>Puhelinnumero</Form.Label>
                <Controller
                  name="phoneNumber"
                  control={control}
                  rules={{
                    required: "Vaadittu kenttä"
                  }}
                  render={(props) => {
                    return (
                      <PhoneInput
                        value={props.field.value}
                        onChange={phone => {
                          clearErrors("phoneNumber");
                          props.field.onChange(phone);
                        }}
                        containerStyle={{
                          width: "100%"
                        }}
                        inputStyle={{
                          width: "100%"
                        }}
                        autoFormat={true}
                        specialLabel="Puhelinnumero"
                        preferredCountries={["fi"]}
                        country={"fi"}
                        masks={{ fi: ".. ......." }}
                        placeholder={"+358 45 1234567"}
                        isValid={() => !formState.errors.phoneNumber}
                      />
                    );
                  }}
                />
                <FormErrorMessage
                  error={formState.errors.phoneNumber}
                  errorMessage={formState.errors.phoneNumber?.message}
                />
              </Form.Group>

              <Form.Group className="mb-2" md="2">
                <Form.Label htmlFor="email">Sähköposti</Form.Label>
                <FormControl
                  id="email"
                  type="text"
                  isInvalid={formState.errors.email}
                  disabled={mode === Modes.EDIT}
                  onInput={() => clearErrors("email")}
                  {...register("email", {
                    required: "Vaadittu kenttä",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Sähköposti väärässä muodossa"
                    }
                  })}
                />
                <FormErrorMessage
                  error={formState.errors.email}
                  errorMessage={formState.errors.email?.message}
                />
              </Form.Group>
            </>
          }
          {mode === Modes.EDIT &&
            <>
              <h3 className="mt-5 mb-3">Vaihda salasana</h3>
              <Form.Group className="mb-2" md="2">
                <Form.Label htmlFor="oldpassword">Vanha salasana</Form.Label>
                <FormControl
                  id="oldpassword"
                  type="password"
                  {...register("oldPassword")}
                />
              </Form.Group>
            </>
          }
          {mode !== Modes.LIGHT &&
            <>
              <FormNewPassword
                name="password"
                form={form}
                edit={mode === Modes.EDIT || mode === Modes.PASSWORD}
              />
              <Form.Group className="mb-2" md="2">
                <Form.Label htmlFor="confirm_password">
                  {mode === Modes.EDIT || mode === Modes.PASSWORD ? "Uusi s" : "S"}alasana uudelleen
                </Form.Label>
                <FormControl
                  id="password2"
                  type="password"
                  isInvalid={formState.errors.password2}
                  onInput={() => clearErrors("password2")}
                  {...register("password2", {
                    validate: passwordsMatch
                  })}
                />
                <FormErrorMessage
                  error={formState.errors.password2}
                  errorMessage={formState.errors.password2?.message}
                />
              </Form.Group>
            </>
          }
          {
            mode === Modes.FULL && (
              <>
                <AgreementInput
                  register={register}
                  clearErrors={clearErrors}
                  formState={formState}
                  agreementType="tos"
                  linkText="yleiset sopimusehdot"
                  linkTextPrefix={"Hyväksyn "}
                  setActiveAgreement={setActiveAgreement}
                />
                <AgreementInput
                  register={register}
                  clearErrors={clearErrors}
                  formState={formState}
                  agreementType="privacy"
                  linkText="tietosuojaselosteen"
                  linkTextPrefix={"Olen lukenut "}
                  setActiveAgreement={setActiveAgreement}
                />
              </>
            )
          }
          <Col style={{ display: "flex", justifyContent: "center", margin: 20 }}>
            <SubmitButton
              formState={formState}
              submitValue={mode === Modes.FULL ? "Rekisteröidy" : "Tallenna"}
              icon={<span className="material-symbols-outlined">account_circle</span>} />
          </Col>
        </Form>
        <RegisterModal
          registrationSuccessful={registrationSuccessful}
          userType={"consumer"}
          mode={mode}
        />
        <AgreementModal
          activeAgreement={activeAgreement}
          setActiveAgreement={setActiveAgreement}
        />
      </Row>
    </div>
  );
}