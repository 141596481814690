import { Button, Form } from "react-bootstrap";
import Modal from "react-modal";
import { DeliveryInputs } from "./DeliveryInputs";
import { DeliveryStatus } from "../../../util/constants.js";
import { Image } from "../../common/Image";
import {defaultModalStyles as modalStyles} from "../../../styles/defaultModalStyles";

export const DeliveryModal = (props) => {
  const { delivery, close } = props;

  return (
    <Modal
      isOpen={delivery !== null}
      onRequestClose={close}
      style={modalStyles}
      className="dynamicModal"
    >
      {
        delivery ? 
          <>
            <DeliveryInputs
              delivery={delivery}
              disabledFields={[]}
              close={close}
              refresh={null}
            />
            {
              delivery.status !== DeliveryStatus.OPEN ?
                <>
                  <p>Hyväksytty toimittaja</p>
                  <Form.Control
                    disabled={true}
                    value={
                      delivery.accepted.courier?.organization.name +
                      " - " +
                      delivery.accepted.courier?.organization.businessId
                    }
                    style={{marginBottom: "8px"}}
                  />
                </> :
                null
            }
            {
              delivery.status === DeliveryStatus.DELIVERED ?
                <>
                  <p>Todistekuva</p>
                  <Image
                    src={`/api/images/${delivery._id}`}
                    alt="proof of delivery"
                    style={{width: "100%", marginBottom: "16px"}}
                  />
                </> :
                null
            }
          </> :
          null
      }
      <div
        style={{
          display: "flex",
          justifyContent: "end"
        }}
      >
        <Button
          className="btn-primary-color"
          onClick={close}
        >
          Sulje
        </Button>
      </div>
    </Modal>
  );
};
