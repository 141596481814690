import { useState, useEffect } from "react";
import { Form, FormControl } from "react-bootstrap";
import { FormErrorMessage } from "../form/FormErrorMessage";
import { AddressInput } from "../consumer/Create/CreateDeliveryFormElements.js";
import { BillingInformationInput } from "./BillingInformationInput";
import { getMunicipalities } from "../../util/publicapis";

export const ConsumerBusinessFields = (props) => {
  const {
    register, formState, clearErrors, setValue,
    getValues, control, isReadOnly, admin
  } = props;

  const [municipalities, setMunicipalities] = useState([]);

  useEffect(() => {
    getMunicipalities("fi")
      .then(municipalities => {
        setMunicipalities(municipalities);
      });
  }, []);

  return (
    <Form.Group className="mb-2" md="2">
      <Form.Label htmlFor="businessName">Yrityksen nimi</Form.Label>
      <FormControl
        id="businessName"
        type="text"
        isInvalid={formState.errors.businessName}
        onInput={() => clearErrors("businessName")}
        {...register("businessName", {
          required: "Vaadittu kenttä",
          minLength: {
            value: 1,
            message: "Yrityksen nimi puuttuu"
          }
        })}
        disabled={isReadOnly}
      />
      <Form.Label htmlFor="businessId">Y-tunnus</Form.Label>
      <FormControl
        id="businessId"
        type="text"
        isInvalid={formState.errors.businessId}
        onInput={() => clearErrors("businessId")}
        {...register("businessId", {
          required: "Vaadittu kenttä",
          pattern: {
            value: /^[0-9]{7}-[0-9]{1}$/,
            message: "Virheellinen Y-tunnus"
          }
        })}
        disabled={isReadOnly}
      />
      <FormErrorMessage
        error={formState.errors.businessId}
        errorMessage={formState.errors.businessId?.message}
      />
      <br/>
      <AddressInput
        title="businessAddress"
        setValue={setValue}
        municipalities={municipalities}
        register={register}
        getValues={getValues}
        control={control}
        clearErrors={clearErrors}
        errors={[]}
        disabledFields={[]}
        isDisabled={isReadOnly}
      />
      <BillingInformationInput
        register={register}
        setValue={setValue}
        getValues={getValues}
        isReadOnly={isReadOnly}
        formState={formState}
      />
      {
        admin ?
          <div className="mt-3">
            <h5>Käyttäjän tiedot:</h5>
            <p>{getValues("name")}</p>
            <p>{getValues("email")}</p>
            <p>{"+" + getValues("phoneNumber")}</p>
          </div>:
          null
      }
    </Form.Group>
  );
};