import { Container, Form, Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import CourierRegister, { CourierModes } from "./CourierRegister";
import ConsumerRegister, {Modes} from "./ConsumerRegister";
import useAppCheck from "../../hooks/useAppCheck";

export default function Register() {
  const { setValue, watch } = useForm({});
  const [isApp] = useAppCheck();

  const radioStatus = watch("userType");

  return (
    <Container>
      <Col style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "40px"
      }}>
        <h1 style={{ margin: "0" }}>Rekisteröidy</h1>
      </Col>
      <Row style={{ justifyContent: "center" }}>
        <Form
          className="col-sm-8 col-md-6 mb-3" style={{ display: "flex", justifyContent: "center" }}>
          {
            !isApp?
              <>
                <button
                  onClick={() => setValue("userType", "consumer")}
                  type="button"
                  className={
                    `btn-primary-color userType ${radioStatus === "consumer" ? "active" : ""}`
                  }
                  style={{
                    display: "flex", 
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 4
                  }}
                >
                  <span className="material-symbols-outlined icon">person</span>
                  Tilaaja
                </button>
                <button
                  onClick={() => setValue("userType", "courier")}
                  type="button"
                  className={
                    `btn-primary-color userType ${radioStatus === "courier" ? "active" : ""}`}
                  style={{
                    display: "flex", 
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: 4
                  }}
                >
                  <span className="material-symbols-outlined icon">local_shipping</span>
                  Kuljettaja
                </button>
              </>
              : null
          }
        </Form>
        {radioStatus === "courier" || isApp?
          <CourierRegister mode={CourierModes.FULL}/>: null }
        {radioStatus === "consumer"? <ConsumerRegister mode={Modes.FULL} />: null }
      </Row>
    </Container>
  );
};