import { ConfirmModal } from "./ConfirmModal";

export const ConfirmDeleteModal = ({ isOpen, deleteAction, close, targetItem }) => {
  return (
    <ConfirmModal
      isOpen={isOpen}
      heading={"Vahvista poisto"}
      confirmationText={`Haluatko varmasti poistaa kohteen ${targetItem}`}
      confirmAction={deleteAction}
      confirmButtonText={"Poista"}
      close={close}
    />
  );
};