import { Row } from "react-bootstrap";
import GradientDiv from "../../Gradient";
import OrganizationRegister from "../../register/OrganizationRegister";

export const OrganizationInfo = ({
  organization
}) => {
  return (
    <GradientDiv>
      <Row style={{ justifyContent: "center" }}>
        {organization &&
          <OrganizationRegister
            editMode={true}
            defaultValues={organization}
          />}
      </Row>
    </GradientDiv>
  );
};