import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FormErrorMessage } from "../form/FormErrorMessage";

export const AgreementInput = ({
  register,
  clearErrors,
  formState,
  agreementType,
  linkText,
  linkTextPrefix,
  setActiveAgreement,
  noLink
}) => {
  return (
    <Form.Group className="mt-4 mb-4" md="2">
      <Form.Check
        type="checkbox"
      >
        <Form.Check.Input
          type="checkbox"
          {...register(agreementType, {
            required: "Vaadittu kenttä"
          })}
          onChange={() => {
            clearErrors(agreementType);
          }}
        />
        <Form.Check.Label>
          {linkTextPrefix}
          <Link
            to={noLink ? "" : "/register"}
            onClick={() => setActiveAgreement(agreementType)}
          >
            {linkText}
          </Link>
        </Form.Check.Label>
      </Form.Check>
      <FormErrorMessage
        error={formState.errors[agreementType]}
        errorMessage={formState.errors[agreementType]?.message}
      />
    </Form.Group>
  );
};