//import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {useMemo} from "react";

export const useOnError = () => {
  //const navigate = useNavigate();

  return useMemo(() => (e, formControl = null) => {
    console.log(e);
    const err = {
      status: e.response?.status,
      message: e.response?.data,
    };
    if(formControl) {
      formControl.setError("submit", err);
    }

    /*if(err.status === 401) {
      navigate("/login");
    }*/

    const errorMessages = {
      400: "Virheellinen pyyntö: " + err.message,
      401: err.message,
      404: "Haettua sivua ei löytynyt",
      422: err.message,
      500: "Ongelma palvelussa: " + err.message,
      429: "Teet tuota liian usein",
    };

    const errorMsg = errorMessages[err.status] || `${err.status} - Tuntematon virhe`;
    toast.error(errorMsg);

    if(formControl) {
      setTimeout(() => {
        formControl.clearErrors("submit");
      }, 2000);
    }
  }, []);
};