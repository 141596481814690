import { useCallback, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLoadingContext } from "../../contexts/LoadingContext";
import { useAdminService } from "../../hooks/services/useAdminService";
import { useOnError } from "../../hooks/useOnError";
import { BusinessModal } from "./BusinessModal";
import { StatusContainer } from "./StatusContainer";

const sectionStrings = {
  confirmedCourierOrganizations: {
    title: "Hyväksytyt kuljettajat",
    noDataText: "Ei hyväksyttyjä kuljettajia",
    type: "courier"
  },
  unconfirmedCourierOrganizations: {
    title: "Hyväksyntää odottavat kuljettajat",
    noDataText: "Ei hyväksyttäviä kuljettajia",
    type: "courier"
  },
  rejectedCourierOrganizations: {
    title: "Hylätyt kuljettajat",
    noDataText: "Ei hylättyjä kuljettajia",
    type: "courier"
  },
  bannedCourierOrganizations: {
    title: "Estetyt kuljettajat",
    noDataText: "Ei estettyjä kuljettajia",
    type: "courier"
  },
  confirmedCustomerOrganizations: {
    title: "Hyväksytyt asiakkaat",
    noDataText: "Ei hyväksyttyjä asiakkaita",
    type: "customer"
  },
  unconfirmedCustomerOrganizations: {
    title: "Hyväksyntää odottavat asiakkaat",
    noDataText: "Ei hyväksyttäviä asiakkaita",
    type: "customer"
  },
  rejectedCustomerOrganizations: {
    title: "Hylätyt asiakkaat",
    noDataText: "Ei hylättyjä asiakkaita",
    type: "customer"
  },
  bannedCustomerOrganizations: {
    title: "Estetyt asiakkaat",
    noDataText: "Ei estettyjä asiakkaita",
    type: "customer"
  }
};

const defaultBusiness = {business: null, type: null};

export default function Admin(props) {
  const [activeBusiness, setActiveBusiness] = useState(defaultBusiness);
  const [businesses, setBusinesses] = useState([]);

  const onError = useOnError();

  const adminService = useAdminService();
  const { addLoading, removeLoading } = useLoadingContext();

  const close = () => {
    setActiveBusiness(defaultBusiness);
  };

  const refresh = useCallback(async () => {
    try {
      addLoading("getBusinesses");
      const resp = await adminService.getBusinesses();
      removeLoading("getBusinesses");
      setBusinesses(resp);
    } catch (e) {
      removeLoading("getBusinesses");
      onError(e);
    }
  }, [onError]);

  useEffect(() => {
    refresh().then();
  }, [refresh]);

  return (
    <Container>
      {
        Object.keys(businesses).map((key, index) => (
          <StatusContainer
            key={index}
            title={sectionStrings[key].title}
            noDataText={sectionStrings[key].noDataText}
            type={sectionStrings[key].type}
            businesses={businesses[key]}
            setActiveBusiness={setActiveBusiness}
          />
        ))
      }
      <BusinessModal
        business={activeBusiness.business}
        type={activeBusiness.type}
        close={close}
        refresh={refresh}
      />
    </Container>
  );
}