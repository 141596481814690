/* eslint-disable max-len */
export const agreementTexts = {
  tos: () => tos,
  privacy: () => privacy,
  visma: () => visma
};

const tos = <>
  <h1>Cargo Online</h1>
  <h3>Yleiset sopimusehdot</h3>
  <p>
    Näihin yleisiin sopimusehtoihin on koottu Cargo Onlinen sovelluksen käyttämisen yleiset ehdot sekä rahdin
    kuljettamisen keskeisen määräykset. Ehdoissa annetaan myös neuvontaa kuluttaja-asiakkaalle kuluttajan
    oikeuksien varmistamisesta sekä kerrotaan yleiset periaatteet henkilötietojen käsittelystä sekä viitataan
    rekisteriselosteiden sijaintiin ja rekisteröidyn oikeuksien käyttämiseen.
    Cargo Onlinen sopimusehtoihin liittyvät yhteystiedot: <b>asiakaspalvelu@finlandcargo.com</b>
  </p>
  <h3>1. Määritelmät</h3>
  <p>
    Cargo Online tarkoittaa Suomeen rekisteröityä osakeyhtiötä Cargo Online Oy:tä, jonka y-tunnus on
    3269752-7 ja kotipaikka Oulu.
    Ehdot tai Ehto tarkoittavat näitä yleisiä sopimusehtoja tai Ehtojen yksittäistä kohtaa.
    Kuljettaja tarkoittaa yritystä tai yksityishenkilöä, joka tarjoaa ja ottaa Sovelluksen kautta vastaan
    tilauksia kuljetuspalveluista Tilaajalta.
    Käyttäjä tarkoittaa Tilaajaa tai Kuljettajaa.
    Sovellus tarkoittaa Cargo Onlinen ylläpitämää järjestelmää, jonka kautta Tilaaja voi tilata
    kuljetuspalveluita Kuljettajalta.
    Tilaaja tarkoittaa yritystä tai yksityishenkilöä, joka tilaa kuljetuspalvelun Sovelluksen kautta.
  </p>
  <h3>2. Yleistä ehtojen soveltamisesta</h3>
  <p>
    Ehtoja sovelletaan jokaiseen Tilaajan ja Kuljettajan välillä tehtyyn toimeksiantoon, jossa Tilaaja
    antaa Kuljettajalle minkä tahansa kuljetuksen kohteen kuljetettavaksi. Kuljetusta koskevat Ehdot
    tulevat sovellettavaksi Tilaajan ja Kuljettajan välillä. Tilaajan ja Kuljettajan välillä syntyy
    sopimussuhde, jonka osapuolena Cargo Online ei ole osallisena.
    Cargo Online tarjoaa Sovelluksen, joka on markkinapaikka, jossa Tilaajan ja Kuljettajan kysyntä ja
    tarjonta kohtaavat. Cargo Online vastaa alustan toiminnasta. Cargo Online hyväksyy Kuljettajan
    Sovelluksen kuljetuspalveluita tarjoavaksi osapuoleksi taatakseen Tilaajalle turvallisen
    sopimuskumppanin ja luotettavan kuljetuspalvelun. Osapuoleksi hyväksynnän ehtona on, että
    Kuljettaja hyväksyy ja sitoutuu noudattamaan näitä Ehtoja. Tilaaja hyväksyy ja sitoutuu
    noudattamaan näitä ehtoja rekisteröityessään Sovellukseen.
    Yleisten sopimusehtojen lisäksi noudatetaan toimintaa sääntelevää lainsäädäntöä. Mikäli
    yksittäisessä soveltamistilanteessa näiden yleisten sopimusehtojen ja minkä tahansa pakottavan
    lain säännöksen soveltamisessa syntyy ristiriitatilanne, noudatetaan pakottavassa lainsäädännössä
    määriteltyä sääntöä. Erityisesti noudatettavaksi tulevat Tiekuljetussopimuslaki (23.3.1979/345)
    sekä kuluttajan ollessa kuljetuspalvelun osapuoli tai Cargo Onlinen alusta käyttäjä
    Kuluttajansuojalaki 20.1.1978/38
    Näitä ehtoja sovelletaan vain Suomessa tapahtuvaan kotimaan kuljetukseen.
  </p>
  <h3>3. Käyttäjän yleiset velvollisuudet</h3>
  <p>
    Käyttäjät sitoutuvat solmimaan keskinäiset Sovellukseen ilmoitetut tai Sovelluksen kautta
    vastaanotetut kuljetuspalveluita koskevat sopimukset käyttäen Sovellusta. Käyttäjien solmimaan
    kuljetuspalveluun sovelletaan näitä yleisiä sopimusehtoja sekä mahdollisia erillisiä
    kuljetuspalveluehtoja. Kuljetuspalvelun hinta veloitetaan ja suoritetaan Cargo Onlinen sovelluksen
    kautta.
    Käyttäjän tili Sovelluksessa on aina henkilö- tai yrityskohtainen. Käyttäjän tulee toimia aina omissa
    nimissään käyttäessään tai salliessaan käytettäväksi Sovellusta. Mikäli Käyttäjä huomaa, että hänen
    henkilöllisyyttään tai Sovelluksen käyttäjätiliä käytetään ulkopuolisen toimesta, Käyttäjän tulee
    ilmoittaa tästä viipymättä Cargo Onlinelle. Käyttäjä vastaa kaikista Sovelluksen käytöstä tai sen
    kautta ostetuista tai tarjotuista kuljetuspalveluista aiheutuvista välittömistä ja välillisistä kuluista ja
    vahingoista Ehtojen, kuljetussopimuksen ehtojen ja lainsäädännön mukaisissa puitteissa.
    Käyttäjä ei voi koskaan siirtää Ehtojen tai kuljetussopimuksen mukaisia velvoitteita tai sopimusta
    kolmannelle taholle.
  </p>
  <h3>4. Kuluttajan erityiset oikeudet</h3>
  <h5>4.1. Kuluttajasuojalain soveltaminen</h5>
  <p>
    Kuluttajan ollessa kuljetuspalvelun osapuoli noudatetaan Kuluttajansuojalain (20.1.1978/38) tähän
    palveluun soveltuvia säännöksiä. Kuluttajan tehdessä kuljetuspalvelusta sopimuksen, saa kuluttaja
    tietoonsa kuljetuspalvelun tarjoajasta kuluttajalle lain mukaan annettavat tiedot. Kuljettaja on aina
    elinkeinon harjoittaja, jolla on lain mukainen elinkeinonharjoittajan vastuu kuljetuspalvelusta.
    Cargo Online vastaa kuluttajalle Sovelluksen toiminnasta, mutta ei tarjoa kuluttajalle Sovelluksen
    ylläpitäjänä varsinaiseen kuljetuspalveluun liittyviä kuluttajansuojalain mukaisia oikeuksia.
  </p>
  <h5>4.2. Kotimyyntiasiakirja</h5>
  <p>
    Kuluttaja saa Sovelluksen kautta etämyyntiasiakirjaa vastaavat tiedot Sovelluksen kautta sekä
    hyväksyy, että Kuluttajasuojalain edellyttämät kotimyyntiin liittyvät tiedonantovelvollisuudet on
    toimitettu kuluttajalle Sovelluksen kautta siten, ettei paperimuotoista kotimyyntiasiakirjaa
    toimiteta erikseen kuluttajalle. Kuluttajalla on kuitenkin aina erillisestä pyynnöstä oikeus saada
    kuljetussopimukseen liittyvät ehdot myös paperimuotoisena selvityksenä.
  </p>
  <h5>4.3. Peruuttamisoikeus</h5>
  <p>
    Kuluttajalla on oikeus peruuttaa koti- tai etämyyntinä tehty kuljetuspalvelun tilaus ilmoittamalla
    siitä sovelluksen kautta tai muulla kuluttajan valitsemalla yksiselitteisellä tavalla Kuljettajalle tai
    tämän lukuun Cargo Onlinelle viimeistään 14 päivän kuluttua kuljetuspalvelua koskevan tilauksen
    tekemisestä, mutta luonnollisesti kuitenkin viimeistään ennen kuin kuljetettava tavara on annettu
    Kuljettajan haltuun kuljetusta varten. Sovelluksen kautta tehty pyyntö kuljetuspalvelun
    hankkimisesta on kuluttajan pyyntö tai suostumus suorittaa kuluttajan tarvitsema kuljetuspalvelu
    kuluttajan haluamana aikana, eikä tämän pyynnön tai suostumuksen Kuljettajalla ole velvollisuutta
    odottaa 14 päivän peruuttamisajan kulumista, vaan kuljetuspalvelu voidaan aloittaa kuluttajan
    haluamana ajankohtana. Kuluttajalle ilmoitetaan peruuttamisilmoituksen vastaanottamisesta ja
    kuljetuspalvelun peruuttamisesta viipymättä Sovelluksen kautta ilmoituksen saapumisen jälkeen.
  </p>
  <h3>5. Kuljetussopimus</h3>
  <h5>5.1. Osapuolet ja sopimuksen syntyminen</h5>
  <p>
    Cargo Online ei ole Kuljettajan ja Tilaajan välisen kuljetussopimuksen osapuoli, mutta välittää
    sopimuksen osapuolten väliset kuljetusta koskevat ehdot, yksilöintitiedot ja kuljetusta koskevan
    maksun kuljetussopimuksen osapuolten välillä. Kuljetussopimus syntyy, kun Kuljettaja hyväksyy
    Tilaajan tarjoaman kuljetuksen Sovelluksessa. Rahtikirja toimitetaan joko Sovelluksen kautta tai
    Kuljettajan toimesta paperimuotoisena dokumenttina. Rahtikirja osoittaa kuljetussopimuksen
    syntyneen, kuljetussopimuksen keskeiset ehdot ja on todiste siitä, että tavara on luovutettu
    kuljetettavaksi Kuljettajalle siten, että se luovutetaan kuljetuksen päätteeksi vastaanottajalle.
    Kuljetustilaus koskee vain Sovelluksessa ilmoitettua tavaramäärää ja -lajia. Kuljettajalla on oikeus
    olla lastaamatta suurempaa tai tilauksesta poikkeavaa tavaramäärää. Jos Tilaaja havaitsee
    kuljetustarpeen muuttuneen tilauksen tekemisen ja rahdinkuljetuksen välisenä aikana, tulee
    muuttuneesta rahdin määrästä tehdä Sovelluksessa muutos. Kuljetuspalvelusta veloitetaan aina
    Sovelluksessa vahvistettu määrä, vaikka kuljetettava tavaramäärä olisi pienempi kuin Sovelluksen
    kautta on tilattu.
    Kuljettajalla on oikeus käyttää kuljetutuksen tai sen osan suorittamiseen alihankkijaa. Kuljettaja
    vastaa alihankkijan suorituksesta aina kuten omasta suorituksestaan.
  </p>
  <h5>5.2. Noudatettava laki</h5>
  <p>
    Kuljettajan ja asiakkaan välisessä kuljetussopimuksessa noudatetaan Tiekuljetussopimuslakia
    23.3.1979/345 sekä asiakkaan ollessa kuluttaja, Kuluttajansuojalakia. Mikäli Tiekuljetuslain ja
    Kuluttajansuojalain määräykset johtaisivat jossakin tilanteessa kuluttaja-asiakkaan kannalta
    erilaisiin lopputuloksiin, noudatetaan kuluttaja-asiakkaalle paremman lopputuleman antavaa
    lainsäädäntöä.
    Tiekuljetussopimuslaki rajoittaa merkittävästi Kuljettajan korvausvastuuta viivästyneistä,
    rikkoontuneista ja kadonneista lähetyksistä. Tyypillisesti vastuu rajoittuu rikkoontuneen tai
    kadonneen lähetyksen korvaamiseen sen painon mukaan 20 €/kg ja viivästystapauksissa
    kuljetuspalkkion palauttamiseen. Cargo Online kehottaa kuljetuspalvelun tilaajaa huomioimaan
    Kuljettajan vahingonkorvausvelvollisuuden rajoitukset ja tarvittaessa vakuuttamaan erikseen
    kuljetuksen kohteen.
  </p>

  <h5>5.3. Erityiset kuljetuksen kohteet</h5>
  <p>
    Kuljetettavan tavaran tulee aina vastata sen ilmoitettua sisältöä. Sovelluksessa on listattuna
    sellaiset kuljetuksen kohteet, joiden tilausta ei voida tehdä Sovelluksen kautta. Mahdollisista
    kiellettyjen tavaroiden tai aineiden listan lisäksi myös muista kuin listassa mainituista vaarallisista
    aineista tulee aina informoida etukäteen Kuljettajaa. Tilaajan tulee ottaa huomioon, että osan
    vaarallisista aineista kuljettamiseen ei jokaisella Kuljettajalla ole lakimääräistä oikeutta.
    Erityistä kuljetuskalustoa, kuljetuslämpötilaa tai rahdin käsittelyyn vaativaa lupaa vaativista
    kuljetuksen kohteista tulee informoida aina etukäteen Kuljettajaa kuljetussopimusta tehtäessä.
    Tällaisia kuljetuksen kohteita ovat esimerkiksi pakasteet, elävät tai kuolleet eläimet, kasvit ja aseet.
    Jos erityisestä kuljetuksen kohteesta ei ole informoitu etukäteen Kuljettajaa, on Kuljettajalla oikeus
    purkaa solmittu kuljetussopimus ja veloittaa syntyneet kustannukset kuljetuspalvelun tilaajalta.
    Kuljettajalla on oikeus purkaa sopimus myös, jos kuljetuksen kohteena oleva tavara muutoin
    aiheuttaa esine- tai henkilövahinkoja aiheuttavan riskin tavaran purkamisen, lastaamisen tai
    tieliikenteessä kuljettamisen aikana.
  </p>
  <h5>5.4. Pakkaus</h5>
  <p>
    Tilaaja on aina vastuussa siitä, että kuljetettava tavara on pakattu niin, että se on turvallinen
    kuljettaa ja että se kestää tavaran liikuttelussa ja kuljetuksen aikana esiintyvät tavanomaiset
    rasitteet sekä riittävän sidonnan ja tuennan kuormatilassa. Tilaaja vastaa, että pakkaustapa on
    sisällölleen sopiva suojaten sisältöä riittävästi. Pakkauksen tulee olla aina sellainen, että
    kuljetettava tavara ei ehjänä tai rikkoutuneena voi aiheuttaa vahinkoa henkilöille, kuljettavalle
    ajoneuvolle, varusteille tai muulle kuljetettavalle tavaralle. Kuljettajalla on oikeus, mutta ei
    velvollisuutta, täydentää tai korjata ilmeisen puutteellista pakkausta vahingon vaaran välttämiseksi
    sekä veloittaa tästä syntyvät kustannukset asiakkaalta.
    Mahdollisten kuormalavojen, kaapelikelojen, rullakoiden tai muiden vastaavien kuljetusta
    helpottavien alustojen toimittaminen, palauttaminen tai vaihto ei kuulu Sovelluksessa tilattavan
    kuljetuspalvelun sisältöön.
    Kuljettaja noudattaa aina kuljetustilauksessa ja tavaran lastaamisessa asiakkaan antamaa
    ohjeistusta. Tilaaja vastaa kollien mahdollisesta merkitsemisetä esimerkiksi kuljetusasennot, päälle
    lastaaminen kiellon, kosteudelta suojaamisen ja muiden mahdollisten erityismerkintöjen osalta.
  </p>
  <h5>5.5. Rahdin nouto ja luovuttaminen</h5>
  <p>
    Tilaaja huolehtii siitä, että Kuljettaja voi sovittuna aikana noutaa ja luovuttaa lähetykset ilman
    odotusaikaa, hukka-ajoa tai muuta estettä. Mikäli Tilaaja lastaa lähetyksen itse, on hän myös
    vastuussa lastauksen asianmukaisuudesta. Mikäli lastaukseen tai purkuun tarvitaan erityiskalustoa,
    on Tilaajan vastattava lastaamisesta itse tai tilattava erikseen tähän tarvittavat palvelut, mikäli
    Kuljettaja ei pysty kaluston tai erityisosaamisensa puitteissa vastaamaan lastaamisesta tällaisessa
    tilanteessa. Tilaaja vastaa mahdollisista työturvallisuuteen liittyvistä erityisvarusteista lastin
    pakkaamisen ja purkamisen yhteydessä.
  </p>
  <h5>5.6. Muut kuljetuksen ehdot</h5>
  <p>
    Mahdolliset kuljetukseen liittyvät reklamaatiot on tehtävä 7 vuorokauden kuluessa kuljettajan
    luovutettua rahdin vastaanottajan haltuun. Cargo Online kehottaa rahdin vastaanottajaa
    huolelliseen rahdin tarkastamiseen, jotta Tilaajalle tai rahdin vastaanottajalle ei aiheudu
    oikeudenmenetyksiä reklamaatioajan ylittämisestä myöhäisen rahdin tarkastuksen vuoksi.
    Cargo Onlinen kautta tilatussa kuljetuspalvelussa Kuljettajalla ei ole milloinkaan oikeutta myydä
    rahtia Tilaajalta olevan saatavan vuoksi.
    Kuljetuksen aikataulu rahdin noudon ja toimituksen osalta on määritelty Sovelluksessa. Kuljettajalla
    on oikeus ja velvollisuus kuvata kuljetukseen vastaanotettu rahti tai rahtikirja. Rahdin lähettäjä ja
    vastaanottaja sallivat, että rahti kuvatessa kuvassa voi näkyä myös rahdin lähettämis- ja/tai
    luovuttamispaikan ympäristöä.
  </p>

  <h3>6. Hinta, maksuehdot ja erityiset maksuvelvollisuudet</h3>
  <p>
    Sovelluksen lataaminen ja rekisteröityminen sovelluksen käyttäjäksi on maksutonta.
    Kuluttaja-asiakas suorittaa kuljetuspalveluiden maksun suoritetaan Sovelluksen kautta etukäteen.
    Yritysasiakkaita voidaan laskuttaa jälkikäteen 14 vrk netto -maksuehdon mukaisesti. Erillistä
    polttoainelisää ei veloiteta.
    Hintoihin lisätään kulloinkin voimassa oleva arvonlisävero sekä muut mahdolliset viranomaisten
    määräämät maksut.
    Sovelluksessa sovitun kuljetushinnan lisäksi Tilaaja sitoutuu suorittamaan rahdinkuljettajalle kaikki
    korvaukset, menot, sakot ja muut mahdolliset maksusuoritukset, joita rahdinkuljettaja saattaa
    joutua kärsimään sen vuoksi että rahtilähetys on sisältänyt tavaroita, joiden kuljetus on kielletty,
    pakkauksessa tai rahtilähetyksestä laadituissa dokumenteissa on vääriä tai puutteellisia tietoja,
    tavara on väärin pakattu tai Tilaaja on laiminlyönyt noudattaa jotain muuta velvollisuuttaan.
    Rahdinkuljettaja on oikeutettu veloittamaan kuljetuspalvelun hinnan, mikäli Tilaaja peruuttaa
    tilauksen myöhemmin kuin 24 tuntia ennen sovittua rahdin noutoaikaa.
    Mahdollinen viivästyskorko veloitetaan kulloinkin voimassa olevan lakimääräisen viivästyskoron
    mukaisesti.
  </p>

  <h3>7. Henkilötietojen käsittely ja tietosuoja</h3>
  <p>
    Sovellus kerää Sovelluksen käyttämisen ja kuljetuspalvelun tilaamisen sekä suorittamisen kannalta
    välttämättömiä ja hyödyllisiä tietoja. Osa tiedoista on sellaisia tietoja, joiden keräämiseen on
    palvelun toimittamiseksi lakimääräinen velvollisuus. Kerättäviä tietoja ovat esimerkiksi Käyttäjän
    nimi, osoite, laskutustiedot, tiedot kuljetuspalvelun ostoista ja maksuliikkeen hoitamiseen
    tarvittavat tiedot. Tärkein tietojen lähde on rekisteröidyltä itseltään saadut tiedot. Cargo Online
    käsittelee kaikkia henkilötietoja luottamuksellisesti ja henkilötietojen käsittelyä koskevien lakien ja
    määräysten mukaisesti.
  </p>

  <p>
    Henkilötietojen käsittelyyn liittyvät ajantasaiset rekisteriselosteet löytyvät osoitteesta www.cargo-online.fi ja www.finlandcargo.com. Rekisteriselosteista löytyvät mm. rekisteröidyn henkilötietojen
    suojaan, käsittelyyn ja rekisteröidyn oikeuksiin sekä niiden käyttämiseen liittyvät tiedot.
    Käyttäjä hyväksyy Sovellukseen liittyvät Käyttäjän mobiililaitteen tietosuoja-asetuksiin liittyvät
    vaatimukset ja määrittelyt mobiilliaplikaation asentamisen yhteydessä ja pääsee muuttamaan niitä
    puhelimen tai sovelluksen asetuksista haluamalleen tasolle.
  </p>
  <h3>8. Ylivoimainen este</h3>
  <p>
    Ylivoimaisena esteenä pidetään Sovelluksen käyttämisessä käyttäjätilin luomisen jälkeistä ja rahdin
    kuljettamisessa sopimuksen solmimisen tekemisen jälkeen ilmennyttä, osapuolista riippumatonta,
    ennalta arvaamatonta, poikkeuksellista tapahtuma, joka ei ole ollut siihen vetoavan osapuolen
    tiedossa etukäteen ja joka estää tai viivästyttää sovitun velvollisuuden täyttämisen. Ylivoimaksesi
    esteeksi katsotaan myös uudet tai muuttuneet viranomaismääräykset ja säädökset, palvelun
    täyttämiseksi tarvittavien lupien rajoittaminen tai epääminen sekä alihankkijan suorittaman
    palvelun viivästyminen, mikäli se on johtunut ylivoimaisesta esteestä. Mikäli osapuoli on estynyt
    täyttämästä velvollisuuksiaan ylivoimaisen esteen vuoksi, tulee siitä ilmoittaa viipymättä toiselle tai
    toisille osapuolille. Ylivoimainen este vapauttaa estyneen osapuolen suoritusvelvollisuuksistaan
    esteen ajaksi.
  </p>
  <h3>9. Muut ehdot</h3>
  <p>
    Kuljettaja sitoutuu suorittamaa kuljetukset asiakkaan Cargo Onlinestä riippumattomana
    sopijaosapuolena noudattaen voimassa olevaa lainsäädäntöä, kuten työ-, työaika-, tieliikenne- ja
    työturvallisuusmääräyksiä. Kuljettaja sitoutuu siihen, etteivät sen oman kuljetusehdot rajoita tai ole
    poikkeavia näiden Ehtojen kanssa. Mahdollisessa ehtojen ristiriitatilanteessa sovelletaan
    ensisijaisesti näitä Ehtoja. Kuljettajalla on aina voimassa oleva soveltuva vakuutusturva, jolla
    Kuljettaja voi kattaa asiakkaalle syntyvät tavaran vahingoittumisesta tai katoamisesta aiheutuvat
    vahingot.
    Palvelun käyttö ei koskaan luo työsuhdetta Kuljettajan ja asiakkaan tai Kuljettajan ja Cargo Onlinen
    välille. Cargo Online ja Kuljettaja vastaavat itsenäisesti omita työnantaja-, vero- ja muista
    vastaavista velvoitteistaan.
    Cargo Onlinella on oikeus muuttaa yksipuoleisesti näitä Ehtoja. Sopimusehtoja ei voida kuitenkaan
    muuttaa jo tilattujen tai toimitettujen kuljetuspalveluiden osalta. Cargo Onlinella on milloin vain
    poistaa Käyttäjän käyttäjätili palvelusta tai estää Käyttäjältä palvelun käyttö, jos Käyttäjä toimii
    Ehtojen tai lainsäädännön vastaisesti tai jos on ilmeistä, että Käyttäjä on taloudellisen tilanteen
    vuoksi estynyt suorittamasta tarkoitettu palvelua tai siitä syntyvää kustannusta taikka muusta
    vastaavasta perustellusta syystä.
    Mitkään muut suulliset tai kirjalliset ehdot, mukaan lukien rahdin lähettäjän asettamat suulliset tai
    kirjalliset ehdot tai ilmoitukset, eivät vaikuta näihin ehtoihin.
    Cargo Online voi siirtää sopimuksen mukaiset velvoitteensa, erityisesti Sovelluksen tarjoamisen
    kolmannelle osapuolelle.
    Sovellus sisältää Cargo Onlinen tekijänoikeuksien suojaamia elementtejä, kuten ohjelmiston osia.
    Käyttäjällä ei ole ilman Cargo Onlinen etukäteistä kirjallista lupaa levittää, julkaista, kopioida,

    saattaa yleisön saataville tai muuten kaupallisesti hyödyntää tekijänoikeuksien tai muiden Cargo
    Onlinen immateriaalioikeuksien suojaamaa materiaalia.
  </p>

  <h3>10. Riitaisuuksien ratkaiseminen ja viranomaisneuvonta</h3>
  <p>
    Näihin Ehtoihin sekä Kuljettajan ja asiakaan väliseen kuljetukseen sovelletaan Suomen lakia.
    Mahdolliset erimielisyydet ratkaistaan Helsingin käräjäoikeudessa. Kuluttaja-asiakkaan ollessa
    riitaisuuden osapuoli, riita voidaan ratkaista aina myös kuluttajan kotipaikan käräjäoikeudessa.
    Kuluttaja-asiakas voi saattaa erimielisyyden kuluttajariitalautakunnan ratkaistavaksi. Ennen asian
    viemistä kuluttajariitalautakunnan käsittelyyn kuluttajan tulee olla yhteydessä Kilpailu- ja
    kuluttajaviraston kuluttajaneuvontaan. Cargo Online sitoutuu noudattamaan omalta osaltaan
    kuluttajariitalautakunnan ratkaisuja, mutta ei anna tällaista etukäteistä sitoumusta Kuljettajan
    puolesta.
    Erimielisyyden koskiessa henkilötietojen käsittelyä, kuluttaja-asiakas tai muu yksityishenkilö, jota
    asia koskee, voi ottaa yhteyttä tietosuojavaltuutetun toimistoon.
    Cargo Online pyrkii Kuljettajan ja tämän asiakkaan välisissä erimielisyyksissä toimimaan
    sovittelijana ja saamaan osapuolten välille näiden Ehtojen sekä lainsäädännön osapuolille
    takaamien oikeuksien mukaisen sovintosopimukseen perustuvan ratkaisun.
  </p>
</>;

const privacy = <>
  <h1>CARGO ONLINE FINLAND OY</h1>
  <h2>Rekisteri- ja tietosuojaseloste</h2>
  <h3>1. Rekisterinpitäjä</h3>
  <p>
    <b>Cargo Online Finland Oy</b><br />
    Haapanatie 7b, 90150 Oulu<br />
    Y-tunnus: 3269752-7
  </p>

  <h3>2. Rekisteriasioista vastaaava henkilö</h3>
  <p>
    <b>Taina Kylmänen</b><br />
    info@finlandcargo.com
  </p>

  <h3>3. Rekisterin nimi</h3>
  <p>
    Cargo Online Finland Oy asiakasrekisteri
  </p>

  <h3>4. Rekisterin sisältö ja sen käyttötarkoitus</h3>
  <p>
    <b>Ryhmä: </b> Asiakkaat<br />
    <b>Sisältö: </b> Nimi, osoite, sähköpostiosoite, puhelinnumero, yrityksen nimi, y-tunnus,
    laskutustiedot tilinumero sekä sopimus- ja tilaustiedot.<br />
    <b>Käyttötarkoitus: </b> Asiakassuhteiden hoitamiseen, tiedottamiseen ja markkinointiin.<br />
    <br />
    Rekisteri voi sisältää myös asiakkaan antamia yksilöllisiä lisätietoja sekä käyttäjätietoja.
    Näiden tietojen tallentamisesta rekisteriin asiakkaalle ilmoitetaan erikseen. <br />
    <br />
    <b>Ryhmä: </b> Potentiaaliset asiakkaat sekä nettisivujen käyttäjät.<br />
    <b>Sisältö: </b> Nimi, sähköpostiosoite sekä valinnaisesti muita tietoja, kuten yrityksen nimi ja puhelinnumero.<br />
    <b>Käyttötarkoitus: </b> Nettisivujen kautta tulevien yhteydenottopyyntöjen käsittely.<br />
  </p>

  <h3>5. Rekisteirn tietojen käsittelyaika</h3>
  <p>
    Rekisteröidyn yhteystietoja säilytetään vain asiakassuhteen voimassaolon ajan, tai valinnaisesti niin kauan kuin tapauskohtaisesti nähdään tarpeelliseksi.<br />
    <br />
    Nettisivujen yhteydenottolomakkeen kautta lähetetyt viestit säilytetään lähtökohtaisesti vuoden ajan, tai valinnaisesti niin kauan kuin tapauskohtaisesti nähdään tarpeelliseksi.<br />
    <br />
  </p>

  <h3>6. Säännönmukaiset tietolähteet</h3>
  <ul>
    <li>Nettisivuillamme oleva yhteydenottolomake, jonka henkilö itse täyttää.</li>
    <li>Henkilöltä itseltään esimerkiksi sähköpostitse tai puhelimitse saadut tiedot.</li>
    <li>Sosiaalinen media (Facebook), jonka kautta henkilö ottaa yhteyttä.</li>
    <li>
      Cargo-Onlinen oma järjestelä ja tilitoimiston käyttämä Fennoa-laskutusjärjestelmä kerää
      yritys- ja yksityisasiakkaiden rekisteröitymistiedot.
    </li>
    <li>
      Cargo-Onlinen sähköpostipalvelin MailGun kerää asiakkaiden sähköpostiosoitteet,
      puhelinnumerot, nimet ja mahdolliset yrityksen tiedot.
    </li>
    <li>
      Cargo-Onlinen tekstiviestipalvelin Quriiri kerää asiakkaiden puhelinnumerot,
      nimet, sähköpostiosoitteet sekä mahdolliset työnantajantiedot.
    </li>
    <li>
      VismaPay kerää asiakkaiden maksutiedot.
    </li>
  </ul>
  <p>
    Täyttäessään nettisivuillamme olevan yhteydenottolomakkeen tai luovuttamalla
    tiedot muuta kautta, henkilö antaa rekisterinpitäjälle suostumuksen antamiensa
    tietojen tallentamiseen ja käsittelyyn tässä rekisteriselosteessa kuvatulla tavalla.
  </p>

  <h3>7. Säännönmukaiset tietojen luovutukset</h3>
  <p>
    Tiedot ovat luottamuksellisia, eikä tietoja luovuteta kolmansille osapuolille
    ilman asiakkaan suostumusta. Tietojen omistus ei siirry rekisterinpitäjältä kolmannelle
    osapuolelle, eikä kolmannella osapuolella ole oikeutta tietojen toimeksiantoa laajempaan käyttöön.<br />
    <br />
    Kolmansia osapuolia ovat mm. yhteistyökumppanit ja muut palveluntarjoajat.
    Tietoja ei luovuteta eikä siirretä EU:n tai Euroopan talousalueen ulkopuolelle.<br />
    <br />
    Käytämme vain voimassa olevan tietosuojalainsäädännön hyväksyneitä palveluntarjoajia, kuten:
    <ul>
      <li>Domainhotelli (nettisivut)</li>
      <li>Google Workspace (sähköposti)</li>
      <li>Elementor (nettisivujen yhteydenottolomake)</li>
      <li>GDPR Cookie Compliance (nettisivujen evästepalvelu)</li>
    </ul>
  </p>

  <h3>8. Rekisterin suojauksen periaatteet</h3>
  <p>
    Rekisteirn tiedot on tallennettu tietojärjestelmiin ja ne on suojattu siten,
    ettei asiaankuulumattomilla tahoilla ole pääsyä rekisteritietoihin.<br />
    <br />
    Tietoihin pääsevät käsiksi ainoastaan Cargo Online Finland Oy:n
    asiakasrekisteristä vastaavat henkilöt.
  </p>

  <h3>9. Tietojen tarkastusoikeus ja korjaaminen</h3>
  <p>
    Rekisteröidyllä on oikeus tarkastaa, mitä häntä koskevia tietoja
    asiakasrekisteriin on tallennettu. Rekisteröidyllä on lisäksi oikeus vaatia
    rekisterissä olevan virheellisen tiedon korjaamista. Tietojen korjauspyynnössä
    on yksilöitävä korjattava virhe ja ilmoitettava korjattavat tiedot.<br />
    <br />
    Tarkastuspyyntö sekä korjauspyyntö on lähetettävä kirjallisena ja
    allekirjoitettuna tämän dokumentin kohdassa 2. mainitulle rekisteriasioista
    vastaavalle henkilölle.
  </p>

  <h3>10. Tietojen poistaminen</h3>
  <p>
    Tietoja voidaan poistaa rekisteröidyn vaatimuksesta.
  </p>

  <h3>11. Muut mahdolliset oikeudet</h3>
  <p>
    Rekisteröidyllä on oikeus kieltää rekisterinpitäjää käsittelemästä häntä
    itseään koskevia tietoja mm. suoramarkkinointia varten.<br />
    <br />
    Nettisivumme voivat sisältää linkkejä kolmansien osapuolten nettisivuille.
    Linkitetyt nettisivut eivät ole meidän hallinnassamme, emmekä me vastaa
    kolmansien osapuolten nettisivujen sisällöstä tai niiden sisältämistä linkeistä.<br />
    <br />
    Evästeisiin perustuvan viestinnän kieltäminen tapahtuu käyttäjän nettiselaimen asetusten kautta.<br />
    <br />
  </p>

  <h3>12. Evästeiden kerääminen</h3>
  <p>
    Nettisivuillamme käytetään evästeitä.
    Nämä evästeisiin perustuvat tiedot voivat olla mm. sivustolla tehtyjä
    toimintoja, sivuvierailuita tai tietoa käyttäjän käyttämistä laitteista.
    Tietojen avulla parannetaan nettisivujen toimivuutta ja sisältöä, kehitetään
    liiketoimintaa sekä kohdennetaan markkinointia.<br />
    <br />
    Nettisivuillamme käytetään Google Ads ja Google Analytics -palveluita,
    jotka hyödyntävät toiminnassaan evästeitä.<br />
    <br />
    Evästeet ovat käyttäjäkohtaisia, mutta käyttäjän henkilöllisyyttä ei voida
    tunnistaa näiden perusteella. Käyttäjän tiedot, kuten: nimi, puhelinnumero
    ja sähköpostiosoite saadaan vain käyttäjän toimesta, esimerkiksi
    yhteydenottolomakkeen kautta, minkä käyttäjä voi itse halutessaan täyttää.<br />
    <br />
    Käyttäjä voi itse vaikuttaa evästeiden käyttöön nettisivuilla olevan
    evästebannerin kautta, tai muuttamalla oman nettiselaimensa asetuksia,
    kuten poistamalla evästeet käytöstä ja tyhjentämällä nettiselaimensa evästetiedot.<br />
    <br />
    On hyvä ottaa huomioon, että evästeet voivat olla tarpeellisia joidenkin
    palveluiden asianmukaiselle toimimiselle.
  </p>
</>;

const visma =
<>
  <p>
    Cargo Online Finland Oy 3269752-7 myy tuotteita yksityishenkilöille Suomeen.
    Pidätämme oikeuden toimitusehtojen ja hintojen muutoksiin. Tuotteiden hinnat
    sisältävät arvonlisäveron.
  </p>
  <h3>Verkkokaupan yhteystiedot</h3>
  <p>Sähköposti: info@finlandcargo.com</p>
  <p>Puhelin: 010 5087 260</p>
  <h3>Tilaaminen</h3>
  <p>
    Tilattavat tuotteet valitaan verkkosivuilla lisäämällä ne ostoskoriin. Tilaus
    lähetetään maksamalla ostoskorin sisältö verkkokaupan kassatoiminnossa.
    Tehdessäsi tilauksen hyväksyt nämä toimitusehdot, tuotteiden hinnat sekä
    toimituskulut. Mikäli tilaushetkellä annetaan sähköpostiosoite, tilauksesta
    lähetetään tilausvahvistus sähköpostitse. Tilausvahvistuksesta ilmenevät tilatut
    tuotteet sekä hinta.
  </p>
  <h3>Maksaminen</h3>
  <p>
    Verkkokaupan maksuvälittäjänä toimii Visma Pay (Visma Payments Oy, y-tunnus
    2486559-4), joka on rekisteröity Finanssivalvonnan ylläpitämään
    maksulaitosrekisteriin. Maksamiseen siirrytään Visma Payn verkkopalvelun kautta
    ja tiliotteella ja laskulla maksun saajana näkyy Visma Pay tai Visma Payments
    Oy. Visma Pay välittää maksut verkkokauppiaalle. Maksaminen on turvallista,
    sillä kaikki maksutapahtumaa koskevat tiedot välitetään salattua yhteyttä
    käyttäen niin ettei kukaan ulkopuolinen taho näe maksutapahtuman tietoja.
  </p>
  <p>
    Kauppa syntyy verkkokaupan asiakkaan ja verkkokaupan välille. Verkkokaupan
    vastuulla ovat kaikki kauppaan liittyvät velvoitteet.
  </p>
  <p>Lue lisää Visma Paysta: <a href="https://www.visma.fi/vismapay/" target="_blank" rel="noopener noreferrer">https://www.visma.fi/vismapay/</a></p>
  <h3>Maksutavat</h3>
  <p>
    Visma Pay -palvelun kautta voit maksaa verkkopankkitunnuksilla, lompakolla,
    maksukorteilla (credit/debit), laskulla tai osamaksulla. Käytettävissä ovat
    seuraavat maksutavat: Osuuspankki, Nordea, Danske Bank, Oma Säästöpankki,
    Säästöpankki, Aktia, Paikallisosuuspankit, S-Pankki, Handelsbanken,
    Ålandsbanken, MobilePay, Masterpass, Pivo, Visa-, Visa Debit-, Visa Electron-,
    MasterCard- ja Debit MasterCard -kortit, sekä Jousto, Fellow Lasku ja Fellow
    Yrityslasku.
  </p>
  <p>
    MobilePay: Voit maksaa MobilePay-lompakollasi mikäli olet sallinut
    verkkokaupoissa maksamisen sovelluksen asetuksista. MobilePay-lompakolla
    suoritetut maksut veloitetaan lompakkoon liitetyltä maksukortilta. Mikäli maksun
    veloittaminen maksukortilta epäonnistuu, MobilePay-lompakolla maksaminen ei ole
    mahdollista verkkokaupassa.
  </p>
  <p>
    Pivo: Käyttöehdot ovat tarjolla Pivon sivuilla:
    <a href="https://pivo.fi/kayttoehdot/pivon-kayttoehdot/" target="_blank" rel="noopener noreferrer"> https://pivo.fi/kayttoehdot/pivon-kayttoehdot/</a>
  </p>
  <p>
    Jousto lasku ja osamaksu on kotimainen palvelu, jolla teet ostoksesi nopeasti ja
    turvallisesti. Jousto on tarkoitettu yksityishenkilöille, jotka ovat hoitaneet
    raha-asiansa moitteettomasti. Joustolla saat 30 vuorokautta korotonta ja
    kulutonta maksuaikaa. Laskun saatuasi voit päättää maksatko sen kokonaan vai
    osissa. Osamaksulla voit maksaa ostoksesi jopa 36:ssa erässä, alkaen 9,90
    eur/kk. Jousto osamaksun kustannukset ovat 3,90 eur/kk ja 19,90%:n luottokorko.

    Voit maksaa Joustolla 30–3000 euron ostoksia. Luotonmyöntäjänä toimii Aurajoki
    Nordic Oy. Lue lisää Joustosta osoitteessa <a href="https://www.jousto.com" target="_blank" rel="noopener noreferrer">www.jousto.com</a>.
  </p>
  <p>
    OP Lasku - Joustava tapa maksaa verkko-ostokset. Kaikkien pankkien asiakkaille.
    OP Laskulla voit tehdä enintään 5 000 euron suuruisia ostoksia ja maksaa ne
    korottomasti pois 45 vuorokauden sisällä. Halutessasi voit pilkkoa laskun
    useampaan maksuerään. Ostoksesi näkyvät yhdessä paikassa, joten pysyt helposti
    perillä rahan käytöstä. Luottorajan avulla pidät kulutuksen haluamissasi
    rajoissa. Saat laskut kätevästi sähköpostiisi. Verkkopalvelussamme näet tietoja
    OP Laskun käytöstäsi ja tekemiesi ostosten summan. Voit käyttää OP Laskua, jos
    olet maksukykyinen yli 20-vuotias, ja sinulla on suomalaisen pankin
    verkkotunnukset. Lisätietoja OP Laskuun liittyen löydät osoitteesta: <a href="https://www.op.fi/henkiloasiakkaat/paivittaiset/maksaminen/op-lasku" target="_blank" rel="noopener noreferrer">
      https://www.op.fi/henkiloasiakkaat/paivittaiset/maksaminen/op-lasku</a>
  </p>
  <h3>Visma Pay -maksupalvelun yhteystiedot</h3>
  <p>
    Visma Payments Oy (Y-tunnus 2486559-4)<br/>
    Sähköposti: helpdesk@vismapay.com<br/>
    Puhelin: 09 315 42 037 (arkisin klo 8-16)<br/>
    Postiosoite: Brahenkatu 4, 53100 Lappeenranta<br/>
  </p>
  <h3>Toimitus</h3>
  <p>
    Tilauksia postitetaan arkipäivisin. Varastosta toimitettavien tuotteiden
    toimitusaika on yleensä 3-5 arkipäivää. Mikäli toimitettavat tuotteet ovat
    tilaustuotteita, toimitusaika on yleensä 1-3 viikkoa. Toimituskulut määräytyvät
    valitun toimitustavan, mahdollisten lisäpalvelujen, tilauksen painon ja koon
    mukaan. Näet toimituskulut verkkokaupan kassatoiminnossa ennen tilauksen
    lopullista hyväksymistä.
  </p>
  <p>
    Mikäli tuote on kadonnut tai vioittunut toimituksen aikana, tulee tästä
    ilmoittaa viipymättä, mutta kuitenkin 14 vuorokauden kuluessa verkkokaupalle.
    Toimituksessa vioittuneista paketeista tulee viipymättä ilmoittaa toimituksesta
    vastanneelle yhtiölle.
  </p>
  <h3>Palautukset</h3>
  <p>
    Verkkokaupan asiakkaalla on kuluttajansuojalain mukainen 14 vuorokauden vaihto-
    ja palautusoikeus. Asiakkaalla on oikeus vaihtaa tai palauttaa osa tai kaikki
    tilauksen tuotteet. Palautettavat tai vaihdettavat tuotteet tulee olla
    alkuperäispakkauksessa ja myyntikuntoisia. Halutessasi palauttaa tai vaihtaa
    tuotteita, ota ensin yhteyttä verkkokauppaan ja kysy palautusohjeita. Liitäthän
    palautukseen mukaan nimesi, yhteystietosi sekä tilinumerosi mahdollista
    maksunpalautusta varten.
  </p>
  <p>
    Tilauksen peruuttaminen, virhevastuu ja reklamaatiot
  </p>
  <p>
    Ennen tilauksen toimitusta tilauksen voi peruuttaa kirjallisella ilmoituksella sähköpostitse.
  </p>
  <p>
    Verkkokaupalla on lakisääteinen virhevastuu myydyistä tuotteista.
    Reklamaatiotapauksissa pyydämme olemaan yhteydessä asiakaspalveluumme.
    Kuluttajalla on oikeus viedä mahdolliset riitatilanteet
    kuluttajariitalautakunnan ratkaistavaksi.
  </p>
</>;