import { useEffect } from "react";
import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import Modal from "react-modal";
import { useOnError } from "../../hooks/useOnError";
import { useDeliveryService } from "../../hooks/services/useDeliveryService";
import { CurrencyWithTax } from "../common/PriceComponents";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { FormErrorMessage } from "../form/FormErrorMessage";
import { useUserContext } from "../../contexts/UserContext";
import { accept } from "./AcceptDeliveryModal";
import {defaultModalStyles} from "../../styles/defaultModalStyles";

const modalStyles = {
  content: {
    ...defaultModalStyles.content,
    width: "50%",
    marginTop: "100px",
  }
};

export const CounterOfferModal = ({
  delivery,
  close,
  requestId,
  refreshAllDeliveries,
  isOrganizationAdmin,
  organizationCouriers
}) => {
  const deliveryService = useDeliveryService();
  const { user } = useUserContext();
  const onError = useOnError();

  const {
    handleSubmit,
    formState,
    register,
    control,
    setValue,
    watch,
    clearErrors
  } = useForm({
    defaultValues:
      {}
  });

  useEffect(() => {
    isOrganizationAdmin && setValue("courierId", user._id);
  }, [isOrganizationAdmin]);

  const handleDateChange = (field, date) => {
    field.onChange(date);
  };

  const toastMessage = "Vastatarjous lähetetty onnistuneesti";

  return (
    <Modal
      isOpen={delivery !== null}
      onRequestClose={close}
      style={modalStyles}
    >
      {
        delivery ?
          <Form
            onSubmit={
              handleSubmit(
                (data) => accept({
                  amount: data.amount,
                  delivery,
                  deliveryService,
                  requestId,
                  onError,
                  data,
                  toastMessage,
                  watch,
                  close,
                  refreshAllDeliveries
                })
              )
            }
            style={{minHeight: 300}}
          >
            <Row style={{ height: "60px" }}>
              <Col className="mb-5">
                <h4>Olet tekemässä vastatarjousta</h4>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="deliveryDate">Kuljetuksen päivämäärä</Form.Label>
                  <Controller
                    name="deliveryDate"
                    control={control}
                    rules={{
                      required: "Vaadittu kenttä"
                    }}
                    render={({ field }) => {
                      return (
                        <DatePicker
                          locale="fi"
                          className="form-control"
                          dateFormat="d.M.yyyy"
                          minDate={new Date()}
                          placeholderText="Valitse päivä"
                          onChange={(date) => {
                            handleDateChange(field, date);
                            clearErrors("deliveryDate");
                          }}
                          selected={field.value}
                        />
                      );
                    }}
                  />
                  <FormErrorMessage
                    error={formState.errors.deliveryDate}
                    errorMessage={formState.errors.deliveryDate?.message}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Group className="mb-3">
                  <Form.Label htmlFor="amount">Kuljetuksen hinta</Form.Label>
                  <InputGroup>
                    <Form.Control
                      name="amount"
                      type="number"
                      step={0.01}
                      min={0}
                      max={99999}
                      {...register("amount", {
                        required: "Vaadittu kenttä"
                      })}
                    />
                    <InputGroup.Text><CurrencyWithTax /></InputGroup.Text>
                  </InputGroup>
                  <FormErrorMessage
                    error={formState.errors.amount}
                    errorMessage={formState.errors.amount?.message}
                  />
                </Form.Group>
                {
                  isOrganizationAdmin && (
                    <Form.Group className="mb-4">
                      <Form.Label htmlFor="courierId">Valitse kuljettaja</Form.Label>
                      <Form.Select
                        name="courierId"
                        {...register("courierId")}
                      >
                        {
                          organizationCouriers.map((courier) => (
                            <option
                              key={courier._id}
                              value={courier._id}
                            >
                              {courier.firstName} {courier.lastName}
                            </option>
                          ))
                        }
                      </Form.Select>
                    </Form.Group>
                  )}
              </Col>
            </Row>
            <Row style={{ marginTop: "60px" }}>
              <Col xs={{ span: 4, offset: 2 }}>
                <Button
                  className="btn-primary-color"
                  type="submit"
                >
                  Tee vastatarjous
                </Button>
              </Col>
              <Col xs={4}>
                <Button
                  className="btn-text" onClick={close}
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <span className="material-symbols-outlined">close</span>
                  Peruuta
                </Button>
              </Col>
            </Row>
          </Form> :
          null
      }
    </Modal>
  );
};