import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import axios from "axios";

export const Image = (props) => {

  const {src, alt, style} = props; 
  
  const auth = useAuth();

  const [guid, setGuid] = useState(null);

  useEffect(() => {
    const getGuid = async () => {
      setGuid((await axios.get(src + "/auth", auth))?.data);
    };
    getGuid();
  }, []);

  if(!guid) {
    return <p>Lataa...</p>;
  }

  return (
    <img
      src={`${src}/image?guid=${guid.guid.encryptedData}&iv=${guid.guid.iv}`}
      alt={alt}
      style={style}
    />
  );
};