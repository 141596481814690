import axios from "axios";
import { useAuth } from "../useAuth";

/**
 * @typedef {{
 * name: string,
 * phoneNumber: string,
 * email: string,
 * password: string,
 * password2: string
 * }} NewCustomerData
 */

/**
 * @typedef {{
 *   name: string,
 *   phoneNumber: string,
 *   email: string,
 * }} CustomerData
 */

/**
 * @typedef {{
 *   email: string=,
 *   password: string=
 * }} CustomerLoginForm
 */

/**
 * @returns {{
 * create: (NewCustomerData) => Promise<CustomerData>,
 * login: (CustomerLoginForm) => Promise<String>
 * }}
 */
export const useCustomerService = () => {
  const auth = useAuth();
  const basePath = "/api/customers";

  return {
    get: async () => (await axios.get(basePath, auth))?.data,
    create: async data => (await axios.post(basePath, data))?.data,
    update: async data => (await axios.patch(basePath, data, auth))?.data,
    login: async data => (await axios.post(`${basePath}/login`, data))?.data,
    socialMediaLogin: async data => (
      await axios.post(`${basePath}/social-media-login`, data)
    )?.data,
    verify: async hash => await axios.get(`${basePath}/verify/${hash}`),
    renewVerify: async data => (await axios.post(`${basePath}/renew-verify`, data))?.data,
    renewVerifyWithCreds: async data => (
      await axios.post(`${basePath}/renew-verify-with-creds`, data, auth)
    )?.data,
    forgotPassword: async data => (await axios.post(`${basePath}/forgot-password`, data))?.data,
    recoverPassword: async data => (await axios.post(`${basePath}/recover-password`, data))?.data,
  };
};