import Modal from "react-modal";
import { Button, Alert, Form } from "react-bootstrap";
import { useState } from "react";
import {defaultModalStyles} from "../../styles/defaultModalStyles";

const modalStyles = {
  content: {
    ...defaultModalStyles.content,
    width: "40%",
  }
};

export const ConfirmDelivery = props => {

  const { isOpen, close, markDelivered } = props;

  const [ image, setImage ] = useState(null);

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyles}
      onRequestClose={close}
    >
      <div>
        <Alert variant="light" style={{padding: 0}}>
          <Alert.Heading>
            Lisää kuva
          </Alert.Heading>
          <p>Lisää kuva rahtikirjasta tai muu toimituksen todistava kuva.</p>
          <Form>
            <Form.Group>
              <Form.Control 
                type="file"
                accept="image/png, image/jpeg, image/jpg"
                capture="environment"
                onChange={e => setImage(e.target.files[0])}
              />
            </Form.Group>
          </Form>
          <div className="d-flex justify-content-end mt-4">
            <Button
              id="markDelivered"
              onClick={() => markDelivered(image)}
              disabled={image === null}
              className="btn-primary-color"
            >
              Merkitse toimitetuksi
            </Button>
          </div>
        </Alert>
      </div>
    </Modal>
  );
};
