import {useEffect, useRef, useState} from "react";
import {createCustomEqual} from "fast-equals";
import {MarkerClusterer, SuperClusterAlgorithm} from "@googlemaps/markerclusterer";

export default function Map(
  {style, markers, onRefChange, onIdle, onMarkerChange, directions, ...options}) {

  const mapRef = useRef();
  const [map, setMap] = useState(/** @type (google.maps.Map|null) **/ null);
  const [ready, setReady] = useState(false);

  const idCounterRef = useRef(0);

  const [currentMarkers, setCurrentMarkers] = useState(null);
  const markerClusterer = useRef(new MarkerClusterer({
    algorithm: new SuperClusterAlgorithm({
      maxZoom: 10
    })
  })).current;

  const directionsRenderer = useRef(new window.google.maps.DirectionsRenderer({
    preserveViewport: true,
    suppressMarkers: true
  })).current;

  useEffect(() => {
    onMarkerChange(currentMarkers);
  }, [currentMarkers, onMarkerChange]);

  useEffect(() => {
    if (mapRef.current && !map) {
      const newMap = new window.google.maps.Map(mapRef.current, {maxZoom: 12});
      console.log("create maps object");
      setMap(newMap);
      onRefChange(newMap);
      markerClusterer.setMap(newMap);
      directionsRenderer.setMap(newMap);
      window.setTimeout(() => {
        newMap.addListener("idle", () => onIdle(newMap));
        window.google.maps.event.trigger(newMap, "resize");
        newMap.setMapTypeId(newMap.mapTypeId);
      }, 1000);

      return () => window.google.maps.event.clearListeners(newMap, "idle");
    }
  }, [markerClusterer, onIdle, onRefChange, mapRef, map, directionsRenderer]);

  useDeepCompareEffectForMaps(() => {
    if (map && options && !ready) {
      map.setOptions(options);
      setReady(true);
    }
  }, [ready, map, options]);

  useEffect(() => {
    setCurrentMarkers(currentMarkers => {
      if (currentMarkers) {
        currentMarkers.forEach(m => {
          m.marker.setOptions({});
        });

        markerClusterer.clearMarkers();
      }

      const newMarkers = markers ? markers.map(
        ({onMouseOver, onMouseOut, onClick, ...options}) => ({
          onMouseOut, onMouseOver, onClick, options,
          marker: new window.google.maps.Marker({
            ...options,
            map: map ?? void 0,
          }),
          id: ++idCounterRef.current
        })) : [];

      markerClusterer.addMarkers(newMarkers.map(m => m.marker));

      return newMarkers;
    });
  }, [markerClusterer, map, markers]);

  useEffect(() => {
    let registeredMarkers;
    if (currentMarkers && map) {
      currentMarkers.forEach(m => {
        window.google.maps.event.addListener(m.marker, "mouseover", m.onMouseOver ?? (() => {
        }));
        window.google.maps.event.addListener(m.marker, "mouseout", m.onMouseOut ?? (() => {
        }));
        window.google.maps.event.addListener(m.marker, "click", m.onClick ?? (() => {
        }));
      });
    }

    return () => {
      if (registeredMarkers) {
        registeredMarkers.forEach(m => {
          window.google.maps.event.clearListeners(m.marker, "mouseover");
          window.google.maps.event.clearListeners(m.marker, "mouseout");
          window.google.maps.event.clearListeners(m.marker, "click");
        });
      }
    };
  }, [currentMarkers, map]);

  useEffect(() => {
    if(!directionsRenderer) {
      return;
    }

    if(!directions) {
      directionsRenderer.set("directions", null);
      return;
    }

    directionsRenderer.setDirections(directions);
  }, [directions, directionsRenderer]);

  return <div ref={mapRef} style={style}/>;
}

// noinspection JSCheckFunctionSignatures
const deepCompareEqualsForMaps = createCustomEqual(
  (deepEqual) => (a, b) => {
    return deepEqual(a, b);
  }
);

function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  useEffect(callback, [callback, ...dependencies.map(useDeepCompareMemoize)]);
}