import { useCallback, useEffect, useState } from "react";
import { useLoadingContext } from "../../../../contexts/LoadingContext";
import { useDeliveryService } from "../../../../hooks/services/useDeliveryService";
import { useOnError } from "../../../../hooks/useOnError";
import { DeliveryStatus } from "../../../../util/constants";
import GradientDiv from "../../../Gradient";
import { DeliveryListings } from "../../DeliveryListings";
import { DeliveryModal } from "../../DeliveryModal";

export const OrganizationDeliveries = () => {
  const [deliveries, setDeliveries] = useState([]);
  const [activeDelivery, setActiveDelivery] = useState(null);

  const onError = useOnError();
  const deliveryService = useDeliveryService();
  const { addLoading, removeLoading } = useLoadingContext();

  const refreshDeliveries = useCallback(async () => {
    try {
      addLoading("organizationDeliveries");
      const resp = await deliveryService.search({ organization: true });
      removeLoading("organizationDeliveries");
      setDeliveries(resp);
    } catch (e) {
      removeLoading("organizationDeliveries");
      //TODO: This is really confusing for unconfirmed organizations so it's commented out
      /*onError(e);*/
    }
  }, [onError]);

  useEffect(() => {
    refreshDeliveries().then();
  }, [refreshDeliveries]);

  const close = () => {
    setActiveDelivery(null);
  };

  console.log(deliveries);

  return (
    <div>
      {
        [
          {
            status: DeliveryStatus.ACCEPTED,
            title: "Hyväksytyt kuljetukset"
          },
          {
            status: DeliveryStatus.DELIVERED,
            title: "Toimitetut kuljetukset"
          }
        ].map(({ status, title }) => {
          const filtered = deliveries.filter((delivery) => delivery.status === status);
          return (
            < GradientDiv
              key={status}
              className="main"
            >
              <h3 className="mb-4">{title}</h3>

              {
                filtered.length > 0 ? (
                  filtered.map((delivery) => (
                    <DeliveryListings
                      key={delivery.id}
                      delivery={delivery}
                      setActiveDelivery={setActiveDelivery}
                      isOrganizationAdmin={true}
                    />
                  ))
                ) : <p>Ei kuljetuksia</p>
              }
            </GradientDiv >
          );
        })
      }
      <DeliveryModal
        delivery={activeDelivery ? activeDelivery : null}
        close={close}
        refreshAllDeliveries={refreshDeliveries}
      />
    </div>
  );
};