// noinspection DuplicatedCode

import { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import GradientDiv from "../../Gradient";
import { DeliveryModal } from "../DeliveryModal";
import { useOnError } from "../../../hooks/useOnError";
import { useDeliveryService } from "../../../hooks/services/useDeliveryService";
import { useCourierDeliveryService } from "../../../hooks/services/useCourierDeliveryService";
import { CourierDeliveryListings } from "../CourierDeliveryListing";
import { EditDeliveryModal } from "../EditDeliveryModal";
import { useLoadingContext } from "../../../contexts/LoadingContext";

// TODO: Merge with ConsumerDeliveries
export default function CourierDeliveries(props) {
  const { delivered } = props;

  const [courierDeliveries, setCourierDeliveries] = useState([]);
  const [activeDelivery, setActiveDelivery] = useState(null);
  const [activeRequestId, setActiveRequestId] = useState(null);
  const [activeCourierDelivery, setActiveCourierDelivery] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const deliveryService = useDeliveryService();
  const courierDeliveryService = useCourierDeliveryService();
  const { addLoading, removeLoading } = useLoadingContext();
  const onError = useOnError();

  const close = () => {
    setActiveDelivery(null);
    setActiveCourierDelivery(null);
    setEditMode(false);
  };

  const refreshAllDeliveries = useCallback(async () => {
    let courierResult;
    try {
      addLoading("deliveries");
      // TODO: Check if deliveryService.search(...) call is necessary
      await deliveryService.search({ ownCourier: true });
      courierResult = await courierDeliveryService.getAll();
      removeLoading("deliveries");
    } catch (e) {
      removeLoading("deliveries");
      onError(e);
      return;
    }

    setCourierDeliveries(courierResult);
  }, [onError]);

  useEffect(() => {
    refreshAllDeliveries().then();
  }, [refreshAllDeliveries]);

  return (
    <>
      {
        !delivered && (
          <GradientDiv
            className="main"
          >
            <h3 className="mb-4">Omat avoimet ilmoitukset</h3>
            <Row>
              <Col xs={12}>
                {
                  courierDeliveries.length > 0 ?
                    courierDeliveries.map((cDelivery) => (
                      <CourierDeliveryListings
                        key={cDelivery.id}
                        courierDelivery={cDelivery}
                        refreshAllDeliveries={refreshAllDeliveries}
                        setActiveDelivery={setActiveDelivery}
                        setActiveCourierDelivery={setActiveCourierDelivery}
                        setActiveRequestId={setActiveRequestId}
                        setEditMode={setEditMode}
                      />
                    )) : <p>Ei omia ilmoituksia</p>
                }
              </Col>
            </Row>
          </GradientDiv>
        )
      }
      <DeliveryModal
        delivery={(activeDelivery && !editMode) ? activeDelivery : null}
        courierDelivery={activeCourierDelivery}
        requestId={activeRequestId}
        close={close}
        refreshAllDeliveries={refreshAllDeliveries}
      />
      <EditDeliveryModal
        courierDelivery={(activeDelivery && editMode) ? activeDelivery : null}
        close={close}
        refresh={refreshAllDeliveries}
      />
    </>
  );
}