import { Button, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { Unloading } from "../../../util/constants";
import "./createDelivery.css";
import { FormErrorMessage } from "../../form/FormErrorMessage";
import { Controller } from "react-hook-form";
import { AddProduct } from "./AddProduct";
import { CurrencyWithTax } from "../../common/PriceComponents";
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import fi from "date-fns/locale/fi";
import {getMunicipalitySuggestion} from "../../../util/postalCode/postalCode";
registerLocale("fi", fi);

const Dictionary = {
  "origin": "Lähtöpaikka",
  "destination": "Määränpää",
  "businessAddress": "Yrityksen osoite"
};

//capitalize
const cptl = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const AddressInput = ({
  title,
  setValue,
  municipalities,
  register,
  disabledFields,
  getValues,
  errors,
  control,
  clearErrors,
  isDisabled
}) => {
  return <>
    <Form.Group className="mb-2">
      <Form.Label htmlFor={title}>{Dictionary[title]}</Form.Label>
      <InputGroup>
        <FormControl
          placeholder="Osoite"
          id={"street" + cptl(title)}
          type="text"
          isInvalid={errors ? errors[title]?.street : false}
          onInput={() => clearErrors(title + ".street")}
          {...register(title + ".street", {
            required: "Vaadittu kenttä"
          })}
          disabled={disabledFields.indexOf(title + ".street") !== -1 || isDisabled}
        />
      </InputGroup>
      <FormErrorMessage
        error={errors[title]?.street}
        errorMessage={errors[title]?.street?.message}
      />
    </Form.Group>
    <Form.Group className="mb-2">
      <Row>
        <Col>
          <InputGroup>
            <FormControl
              placeholder="Postinumero"
              id="postcode"
              type="number"
              isInvalid={errors[title]?.postCode}
              onInput={() => clearErrors(title + ".postCode")}
              {...register(title + ".postCode", {
                required: "Vaadittu kenttä",
                pattern: {
                  value: /^\d{5}$/,
                  message: "Tarkista pituus"
                },
                onChange: e => {
                  const value = e.nativeEvent.target.value;
                  if(value.length !== 5) {
                    return;
                  }

                  const suggestion = getMunicipalitySuggestion(value);
                  if(!suggestion || !municipalities.includes(suggestion)) {
                    return;
                  }

                  setValue(title + ".postOffice", suggestion);
                }
              })}
              disabled={disabledFields.indexOf(title + ".postCode") !== -1 || isDisabled}
            />
          </InputGroup>
          <FormErrorMessage
            error={errors[title]?.postCode}
            errorMessage={errors[title]?.postCode?.message}
          />
        </Col>
        <Col>
          <Controller
            name={title + ".postOffice"}
            control={control}
            rules={{
              required: "Vaadittu kenttä"
            }}
            render={(props) => {
              return (
                <Typeahead
                  id={title}
                  placeholder="Postitoimipaikka"
                  selected={
                    getValues()?.[title]?.postOffice ? [getValues()[title].postOffice] : null
                  }
                  onChange={e => {
                    clearErrors(title + ".postOffice");
                    props.field.onChange(e[0]);
                  }}
                  isInvalid={Boolean(errors[title]?.postOffice)}
                  onBlur={e => setValue(title + ".postOffice", e.target.value)}
                  options={municipalities}
                  disabled={disabledFields.indexOf(title + ".postOffice") !== -1 || isDisabled}
                />
              );
            }}
          />
          <FormErrorMessage
            error={errors[title]?.postOffice}
            errorMessage={errors[title]?.postOffice?.message}
          />
        </Col>
      </Row>
    </Form.Group>
  </>;
};

export const GoodsInput = ({
  register, fields, remove, append, disabledFields, errors, clearErrors, isDisabled
}) => {
  return (
    <>
      <Form.Label htmlFor="stuff">Tuotteet</Form.Label>
      <br></br>

      {fields.map((field, index) => (
        <AddProduct
          key={index}
          field={field}
          register={register}
          remove={remove}
          index={index}
          disabledFields={disabledFields}
          errors={errors}
          clearErrors={clearErrors}
          isDisabled={isDisabled}
        />
      )) ?? []}

      <Button
        onClick={() => append({})}
        className="add-product-btn"
        disabled={disabledFields.includes("goods") || isDisabled}
      >
        Lisää tuote
      </Button>
    </>
  );
};

export const SingleDateInput = ({
  name,
  control,
  clearErrors,
  disabledFields,
  isDisabled,
  isOptional,
  startDate,
  endDate,
  fieldName
}) => {
  const handleDateChange = (field, date) => {
    field.onChange(date);
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={!isOptional && {
        required: "Vaadittu kenttä"
      }}
      render={({ field }) => {
        return (
          <DatePicker
            locale="fi"
            className="form-control"
            dateFormat="d.M.yyyy"
            minDate={new Date()}
            selectsStart
            placeholderText="Valitse päivä"
            onChange={(date) => {
              handleDateChange(field, date);
              clearErrors(name);
            }}
            selected={field.value}
            startDate={startDate}
            endDate={endDate}
            disabled={disabledFields.includes(fieldName) || isDisabled}
          />
        );
      }}
    />
  );
};

export const DateRangeInputs = ({
  errors,
  clearErrors,
  disabledFields,
  isDisabled,
  watch,
  control,
  isOptional
}) => {

  return (
    <>
      <Form.Label htmlFor="delieveryDate">Matkan ajankohta</Form.Label>
      <Row>
        <Col>
          <Form.Group className="mb-3">
            <SingleDateInput
              name="dateRange.from"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              disabledFields={disabledFields}
              isDisabled={isDisabled}
              isOptional={isOptional}
              startDate={watch("dateRange.from")}
              endDate={watch("dateRange.to")}
            />
            <FormErrorMessage
              error={errors.dateRange?.from}
              errorMessage={errors.dateRange?.from?.message}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-3">
            <SingleDateInput
              name="dateRange.to"
              control={control}
              errors={errors}
              clearErrors={clearErrors}
              disabledFields={disabledFields}
              isDisabled={isDisabled}
              isOptional={isOptional}
              startDate={watch("dateRange.from")}
              endDate={watch("dateRange.to")}
            />
            <FormErrorMessage
              error={errors.dateRange?.to}
              errorMessage={errors.dateRange?.to?.message}
            />
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export const OffloadingInput = ({
  register, disabledFields, user, isDisabled
}) => (
  <>
    <Form.Group>
      <Form.Label htmlFor="unloadPossibilities">Purkumahdollisuudet</Form.Label>
      <Form.Select
        name="unloading"
        {...register("unloading")}
        disabled={disabledFields.includes("unloading") || isDisabled}
      >
        <option>{Unloading.ALL}</option>
        <option>{Unloading.CRANE}</option>
        <option>{Unloading.TAILBOARD}</option>
        <option>{Unloading.NOINFO}</option>
        {
          user.customerType === "company" ?
            <option>{Unloading.NONE}</option> :
            null
        }
      </Form.Select>
    </Form.Group>
  </>
);

export const PriceInput = ({
  register, disabledFields, errors, clearErrors, isDisabled, notOpenStatus
}) => (
  <>
    <Form.Group className="mb-2" md="2">
      <Form.Label htmlFor="dimensions">{notOpenStatus ? "Hinta" : "Hintaehdotus"}</Form.Label>
      <InputGroup>
        <FormControl
          id="price"
          type="number"
          step=".01"
          min="0"
          max="99999"
          isInvalid={errors.price}
          onInput={() => clearErrors("price")}
          {...register("price")}
          disabled={disabledFields.includes("price") || isDisabled}
        />
        <InputGroup.Text><CurrencyWithTax /></InputGroup.Text>
      </InputGroup>
      <FormErrorMessage
        error={errors.price}
        errorMessage={errors.price?.message}
      />
    </Form.Group>
  </>
);

export const WaybillInput = ({
  register, disabledFields, isDisabled
}) => (
  <Form.Group className="mb-2" md="2">
    <Form.Label htmlFor="dimensions">Rahtikirjan numero</Form.Label>
    <InputGroup>
      <FormControl
        id="waybillNo"
        type="number"
        {...register("waybillNo")}
        disabled={disabledFields.includes("waybillNo") || isDisabled}
      />
    </InputGroup>
  </Form.Group>
);