import create from "zustand";

export const globalState = (defaultValue = null, persistKey = null) => {
  const store = create(set => ({
    value: persistKey? (getLocalStorage(persistKey) ?? defaultValue) : defaultValue,
    setValue: input => set(
      prev => {
        const newVal = {value: typeof input === "function" ? input(prev.value) : input};

        if(persistKey) {
          setLocalStorage(persistKey, newVal.value);
        }

        return newVal;
      }
    )
  }));

  return {
    use: () => store(state => ([state.value, state.setValue])),
    store
  };
};

const getLocalStorage = key => JSON.parse(window.localStorage.getItem(key));
const setLocalStorage = (key, value) =>
  window.localStorage.setItem(key, JSON.stringify(value));