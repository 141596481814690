import React, {useCallback, useEffect} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Unloading} from "../../../util/constants";
import {useForm} from "react-hook-form";
import {useDebounce} from "../../../hooks/useDebounce";
import {BsGeoAlt} from "react-icons/bs";
import "./search.css";
import useAppCheck from "../../../hooks/useAppCheck";

const SearchBar = ({doSearch, style = {}}) => {
  const {watch, setValue, register, getValues} = useForm({
    defaultValues: {
      address: null,
      latitude: null,
      longitude: null,
      unloading: Unloading.ALL,
      weight: null,
      dimensions: {
        x: null,
        y: null,
        z: null
      }
    }
  });
  const [isApp] = useAppCheck();

  useEffect(() => {
    window.mobileLocationCallback = (location) => {
      const {latitude, longitude} = location.coords;
      setValue("latitude", latitude);
      setValue("longitude", longitude);
    };
  }, [setValue]);

  const search = useDebounce(useCallback(() => doSearch(getValues()), [doSearch, getValues]), 500);

  const registerNumberInput = name => ({
    value: (value => {
      if (value === null || (typeof value === "number" && isNaN(value))) {
        return "";
      }

      return value.toString();
    })(watch(name)),
    onChange: ({target: {value}}) => {
      const sanitizedValue = value.replaceAll(/\D/g, "").slice(0, 8);
      if (sanitizedValue.length === 0) {
        setValue(name, null);
        return;
      }

      setValue(name, parseInt(sanitizedValue, 10));
    }
  });

  const onKeyUp = useCallback(e => {
    if (e.key === "Enter") {
      search();
    }
  }, [search]);

  // TODO: display loading animation
  return (
    <Form style={style}>
      <Row className="mb-3" style={{
        flexDirection: "row",
      }}>
        {/* Forms are reversed to that the Form.Label text
            can be changed when a form field is focused */}
        <Col xs={12} md={6} xl={3}>
          <Form.Group className="mb-4 form-reverse">
            <Form.Select
              size="lg"
              id="unloading"
              {...register("unloading")}
              onChange={search}
              className="col-xs-12"
            >
              <option>{Unloading.ALL}</option>
              <option>{Unloading.CRANE}</option>
              <option>{Unloading.TAILBOARD}</option>
              <option>{Unloading.NONE}</option>
            </Form.Select>
            <Form.Label style={{
              paddingLeft: 5
            }}>Purkutyyppi</Form.Label>
          </Form.Group>
        </Col>
        <Col xs={12} md={6} xl={3}>
          <Form.Group className="mb-4 form-reverse">
            <Form.Control
              onKeyUp={onKeyUp} size="lg" id="weight" {...registerNumberInput("weight")} />
            <Form.Label style={{
              paddingLeft: 5
            }}>Maksimipaino (kg)</Form.Label>
          </Form.Group>
        </Col>
        <Col xs={12} md={6} xl={3}>
          <Form.Group className="mb-4 form-reverse">
            <Row>
              <Col xs={4} style={{paddingRight: 1}}>
                <Form.Control
                  onKeyUp={onKeyUp}
                  size="lg"
                  id="dimension-x"
                  maxLength={2}
                  {...registerNumberInput("dimensions.x")}
                />
                <div className="dimension-text">
                  <label>Leveys</label>
                </div>
              </Col>
              <Col xs={4}>
                <Form.Control
                  onKeyUp={onKeyUp}
                  size="lg"
                  id="dimension-y"
                  maxLength={2}
                  {...registerNumberInput("dimensions.y")}
                />
                <div className="dimension-text">
                  <label>Korkeus</label>
                </div>
              </Col>
              <Col xs={4} style={{paddingLeft: 1}}>
                <Form.Control
                  onKeyUp={onKeyUp}
                  size="lg"
                  id="dimension-z"
                  maxLength={2}
                  {...registerNumberInput("dimensions.z")}
                />
                <div className="dimension-text">
                  <label>Syvyys</label>
                </div>
              </Col>
            </Row>
            <Form.Label
              style={{ paddingLeft: 5 }}
            >Maksimimitat (m)</Form.Label>
          </Form.Group>
        </Col>
        <Col xs={12} md={6} xl={3}>
          <Form.Group className="mb-4 form-reverse">
            <div
              style={{display: "flex", flexDirection: "row"}}
            >
              <Form.Control
                id="address"
                placeholder={
                  watch("latitude") ?
                    `${watch("latitude")}, ${watch("longitude")} (Paikannettu sijainti)` : "Osoite"
                }
                onKeyUp={onKeyUp}
                {...register("address", {
                  onChange: () => {
                    setValue("latitude", null);
                    setValue("longitude", null);
                  }
                })}
                size="lg"
              />
              <Button variant="secondary" style={{marginLeft: 6}} onClick={
                () => {
                  if(isApp) {
                    window.ReactNativeWebView?.postMessage(
                      JSON.stringify({action: "GET_LOCATION"})
                    );
                  } else {
                    navigator.geolocation.getCurrentPosition(
                      ({coords: {latitude, longitude}}) => {
                        setValue("latitude", latitude);
                        setValue("longitude", longitude);
                        setValue("address", null);
                      });
                  }
                }
              }>
                <BsGeoAlt/>
              </Button>
              <Button variant="success" style={{marginLeft: 6}} onClick={search}>
                Hae
              </Button>
            </div>
            <Form.Label style={{
              paddingLeft: 5
            }}>Sijainti</Form.Label>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default SearchBar;