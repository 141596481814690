import { Col, Container, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { SubmitButton } from "./SubmitButton";
import { useOnError } from "../hooks/useOnError";
import { toast } from "react-toastify";
import useToken from "../hooks/useToken";
import { useCourierService } from "../hooks/services/useCourierService";
import { useCustomerService } from "../hooks/services/useCustomerService";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FormErrorMessage } from "./form/FormErrorMessage";
import { useAdminService } from "../hooks/services/useAdminService";

export const AdminLogin = (props) => {
  const { loginType } = props;

  const { handleSubmit, formState, register, watch }
    = useForm({ defaultValues: { loginType } });

  const customerService = useCustomerService();
  const courierService = useCourierService();
  const adminService = useAdminService();

  const onError = useOnError();
  const [, setToken] = useToken();
  const navigate = useNavigate();

  const userType = watch("loginType");

  const isAdminLogin = loginType === "admin";

  const attemptLogin = useCallback(async data => {
    try {
      const token = await (userType === "admin" ?
        adminService :
        userType === "courier" ?
          courierService :
          customerService).login(data);
      setToken(token);

      toast.success("Kirjautuminen onnistui");
      if (userType === "admin") {
        navigate("/admin/users");
      } else if (userType === "courier") {
        navigate("/courier/deliveries");
      } else {
        navigate("/consumer/deliveries");
      }
    } catch (e) {
      onError(e);
    }
  }, [courierService, customerService, adminService,
    navigate, onError, setToken, userType]);

  return (
    <Container>
      <Col style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "40px"
      }}>
        <h1 style={{ margin: "0" }}>Kirjaudu sisään</h1>
      </Col>
      <Row style={{ justifyContent: "center" }}>
        <Form
          onSubmit={handleSubmit(attemptLogin)}
          className="col-sm-12 col-md-7 col-lg-4"
        >
          {
            isAdminLogin ? (
              <Form.Group className="mb-3" md="2">
                <Form.Label htmlFor="dimensions">Käyttäjätunnus</Form.Label>
                <InputGroup>
                  <FormControl
                    id="username"
                    type="text"
                    isInvalid={formState.errors.username}
                    {...register("username", {
                      required: "Anna käyttäjänimi"
                    })}
                    size="lg"
                  />
                </InputGroup>
                <FormErrorMessage
                  error={formState.errors.username}
                  errorMessage={formState.errors.username?.message}
                />
              </Form.Group>
            )
              : (
                <Form.Group className="mb-3" md="2">
                  <Form.Label htmlFor="dimensions">Sähköposti</Form.Label>
                  <InputGroup>
                    <FormControl
                      id="email"
                      type="text"
                      isInvalid={formState.errors.email}
                      {...register("email", {
                        required: "Anna sähköposti"
                      })}
                      size="lg"
                    />
                  </InputGroup>
                  <FormErrorMessage
                    error={formState.errors.email}
                    errorMessage={formState.errors.email?.message}
                  />
                </Form.Group>
              )
          }

          <Form.Group className="mb-4" md="2">
            <Form.Label htmlFor="dimensions">Salasana</Form.Label>
            <InputGroup>
              <FormControl
                id="password"
                type="password"
                isInvalid={formState.errors.password}
                {...register("password", {
                  required: "Anna salasana"
                })}
                size="lg"
              />
            </InputGroup>
            <FormErrorMessage
              error={formState.errors.password}
              errorMessage={formState.errors.password?.message}
            />
          </Form.Group>
          <Col style={{ display: "flex", justifyContent: "center" }}>
            <SubmitButton
              formState={formState}
              submitValue="Kirjaudu sisään"
              icon={<span className="material-symbols-outlined">login</span>} />
          </Col>
        </Form>
      </Row>
    </Container >
  );
};