import { Button, Col, ListGroup, Row, Card } from "react-bootstrap";
import GradientDiv from "../Gradient";
import {formatDate, formatDateRange, formatPhone} from "../../util/format";
import { DeliveryStatus } from "../../util/constants";
import { useOnError } from "../../hooks/useOnError";
import { toast } from "react-toastify";
import moment from "moment";
import { useDeliveryService } from "../../hooks/services/useDeliveryService";
import { useCourierService } from "../../hooks/services/useCourierService";
import React, { useEffect, useState } from "react";
import { ConfirmDeleteModal } from "../common/ConfirmDeleteModal";
import { SuggestedTransports } from "./SuggestedTransports";
import { CurrencyWithTax, AmountWithTax } from "../common/PriceComponents";
import { AnswerModal } from "./AnswerModal";

export const DeliveryListings = ({
  delivery,
  setActiveDelivery,
  setActiveOfferId,
  setActiveCourierDelivery,
  refresh,
  matchingCourierDeliveries,
  setEditMode
}) => {
  const showCounterOffers = delivery.status === DeliveryStatus.OPEN;
  const showCourierInfo = delivery.status === DeliveryStatus.ACCEPTED;

  const onError = useOnError();
  const deliveryService = useDeliveryService();
  const courierService = useCourierService();

  const [courier, setCourier] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [activeCounterOffer, setActiveCounterOffer] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [showAnswerModal, setShowAnswerModal] = useState(false);
  const [questionId, setQuestionId] = useState(null);


  const refreshAllDeliveries = async () => {
    try {
      await refresh();
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    if (delivery.status === DeliveryStatus.OPEN || delivery.status === DeliveryStatus.ACCEPTED) {
      setQuestions(delivery.questions);
    }
  }, [delivery]);


  useEffect(() => {
    if (activeCounterOffer) {
      setTimeout(() => {
        setActiveCounterOffer(null);
      }, 4000);
    }
  }, [activeCounterOffer]);

  useEffect(() => {
    const courierId = delivery?.accepted?.courier?._id;
    if (!courierId || !showCourierInfo) {
      setCourier(null);
      return;
    }

    if (courier?._id === delivery?.accepted?.courier._id) {
      return;
    }

    let cancel = false;

    (async () => {
      const resp = await courierService.getCouriersByIds([courierId]);

      if (!cancel) {
        if (resp.length > 0) {
          setCourier(resp[0]);
        } else {
          setCourier(null);
        }
      }
    })();

    return () => {
      cancel = true;
    };
  }, [showCourierInfo, delivery, courier?._id]);

  if (showCourierInfo && courier?._id !== delivery?.accepted?.courier?._id) {
    return <h5>Ladataan...</h5>;
  }

  let dateString;
  if (delivery.status === DeliveryStatus.OPEN) {
    dateString = formatDateRange(delivery.dateRange);
  } else if (delivery.status === DeliveryStatus.ACCEPTED) {
    dateString = formatDate(delivery.accepted.date);
  } else if (delivery.status === DeliveryStatus.DELIVERED) {
    dateString = formatDateRange(
      { from: delivery.accepted.date, to: delivery.confirmedDeliveryDate }
    );
  }

  const edit = () => {
    setActiveDelivery(delivery);
    setEditMode(true);
  };

  const remove = async () => {
    try {
      await deliveryService.delete(delivery.id);
    } catch (e) {
      onError(e);
      return;
    }
    toast.success("Kuljetus poistettu onnistuneesti");
    await refresh();
  };

  return (
    <GradientDiv>
      <Row className="mb-2">
        <Col>
          <h5 className="nowrap" style={{ marginBottom: "13px" }}>
            {delivery.origin.postOffice + " - " + delivery.destination.postOffice}
            <span style={{ marginLeft: "16px", fontSize: "16px" }}>{dateString}</span>
          </h5>
          <p
            style={{
              opacity: 0.6,
              fontWeight: 500
            }}
          >Tilaustunniste: {delivery.orderId}</p>
          {
            !!courier && <p>
              <b>Kuljettaja</b><br />
              {courier?.firstName + " " + courier?.lastName}<br />
              {courier?.organization?.name}<br />
              {courier?.phoneNumber? formatPhone(courier.phoneNumber) : ""}<br />
              {courier?.email}<br />
              {courier?.address}
            </p>
          }
        </Col>
      </Row>
      <Row>
        <Col lg={3} md={4} className="mb-3">
          <Button
            className="btn-primary-color"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "1rem"
            }}
            onClick={edit}>
            <span className="material-symbols-outlined">
              {
                delivery?.status === DeliveryStatus.OPEN ? "edit" : "visibility"
              }
            </span>
            {
              delivery?.status === DeliveryStatus.OPEN ? "Muokkaa" : "Näytä"
            }
          </Button>
        </Col>
        {
          delivery?.status === DeliveryStatus.OPEN && <Col lg={3} md={4}>
            <Button
              className="btn-text" style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1rem"
              }}
              onClick={() => setShowDelete(true)}
            >
              <span className="material-symbols-outlined">delete</span>
              Poista
            </Button>
            <ConfirmDeleteModal
              isOpen={showDelete}
              deleteAction={remove}
              close={() => setShowDelete(false)}
              targetItem={
                `${delivery.origin.postOffice} - ${delivery.destination.postOffice} ${dateString}`
              }
            />
          </Col>
        }
      </Row>
      {
        showCounterOffers && (() => {
          const from = moment(delivery.dateRange.from);
          const to = moment(delivery.dateRange.to);

          const counterOffers = (delivery?.counterOffers ?? []).map(offer => {
            if (offer.amount > delivery.price) {
              return offer;
            }

            const date = moment(offer.date);

            if (!date.isSameOrAfter(from) || !date.isSameOrBefore(to)) {
              return offer;
            }

            return { ...offer, matchesListing: true };
          });

          counterOffers.sort((a, b) => {
            if (a.matchesListing === b.matchesListing) {
              return moment(a.date).toDate().getTime() - moment(b.date).toDate().getTime();
            }

            if (a.matchesListing) {
              return -1;
            } else {
              return 1;
            }
          });

          return <>
            <Row>
              <Col xs={12}>
                <h5 className="nowrap">Vastatarjoukset:</h5>
                {
                  counterOffers.length > 0 ?
                    <ListGroup>
                      {
                        counterOffers.map((offer, index) =>
                          <React.Fragment
                            key={index}
                          >
                            <ListGroup.Item
                              className={
                                activeCounterOffer === offer._id ?
                                  "background-easeout ease-out" : ""
                              }
                              onClick={_ => {
                                setActiveOfferId(offer._id);
                                setActiveDelivery(delivery);
                              }}
                              action
                            >
                              <div style={{
                                display: "flex"
                              }}>
                                <div style={{
                                  flex: 1
                                }}>
                                  <p>Yritys: {offer.courier.organization?.name}</p>
                                  <p
                                    style={{
                                      display: "inline"
                                    }}
                                  >
                                    {offer.matchesListing && (
                                      <span style={{
                                        color: "#12C312",
                                        marginRight: 7,
                                        display: "inline"
                                      }}>
                                        Ilmoituksen mukainen
                                      </span>)
                                    }
                                    {
                                      moment(offer.date).format("D.M.YYYY")
                                    } - <AmountWithTax amt={offer.amount}/>
                                    <CurrencyWithTax/>
                                  </p>
                                </div>
                                <div style={{
                                  flex: 1,
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center"
                                }}>
                                  {offer.courier.organization?.trustedPartner && (
                                    <img src="/partner.png" alt="trustedPartner"/>
                                  )}
                                </div>
                              </div>
                            </ListGroup.Item>
                          </React.Fragment>
                        ) ?? []
                      }
                    </ListGroup>
                    :
                    <>
                      <h6 className="px-3">Ei tarjouksia</h6>
                    </>
                }
              </Col>
              <SuggestedTransports
                delivery={delivery}
                matchingCourierDeliveries={matchingCourierDeliveries}
                setActiveDelivery={setActiveDelivery}
                setActiveCourierDelivery={setActiveCourierDelivery}
                setActiveCounterOffer={setActiveCounterOffer}
              />
              <Col xs={12}>
                <h5 className="nowrap">Kysymykset:</h5>
                <h6>
                  Vastaamattomat kysymykset: {questions.filter(q => !q.answer).length}
                </h6>
                {
                  questions.length > 0 ?
                    questions
                      .filter(q => !q.answer)
                      .sort((a, b) => new Date(b.date) - new Date(a.date))
                      .map((question, index) =>
                        <Card
                          key={index}
                          className="mb-3"
                          style={{
                            padding: "1rem"
                          }}
                        >
                          <Card.Title>
                            <div style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}>
                              <div>
                                <b>{question.question}</b>
                              </div>
                              <div>
                                {moment(question.date).format("D.M.YYYY")}
                              </div>
                            </div>
                          </Card.Title>  
                          <Card.Body>
                            <p>{question.answer || "Ei vastausta"}</p>
                          </Card.Body>
                          {!question.answer && (
                            <Button className="btn-primary-color" onClick={
                              () => {
                                setQuestionId(question._id);
                                setShowAnswerModal(true);
                              }
                            }>
                              Vastaa
                            </Button>
                          )}
                          

                        </Card>
                      ) ?? []
                    :
                    "Ei kysymyksiä"
                }
                <h6>Vastatut kysymykset: {questions.filter(q => q.answer).length}</h6>
                {
                  questions.length > 0 ?
                    questions
                      .filter(q => q.answer)
                      .sort((a, b) => new Date(b.date) - new Date(a.date))
                      .map((question, index) =>
                        <Card
                          key={index}
                          className="mb-3"
                          style={{
                            padding: "1rem"
                          }}
                        >
                          <Card.Title>
                            <div style={{
                              display: "flex",
                              justifyContent: "space-between"
                            }}>
                              <div>
                                <b>{question.question}</b>
                              </div>
                              <div>
                                {moment(question.date).format("D.M.YYYY")}
                              </div>
                            </div>
                          </Card.Title>
                          <Card.Body>
                            <p>{question.answer || "Ei vastausta"}</p>
                          </Card.Body>
                        </Card>
                      ) ?? []
                    :
                    "Ei kysymyksiä"
                }
                

                
              </Col>
              <AnswerModal 
                isOpen={showAnswerModal} 
                close={() => setShowAnswerModal(false)} 
                questionId={questionId} 
                refreshAllDeliveries={refreshAllDeliveries}
                delivery={delivery} 
              />
            </Row>
          </>;
        })()
      }
    </GradientDiv >
  );
};