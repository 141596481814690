import { Form, FormControl, InputGroup, Col, Row } from "react-bootstrap";
import { FormErrorMessage } from "../../form/FormErrorMessage";

export default function GoodsModal(props) {
  const { disabled, errors, clearErrors } = props;
  return (
    <>
      <Form.Group className="mb-2" md="2">
        <Form.Label htmlFor="stuffName">Tuote</Form.Label>
        <InputGroup>
          <FormControl
            id="stuffName"
            type="text"
            isInvalid={errors.goods?.[props.index]?.name}
            onInput={() => clearErrors(`goods.${props.index}.name`)}
            {...props.register(`goods.${props.index}.name`, {
              required: "Vaadittu kenttä"
            })}
            disabled={disabled}
          />
        </InputGroup>
        <FormErrorMessage
          error={errors.goods?.[props.index]?.name}
          errorMessage={errors.goods?.[props.index]?.name?.message}
        />
      </Form.Group>

      <Form.Label htmlFor="dimensions">Tilan määrä</Form.Label>
      <Row>
        {
          ["x", "y", "z"].map(d => <Col
            key={d}
            xs={12}
            sm={4}
          >
            <Form.Group className="mb-2">
              <InputGroup>
                <FormControl
                  id={`dimension${d.toUpperCase()}`}
                  type="number"
                  step={0.001}
                  min={0.001}
                  isInvalid={errors.goods?.[props.index]?.dimensions?.[d]}
                  onInput={() => clearErrors(`goods.${props.index}.dimensions.${d}`)}
                  {...props.register(`goods.${props.index}.dimensions.${d}`, {
                    required: "Vaadittu kenttä"
                  })}
                  disabled={disabled}
                />
                <InputGroup.Text>m</InputGroup.Text>
              </InputGroup>
              <FormErrorMessage
                error={errors.goods?.[props.index]?.dimensions?.[d]}
                errorMessage={errors.goods?.[props.index]?.dimensions?.[d]?.message}
              />
            </Form.Group>
          </Col>) ?? []
        }
      </Row>

      <Form.Group className="mb-2" md="2">
        <Form.Label htmlFor="dimensions">Paino</Form.Label>
        <InputGroup>
          <FormControl
            id="weight"
            type="number"
            min={0.1}
            step={0.1}
            isInvalid={errors.goods?.[props.index]?.weight}
            onInput={() => clearErrors(`goods.${props.index}.weight`)}
            {...props.register(`goods.${props.index}.weight`, {
              required: "Vaadittu kenttä"
            })}
            disabled={disabled}
          />
          <InputGroup.Text>kg</InputGroup.Text>
        </InputGroup>
        <FormErrorMessage
          error={errors.goods?.[props.index]?.weight}
          errorMessage={errors.goods?.[props.index]?.weight?.message}
        />
      </Form.Group>
      <Form.Group className="mb-2" md="2">
        <Form.Label htmlFor="amount">Määrä</Form.Label>
        <InputGroup>
          <FormControl
            id="amount"
            type="number"
            min={1}
            defaultValue={1}
            isInvalid={errors.goods?.[props.index]?.amount}
            onInput={() => clearErrors(`goods.${props.index}.amount`)}
            {...props.register(`goods.${props.index}.amount`, {
              required: "Vaadittu kenttä"
            })}
            disabled={disabled}
          />
          <InputGroup.Text>kpl</InputGroup.Text>
        </InputGroup>
        <FormErrorMessage
          error={errors.goods?.[props.index]?.amount}
          errorMessage={errors.goods?.[props.index]?.amount?.message}
        />
      </Form.Group>
      <InputGroup className="mb-2 mt-3" md="2" style={{justifyContent: "center"}}>
        <Form.Check
          id="fragile"
          label="Särkyvää"
          {...props.register(`goods.${props.index}.fragile`)}
          disabled={disabled}
        />
      </InputGroup>
      <Form.Label htmlFor="info">Lisätietoa</Form.Label>
      <Form.Control
        id="info"
        as="textarea"
        rows={3}
        {...props.register(`goods.${props.index}.info`)}
        disabled={disabled}
      />
    </>
  );
}