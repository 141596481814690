import Modal from "react-modal";
import CreateDelivery from "./Create/CreateDelivery";
import {DeliveryStatus} from "../../util/constants";
import {defaultModalStyles as modalStyles} from "../../styles/defaultModalStyles";

export const DeliveryModal = (props) => {
  const {delivery, close, refresh} = props;

  // TODO: refresh parent list after edit
  return (
    <Modal
      isOpen={delivery !== null}
      onRequestClose={close}
      style={modalStyles}
    >
      {
        delivery ?
          <CreateDelivery
            fullWidth={true}
            disabledFields={
              [
                ...["origin", "destination"].map(
                  place => ["postOffice", "postCode", ...(
                    delivery.status === DeliveryStatus.OPEN ?
                      [] : ["street"]
                  )].map(field => `${place}.${field}`)
                ).flat(),
                ...(
                  delivery.status === DeliveryStatus.OPEN ?
                    []
                    :
                    [
                      "goods",
                      "unloading",
                      "price",
                      "waybillNo",
                      "info",
                      "dateRange"
                    ]
                )
              ]
            }
            {...{refresh, close, delivery}}
          /> :
          null
      }
    </Modal>
  );
};
