import axios from "axios";
import {globalState} from "../util/globalState";
import {useAuth} from "./useAuth";

const cacheStore = globalState({});

export const useCache = (basePath, name = "<unknown>") => {
  const auth = useAuth();

  const [cache, setCache] = cacheStore.use();

  return {
    current: (ids, forceRefresh = false) => new Promise(async resolve => {
      let idsNotInCache;
      if(!forceRefresh) {
        idsNotInCache = (
          cache[name] ? ids.filter(id => !cache[name][id]) : ids
        ).join(",");
      } else {
        idsNotInCache = ids;
      }

      if (idsNotInCache.length === 0) {
        resolve(ids.map(id => cache[name][id]));
        return;
      }

      const results = (
        await axios.get(basePath, {params: {ids: idsNotInCache}, ...auth}))?.data ?? [];
      setCache(oldValue => {
        const newValue = {
          ...oldValue,
          [name]: {
            ...(oldValue[name] ?? {}),
            ...Object.fromEntries(results.map(result => [result._id, result]))
          }
        };

        console.log(name, "cache size:", Object.keys(newValue[name] ?? {}).length);


        (async () => resolve(ids.map(id => newValue[name][id])))();

        return newValue;
      });
    })
  };
};