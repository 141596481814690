import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Modal from "react-modal";
import { useOnError } from "../../hooks/useOnError";
import { toast } from "react-toastify";
import { useCourierDeliveryService } from "../../hooks/services/useCourierDeliveryService";
import {defaultModalStyles} from "../../styles/defaultModalStyles";

const modalStyles = {
  content: {
    ...defaultModalStyles.content,
    width: "40%",
    marginTop: "100px",
  }
};

export const RejectModal = ({
  isOpen,
  close,
  requestId,
  refreshAllDeliveries,
  courierDelivery
}) => {
  const [rejectReason, setRejectReason] = useState("");
  const courierDeliveryService = useCourierDeliveryService();
  const onError = useOnError();

  const reject = async () => {
    if (rejectReason.trim() === "") {
      toast.error("Hylkäämisen syy puuttuu");
      return;
    }

    try {
      await courierDeliveryService.rejectRequest(courierDelivery.id, requestId, { rejectReason });
    } catch (e) {
      onError(e);

      if (e?.response?.status === 409) {
        alert(e?.response?.data);

        window.location.reload();
      }

      return;
    }
    close();
    toast.success(
      "Kuljetuspyyntö hylätty onnistuneesti"
    );
    refreshAllDeliveries();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={modalStyles}
    >
      {
        <div>
          <Row className="mb-3">
            <Col>
              <h4>Olet hylkäämässä kuljetuspyyntöä</h4>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col xs={6}>
              <Form.Group>
                <Form.Label htmlFor="rejectReason">
                  Kerro asiakkaalle hylkäämisen peruste.<br/><br/>
                  Huomaa että jos ilmoitat yksityishenkilöille
                  toivomaasi hintaa niin lisää hintaan alv. 24%
                </Form.Label>
                <Form.Control
                  name="rejectReason"
                  value={rejectReason}
                  onChange={(evt) => setRejectReason(evt.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={6}>
              <Button
                id="acceptDelivery"
                className="btn-primary-color"
                onClick={reject}
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <span className="material-symbols-outlined">mail</span>
                Lähetä
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                id="cancelDelivery" className="btn-text" onClick={close}
                style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <span className="material-symbols-outlined">close</span>
                Peruuta
              </Button>
            </Col>

          </Row>
        </div>
      }
    </Modal>
  );
};