import { useAuth } from "../useAuth";
import axios from "axios";

export const useAdminService = () => {
  const auth = useAuth();
  const basePath = "/api/admins";

  return {
    login: async (data) => (await axios.post(`${basePath}/login`, data))?.data,
    profile: async () => (await axios.get(`${basePath}/profile`, auth))?.data,
    getUnconfirmedUsers: async () => (await axios.get(`${basePath}/getunconfirmed`, auth))?.data,
    changeCourierStatus: async (id, accountStatus) => (
      await axios.post(
        `${basePath}/changecourierstatus`, { id: id, accountStatus: accountStatus }, auth
      )
    )?.data,
    modifyCourier: async data => (await axios.put(`${basePath}/modifycourier`, data, auth))?.data,
    searchDelivery: async data => (
      await axios.post(`${basePath}/search-delivery`, data, auth)
    )?.data,
    getBusinesses: async () => (await axios.get(`${basePath}/organizations`, auth))?.data,
    modifyOrganization: async data => (
      await axios.put(`${basePath}/modify-organization`, data, auth)
    )?.data,
    changeBusinessStatus: async (id, type, accountStatus) => (
      await axios.post(
        `${basePath}/change-business-status`, { id, type, accountStatus }, auth
      )
    )?.data,
  };
};