import { useEffect } from "react";
import { Alert, Button, Form, FormControl } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-modal";
import PhoneInput from "react-phone-input-2";
import { useCustomerService } from "../../hooks/services/useCustomerService";
import useToken from "../../hooks/useToken";
import { FormErrorMessage } from "../form/FormErrorMessage";
import { toast } from "react-toastify";
import { useOnError } from "../../hooks/useOnError";
import { useNavigate } from "react-router-dom";
import {defaultModalStyles as modalStyles} from "../../styles/defaultModalStyles";

export const SocialMediaRegister = ({
  isOpen,
  close,
  profileData,
  creds
}) => {
  const [, setToken] = useToken();
  const customerService = useCustomerService();
  const onError = useOnError();
  const navigate = useNavigate();

  const form = useForm({
    reValidateMode: "onSubmit",
    defaultValues: {}
  });
  const {
    register,
    unregister,
    handleSubmit,
    formState,
    reset,
    clearErrors,
    control,
    getValues,
    setValue,
    watch
  } = form;

  useEffect(() => {
    if (profileData) {
      setValue("name", profileData.name);
      setValue("email", profileData.email);
      setValue("socialMedias", profileData.socialMedias);
      setValue("socialMediaRegister", true);
    }
  }, [profileData]);

  const attemptRegister = async data => {
    try {
      await customerService.create(data);
      let resp;

      if (profileData.socialMedias.google) {
        resp = await customerService.socialMediaLogin({
          socialMedia: "google",
          credentials: {
            accessToken: creds.credential
          }
        });
      } else if (profileData.socialMedias.facebook) {
        resp = await customerService.socialMediaLogin({
          socialMedia: "facebook",
          credentials: creds
        });
      }
      setToken(resp.loginToken);
      reset();
      navigate("/consumer/deliveries");
      toast.success("Rekisteröityminen onnistui");
    } catch (e) {
      onError(e);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={modalStyles}
    >
      <Alert variant="light" style={{ padding: 0 }}>
        <Alert.Heading>
          Rekisteröinnin viimeistely
        </Alert.Heading>
        <p>Uutena käyttäjänä pyydämme sinua tarkistamaan saamamme tiedot
          sekä lisäämään puuttuvat.</p>

        <Form
          noValidate
          onSubmit={handleSubmit(attemptRegister)}
          className="w-100 col-sm-8 col-md-6"
        >
          <Form.Group className="mb-2" md="2">
            <Form.Label htmlFor="name">Nimi</Form.Label>
            <FormControl
              isInvalid={formState.errors.name}
              id="name"
              type="text"
              onInput={() => clearErrors("name")}
              {...register("name", { required: "Vaadittu kenttä" })}
            />

            <FormErrorMessage
              error={formState.errors.name}
              errorMessage={formState.errors.name?.message}
            />
          </Form.Group>

          <Form.Group className="mb-2" md="2">
            <Form.Label htmlFor="email">Sähköposti</Form.Label>
            <FormControl
              id="email"
              type="text"
              isInvalid={formState.errors.email}
              disabled={true}
              onInput={() => clearErrors("email")}
              {...register("email", {
                required: "Vaadittu kenttä",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Sähköposti väärässä muodossa"
                }
              })}
            />
            <FormErrorMessage
              error={formState.errors.email}
              errorMessage={formState.errors.email?.message}
            />
          </Form.Group>

          <Form.Group className="mb-2" md="2">
            <Form.Label htmlFor="name">Asiakkuustyyppi</Form.Label>
            <Controller
              name="customerType"
              control={control}
              rules={{
                required: "Valitse asiakkuustyyppi"
              }}
              render={(props) => {
                return (
                  <div style={{ display: "flex" }}>
                    <Form.Check
                      name="customerType"
                      type="radio"
                      value="person"
                      aria-label="radio_person"
                      id="radio_person"
                      label="Yksityishenkilö"
                      isInvalid={formState.errors.customerType}
                      onChange={evt => {
                        clearErrors("customerType");
                        props.field.onChange(evt.target.value);
                        unregister("businessId");
                      }}
                      checked={getValues("customerType") === "person"}
                      style={{ marginRight: "1.5rem" }}
                    />
                    <Form.Check
                      name="customerType"
                      type="radio"
                      value="company"
                      aria-label="radio_company"
                      id="radio_company"
                      label="Yritys"
                      isInvalid={formState.errors.customerType}
                      onChange={evt => {
                        clearErrors("customerType");
                        props.field.onChange(evt.target.value);
                      }}
                      checked={getValues("customerType") === "company"}
                      style={{ marginRight: "1.5rem" }}
                    />
                  </div>
                );
              }}
            />
            <FormErrorMessage
              error={formState.errors.customerType}
              errorMessage={formState.errors.customerType?.message}
            />
          </Form.Group>

          {
            watch("customerType") === "company" && (
              <Form.Group className="mb-2" md="2">
                <Form.Label htmlFor="businessId">Y-tunnus</Form.Label>
                <FormControl
                  id="businessId"
                  type="text"
                  isInvalid={formState.errors.businessId}
                  onInput={() => clearErrors("businessId")}
                  {...register("businessId", {
                    required: "Vaadittu kenttä",
                    minLength: {
                      value: 5,
                      message: "Y-tunnus liian lyhyt"
                    },
                    maxLength: {
                      value: 16,
                      message: "Y-tunnus liian pitkä"
                    }
                  })}
                />
                <FormErrorMessage
                  error={formState.errors.businessId}
                  errorMessage={formState.errors.businessId?.message}
                />
              </Form.Group>
            )
          }

          <Form.Group className="mb-2" md="2">
            <Form.Label>Puhelinnumero</Form.Label>
            <Controller
              name="phoneNumber"
              control={control}
              rules={{
                required: "Vaadittu kenttä"
              }}
              render={(props) => {
                return (
                  <PhoneInput
                    value={props.field.value}
                    onChange={phone => {
                      clearErrors("phoneNumber");
                      props.field.onChange(phone);
                    }}
                    containerStyle={{
                      width: "100%"
                    }}
                    inputStyle={{
                      width: "100%"
                    }}
                    autoFormat={true}
                    specialLabel="Puhelinnumero"
                    preferredCountries={["fi"]}
                    country={"fi"}
                    masks={{ fi: ".. ......." }}
                    placeholder={"+358 45 1234567"}
                    isValid={() => !formState.errors.phoneNumber}
                  />
                );
              }}
            />
            <FormErrorMessage
              error={formState.errors.phoneNumber}
              errorMessage={formState.errors.phoneNumber?.message}
            />
          </Form.Group>

          <div className="d-flex justify-content-end mt-4">
            <Button
              id="close"
              variant="outline-primary"
              onClick={close}
              className="btn-outline me-3"
            >
              Peruuta
            </Button>
            <Button
              type="submit"
              className="btn-primary-color"
            >
              Viimeistele
            </Button>
          </div>
        </Form>
      </Alert>
    </Modal>
  );
};