export const Unloading = {
  ALL: "Kaikki",
  CRANE: "Nosturi",
  TAILBOARD: "Perälautapurku",
  NOINFO: "Ei tietoa",
  NONE: "Ei purkua"
};

export const DeliveryStatus = {
  OPEN: "OPEN",
  ACCEPTED: "ACCEPTED",
  DELIVERED: "DELIVERED"
};

export const CourierDeliveryStatusText = {
  OPEN: "Avoin",
  WAITING: "Odottaa asiakkaan hyväksyntää"
};

export const VAT = 24;