import { Button, Row, Col, Form } from "react-bootstrap";
import {useOnError} from "../../../hooks/useOnError";
import {usePaymentService} from "../../../hooks/services/usePaymentService";
import { AgreementInput } from "../../register/AgreementInput.js";
import { AgreementModal } from "../../register/AgreementModal.js";
import { useState } from "react";
import { useForm } from "react-hook-form";

export default function VismaPayInfo(props) {
  const {delivery, offerId, setDisplayPayInfo} = props;
  const paymentService = usePaymentService();

  const offer = delivery?.counterOffers.find((offer) => offer._id === offerId);

  const onError = useOnError();

  const [activeAgreement, setActiveAgreement] = useState("");
  
  const form = useForm({
    defaultValues: {
      visma: false
    }
  });
  const {
    register,
    formState,
    handleSubmit,
    clearErrors,
    watch
  } = form;

  return (
    <>
      <Row>
        <Col xs={3} className="mb-3">
          <Button
            className="btn-primary-color"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "1rem",
            }}
            onClick={() => setDisplayPayInfo(false)}
          >Takaisin</Button>
        </Col>
      </Row>
      <Row className="mb-3">
        {
          window.innerWidth > 1300 ?
            <Col xs={12}>
              <img
                style={{width: "100%"}}
                src="https://static.vismapay.com/pay_banners/row.png"
                alt="maksumuodot"
              />
            </Col> :
            <Col xs={12} style={{alignItems: "center"}}>
              <img
                style={{width: 191, display: "flex", margin: "auto"}}
                src="https://static.vismapay.com/pay_banners/column.png"
                alt="maksumuodot"
              />
            </Col>
        }
      </Row>
      <Row>
        <p>Siirry maksamaan kuljetus Visma pay -maksupalvelulla</p>
      </Row>
      <Form onSubmit={handleSubmit(async _ => {
        try {
          const response = await paymentService.create(delivery._id, offer._id);
          window.location.href = response.url;
        } catch (e) {
          onError(e);
        }
      })}
      >
        <Row>
          <AgreementInput
            register={register}
            clearErrors={clearErrors}
            formState={formState}
            agreementType="visma"
            linkText="toimitusehdot"
            linkTextPrefix={"Hyväksyn "}
            setActiveAgreement={setActiveAgreement}
            noLink={true}
          />
        </Row>
        <Row>
          <Col xs={6}>
            <Button
              className="btn-primary-color"
              type="submit"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1rem",
              }}
              disabled={watch("visma", false)}
            >
              <span className="material-symbols-outlined">payment</span>
              <span>Siirry maksamaan</span>
            </Button>
          </Col>
        </Row>
      </Form>
      <AgreementModal
        activeAgreement={activeAgreement}
        setActiveAgreement={setActiveAgreement}
      />
    </>
  );
}