import { DeliveryStatus } from "./constants";

export const formatDate = (isoString) => {
  return new Date(isoString).toLocaleDateString("fi");
};

export const formatAddress = (address, status) => {
  //Hide accurate address for Open deliveries
  if(status === DeliveryStatus.OPEN) {
    return <span>
      <span style={{
        color: "transparent",
        textShadow: "0 0 8px #000",
        userSelect: "none"
      }}
      title="Saat tarkan osoitteen kun tarjous hyväksytään"
      >
        Äläkurkikatu 3        
      </span>
      , {address.postCode + " " + address.postOffice}
    </span>;
  } else {
    return address.street + ", " + address.postCode + " " + address.postOffice;
  }
};

export const formatDateRange = (dateRange) => {
  return formatDate(dateRange.from) + " - " + formatDate(dateRange.to);
};

export const formatPriceToEuros = (price) => {
  return price / 100;
};

export const formatPhone = (str) => {
  if(str === "" || (str[0] === "0" || str[0] === "+")) {
    return str;
  } else {
    return "+" + str;
  }
};