import { Col, Row } from "react-bootstrap";
import { useUserContext } from "../../../contexts/UserContext";
import { OfferItem } from "./OfferItem";

export const OfferListing = ({ delivery, refreshAllDeliveries }) => {
  const { user } = useUserContext();

  return (
    <Row
      className="pt-4"
    >
      <Col xs={12}>
        <h5 className="nowrap mb-3">Omat tarjoukset:</h5>
      </Col>
      {
        delivery.counterOffers.map((offer) => (
          offer.courier._id === user._id ? (
            <OfferItem
              key={offer._id}
              deliveryId={delivery.id}
              offer={offer}
              refreshAllDeliveries={refreshAllDeliveries}
            />
          ) : null
        ))
      }
    </Row>
  );
};