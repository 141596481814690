import Modal from "react-modal";
import { Button, Alert } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Modes } from "./ConsumerRegister";
import { CourierModes } from "./CourierRegister";
import { useUserContext } from "../../contexts/UserContext";
import { useCourierService } from "../../hooks/services/useCourierService";
import {defaultModalStyles} from "../../styles/defaultModalStyles";

const modalStyles = {
  content: {
    ...defaultModalStyles.content,
    width: "60%",
  }
};

export const RegisterModal = props => {
  const {/** @type boolean **/ registrationSuccessful } = props;
  const navigate = useNavigate();
  const { setUser } = useUserContext();
  const courierService = useCourierService();

  const getNavUrl = () => {
    if (props.mode === Modes.PASSWORD || props.mode === CourierModes.PASSWORD) {
      navigate("/login");
    } else if (props.userType === "courier") {
      navigate("/courier/deliveries");
    } else {
      navigate("/consumer/deliveries");
    }
  };

  return (
    <Modal
      isOpen={registrationSuccessful}
      style={modalStyles}
    >
      <div>
        <Alert variant="light" style={{padding: 0}}>
          <Alert.Heading>
            {
              props.mode === Modes.FULL || props.mode === CourierModes.FULL ?
                "Rekisteröinti onnistui" :
                "Tallentaminen onnistui"
            }
          </Alert.Heading>
          <div className="d-flex justify-content-end mt-4">
            <Button
              id="close"
              onClick={async () => {
                if(props.mode !== Modes.Password && props.mode !== CourierModes.PASSWORD) {
                  if(props.userType === "courier") {
                    setUser(await courierService.profile());
                  }
                }
                getNavUrl();
              }}
              className="btn-primary-color"
            >
              Poistu
            </Button>
          </div>
        </Alert>
      </div>
    </Modal>
  );
};
