import { useEffect, useState } from "react";

export const useCountdown = (seconds) => {
  const [timer, setTimer] = useState(seconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);

    if (timer === 0) clearInterval(interval);

    return () => clearInterval(interval);
  }, [timer]);

  const resetInterval = (seconds) => setTimer(seconds);

  return {
    timer,
    resetInterval
  };
};