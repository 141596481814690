import { Form } from "react-bootstrap";
import { CheckCircle, Circle } from "react-bootstrap-icons";
import { FormErrorMessage } from "./FormErrorMessage";

const ValidationDisplay = (props) => {
  return <p>
    <span style={{ display: "inline-flex", alignItems: "center" }}>
      {props.valid ? <CheckCircle /> : <Circle />}

      <span className="px-1">{props.children}</span>
    </span>
  </p>;
};

function FormNewPassword(props) {
  const { name, form: { formState, watch, register, clearErrors } } = props;

  const includesNumber = () => {
    if (!watch(name).match(/\d/g))
      return "Salasana ei täytä vaatimuksia";
  };

  return <>
    <Form.Label>{props.edit ? "Uusi s" : "S"}alasana</Form.Label>
    <Form.Control
      required
      type="password"
      name="password"
      isInvalid={formState.errors.password}
      onInput={() => clearErrors("password")}
      {...register(name, !props.edit ? {
        required: "Vaadittu kenttä",
        minLength: {
          value: 8,
          message: "Salasana ei täytä vaatimuksia"
        },
        validate: includesNumber
      } : {})}
    />
    <FormErrorMessage
      error={formState.errors.password}
      errorMessage={formState.errors.password?.message}
    />
    <div className="my-2" />
    <ValidationDisplay valid={watch(name).length >= 8}>8 merkkiä</ValidationDisplay>
    <ValidationDisplay valid={watch(name).match(/\d/g)}>1 numero</ValidationDisplay>
    <ValidationDisplay valid={watch(name).match(/\D/g)}>
      1 merkki, joka ei ole numero
    </ValidationDisplay>
  </>;
}

export default FormNewPassword;