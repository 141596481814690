import { useMemo } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useUserContext } from "../../contexts/UserContext";

export function NavLinksCourier({ mobile }) {
  const { user } = useUserContext();

  const isAdmin = useMemo(() => {
    return user?.organization?.couriers?.find((member) =>
      member.courier === user._id)?.role === "admin";
  }, [user]);

  const isUnconfirmed = useMemo(() => {
    return user?.organization?.accountStatus === "unconfirmed";
  }, [user]);


  return (
    <>
      {isUnconfirmed && (
        <span
          className="sidebar-notification"
        >Osa toiminnoista on estetty, koska yritys odottaa ylläpidon vahvistusta</span>
      )}
      <Nav.Link
        className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
        disabled={isUnconfirmed}
        as={Link}
        to="courier/deliveries"
        href="deliveries"
      >
        {!mobile && <span className="material-symbols-outlined sidebar-item-icon">package</span>}
        <span className={mobile ? "" : "sidebar-item-text"}>Omat kuljetukset</span>
      </Nav.Link>
      <Nav.Link
        className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
        disabled={isUnconfirmed}
        as={Link}
        to="courier/search"
        href="search"
      >
        {!mobile && <span className="material-symbols-outlined sidebar-item-icon">explore</span>}
        <span className={mobile ? "" : "sidebar-item-text"}>Hae kuljetuksia</span>
      </Nav.Link>
      <Nav.Link
        className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
        disabled={isUnconfirmed}
        as={Link}
        to="courier/create"
        href="create"
      >
        {!mobile && <span className="material-symbols-outlined sidebar-item-icon">add</span>}
        <span className={mobile ? "" : "sidebar-item-text"}>Luo kuljetus</span>
      </Nav.Link>
      <Nav.Link
        className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
        as={Link}
        to="courier/questions"
        href="questions"
      >
        {!mobile && <span className="material-symbols-outlined sidebar-item-icon">
          question_answer
        </span>}
        <span className={mobile ? "" : "sidebar-item-text"}>Kysymykset</span>
      </Nav.Link>
      {
        isAdmin && (
          <Nav.Link
            className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
            as={Link}
            to="courier/organization"
            href="courierOrganization"
          >
            {!mobile && <span className="material-symbols-outlined sidebar-item-icon">group</span>}
            <span className={mobile ? "" : "sidebar-item-text"}>Yritys</span>
          </Nav.Link>
        )
      }
      <Nav.Link
        className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
        as={Link}
        to="courier/profile"
        href="courierProfile"
      >
        {!mobile && <span className="material-symbols-outlined sidebar-item-icon">person</span>}
        <span className={mobile ? "" : "sidebar-item-text"}>Omat tiedot</span>
      </Nav.Link>
      <a
        className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
        href="https://finlandcargo.com/yhteystiedot/"
        style={{textDecoration: "none"}}
        target="_blank"
        rel="noreferrer"
      >
        {!mobile &&
          <span className="material-symbols-outlined sidebar-item-icon">support_agent</span>}
        Ota yhteyttä
      </a>
    </>
  );
}