import {useCallback, useRef} from "react";

export const useDebounce = (fn, delay) => {
  const timeoutRef = useRef(null);

  return useCallback((...newArgs) => {
    if (timeoutRef.current) {
      window.clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = window.setTimeout(() => fn(...newArgs), delay);
  }, [delay, fn, timeoutRef]);
};