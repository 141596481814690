import Modal from "react-modal";
import {Alert, Button} from "react-bootstrap";
import {defaultModalStyles} from "../../../styles/defaultModalStyles";

const modalStyles = {
  content: {
    ...defaultModalStyles.content,
    width: "40%",
  }
};

export const ConfirmationModal = (props) => {
  const {delivery, close} = props;

  return (
    <Modal
      isOpen={delivery !== null}
      onRequestClose={close}
      style={modalStyles}
    >
      {
        delivery ?
          (
            !delivery.error ?
              <div>
                <Alert variant="light">
                  <Alert.Heading>Kuljetus lisätty</Alert.Heading>
                  <div className="d-flex justify-content-end">
                    <Button id="close" onClick={close} className="btn-primary-color">Poistu</Button>
                  </div>
                </Alert>
              </div>
              :
              <div>
                <Alert variant="warning">
                  <Alert.Heading>Virhe: Kuljetuksen lisääminen ei onnistunut</Alert.Heading>
                  <p>{delivery.error}</p>
                  <div className="d-flex justify-content-end">
                    <Button id="close" onClick={close} className="btn-primary-color">Poistu</Button>
                  </div>
                </Alert>
              </div>
          ) :
          null
      }
    </Modal>
  );
};