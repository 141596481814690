import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useCustomerService } from "../../hooks/services/useCustomerService";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useCourierService } from "../../hooks/services/useCourierService";


const statusElements = {
  200: (userType, _, data) => (
    <div>
      <p>{data}</p>
      <Nav.Link
        as={Link}
        to={`../../${userType === "customer" ? "consumer" : "courier"}/deliveries`}
        href={`${userType === "customer" ? "consumer" : "courier"}/deliveries`}
      >
        <button className="btn btn-primary-color">
          Paina tästä siirtyäksesi sovellukseen
        </button>
      </Nav.Link>
    </div>
  ),
  404: () => "Väärä koodi",
  410: (_, renewVerify) => (
    <div>
      <p>Koodi vanhentunut</p>
      <button
        className="btn btn-primary-color"
        onClick={renewVerify}
      >
        Lähetä uusi koodi
      </button>
    </div>
  )
};

export default function Profile(props) {
  const { userType, verifyType, hash } = useParams();
  const [response, setResponse] = useState(null);

  const customerService = useCustomerService();
  const courierService = useCourierService();

  useEffect(() => {
    const verify = async () => {
      try {
        const response = userType === "customer" ?
          await customerService.verify(hash) : await courierService.verify(verifyType, hash);
        setResponse({ status: response.status, data: response.data });
      } catch (e) {
        setResponse({ status: e.response.status });
      }
    };

    verify();
  }, []);

  const renewVerify = async () => {
    userType === "customer" ?
      await customerService.renewVerify({ hash }) :
      await courierService.renewVerify({ verifyType, hash });
    toast.success("Uusi koodi lähetetty");
  };

  return (
    <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3">
      {statusElements[response?.status]?.(userType, renewVerify, response.data)}
    </div>
  );
}