import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useUserContext } from "../../../contexts/UserContext";
import useToken from "../../../hooks/useToken";

export const CheckOrganization = () => {
  const { user } = useUserContext();
  const [token] = useToken();
  const { pathname } = useLocation();

  const joinPath = "/courier/join-organization";

  return (token && token.length > 0) ?
    (() => {
      if (user && !user?.organization && pathname !== joinPath) {
        return <Navigate to={joinPath} />;
      }

      return <Outlet />;
    })()
    :
    <Navigate to="/login" />;
};