import { useState } from "react";
import GoodsModal from "./GoodsModal";
import { Button, Card } from "react-bootstrap";
import "./createDelivery.css";
import { ConfirmDeleteModal } from "../../common/ConfirmDeleteModal";

export const AddProduct = ({
  field,
  register,
  remove,
  index,
  disabledFields,
  errors,
  clearErrors,
  isDisabled
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <Card
      key={field.id}
      className="product-card"
    >
      <Card.Body>
        <GoodsModal
          register={register}
          index={index}
          field={field}
          disabled={disabledFields.includes("goods") || isDisabled}
          errors={errors}
          clearErrors={clearErrors}
        >
        </GoodsModal>
        <Button
          variant="danger"
          // onClick={() => remove(index)}
          onClick={() => setShowDeleteModal(true)}
          style={{ margin: 10 }}
          disabled={disabledFields.includes("goods") || isDisabled}
        >
          Poista tuote
        </Button>
        <ConfirmDeleteModal
          isOpen={showDeleteModal}
          deleteAction={() => remove(index)}
          close={() => setShowDeleteModal(false)}
          targetItem={
            field.name
          }
        />
      </Card.Body>
    </Card>
  );
};