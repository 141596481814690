import { useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { useDeliveryService } from "../../hooks/services/useDeliveryService";
import { useOnError } from "../../hooks/useOnError";
import { toast } from "react-toastify";
import {defaultModalStyles} from "../../styles/defaultModalStyles";

export const ChangeCourierModal = ({
  isOpen,
  close,
  organizationCouriers,
  currentCourier,
  delivery,
  refreshAllDeliveries
}) => {

  const deliveryService = useDeliveryService();

  const onError = useOnError();

  const {
    handleSubmit,
    register,
    setValue
  } = useForm({});

  useEffect(() => {
    currentCourier && setValue("courierId", currentCourier._id);
  }, [currentCourier]);

  const changeCourier = async (data) => {
    try {
      await deliveryService.changeCourier(delivery._id, data.courierId);
      refreshAllDeliveries();
      toast.success("Kuljettaja vaihdettu");
      close();
    } catch (e) {
      onError(e);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={defaultModalStyles}
    >
      <Form
        onSubmit={handleSubmit((data) => changeCourier(data))}
      >
        <Row style={{ height: "60px" }}>
          <Col className="mb-5">
            <h4>Olet vaihtamassa kuljettajaa kuljetukselle</h4>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <Form.Group className="mb-4">
              <Form.Label htmlFor="courierId">Valitse kuljettaja</Form.Label>
              <Form.Select
                name="courierId"
                {...register("courierId")}
              >
                {
                  organizationCouriers.map((courier) => (
                    <option
                      key={courier._id}
                      value={courier._id}
                    >
                      {courier.firstName} {courier.lastName}
                    </option>
                  ))
                }
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 4, offset: 2 }}>
            <Button
              className="btn-primary-color"
              type="submit"
            // onClick={accept}
            >
              Vaihda kuljettaja
            </Button>
          </Col>
          <Col xs={4}>
            <Button
              className="btn-text" onClick={close}
              style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <span className="material-symbols-outlined">close</span>
              Peruuta
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};