import { useState } from "react";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { useForm } from "react-hook-form";
import OrganizationRegister from "../../../register/OrganizationRegister";
import { JoinStep } from "./JoinStep";

export const JoinOrganizationProcess = () => {
  const [isFirstStep, setIsFirstStep] = useState(true);

  const {
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: { method: "join" }
  });

  const method = watch("method");

  const backButton = () => (
    <Col xs={12} xl={5}>
      <Button
        className="btn-primary-color wizard-button w-100"
        onClick={() => setIsFirstStep(true)}
      >
        Edellinen
      </Button>
    </Col>
  );

  return (
    <Container>
      <Col style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "40px"
      }}>
        <h1 style={{ margin: "0" }}>Yrityksen liittäminen</h1>
      </Col>
      <Row style={{ justifyContent: "center" }}>
        <div
          className="col-sm-12 col-md-7 col-lg-6"
        >
          {
            isFirstStep ? (
              <FirstStep
                setValue={setValue}
                setIsFirstStep={setIsFirstStep}
              />
            ) : (
              <>
                {method === "join" ? (
                  <JoinStep
                    register={register}
                    backButton={backButton}
                  />
                ) : (
                  <OrganizationRegister
                    fullWidth={true}
                    backButton={backButton}
                  />
                )}
              </>
            )
          }
        </div>
      </Row>
    </Container>
  );
};

const FirstStep = ({
  setValue,
  setIsFirstStep
}) => {
  return (
    <Row>
      Valitse haluatko liittyä olemassa olevaan yritykseen vai luoda uuden
      <Col
        className="mt-4"
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <Stack
          style={{ flex: "none" }}
          gap={4}
        >
          <Button
            type="submit"
            className="btn-primary-color"
            onClick={() => {
              setValue("method", "join");
              setIsFirstStep(false);
            }}
          >
            Liity olemassa olevaan
          </Button>
          <Button
            type="submit"
            className="btn-primary-color"
            onClick={() => {
              setValue("method", "create");
              setIsFirstStep(false);
            }}
          >
            Luo uusi
          </Button>
        </Stack>
      </Col>
    </Row>
  );
};