import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

export function NavLinksConsumer({ mobile }) {
  return (
    <>
      <Nav.Link
        className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
        as={Link}
        to="consumer/deliveries"
        href="consumerList"
      >
        {!mobile && <span className="material-symbols-outlined sidebar-item-icon">list</span>}
        <span className={mobile ? "" : "sidebar-item-text"}>Omat ilmoitukset</span>
      </Nav.Link>
      <Nav.Link
        className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
        as={Link}
        to="consumer/delivered"
        href="delivered"
      >
        {!mobile && <span className="material-symbols-outlined sidebar-item-icon">inventory</span>}
        <span className={mobile ? "" : "sidebar-item-text"}>Toimitetut</span>
      </Nav.Link>
      <Nav.Link
        className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
        as={Link} to="consumer/create"
        href="consumerCreate"
      >
        {!mobile && <span className="material-symbols-outlined sidebar-item-icon">add</span>}
        <span className={mobile ? "" : "sidebar-item-text"}>Luo kuljetus</span>
      </Nav.Link>
      <Nav.Link
        className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
        as={Link}
        to="consumer/profile"
        href="consumerProfile"
      >
        {!mobile && <span className="material-symbols-outlined sidebar-item-icon">person</span>}
        <span className={mobile ? "" : "sidebar-item-text"}>Omat tiedot</span>
      </Nav.Link>
      <a
        className={`sidebar-item ${mobile ? "mobile-nav-item" : ""}`}
        href="https://finlandcargo.com/yhteystiedot/"
        target="_blank"
        rel="noreferrer"
        style={{textDecoration: "none"}}
      >
        {!mobile &&
          <span className="material-symbols-outlined sidebar-item-icon">support_agent</span>}
        Ota yhteyttä
      </a>
    </>
  );
}