import {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {useAdminService} from "../../../hooks/services/useAdminService";
import {SearchInput} from "../../common/SearchInput";
import {DeliveryModal} from "./DeliveryModal";
import {DeliveryItem} from "./DeliveryItem";
import {useLoadingContext} from "../../../contexts/LoadingContext";
import {useOnError} from "../../../hooks/useOnError";
import {Paginator} from "../../Paginator";

export const Deliveries = () => {
  const adminService = useAdminService();

  const [deliveries, setDeliveries] = useState(null);
  const [activeDelivery, setActiveDelivery] = useState(null);

  const [page, setPage] = useState(1);

  const onError = useOnError();

  const {addLoading, removeLoading} = useLoadingContext();

  const handleSearch = async (evt, value, extra = {}) => {
    evt.preventDefault();
    try {
      addLoading("adminSearchDelivery");
      const resp = await adminService.searchDelivery({
        orderId: value?.trim()?.length > 0 ? value : void 0,
        ...extra
      });
      setPage(1);
      removeLoading("adminSearchDelivery");
      setDeliveries(resp);
    } catch (e) {
      removeLoading("adminSearchDelivery");
      console.log(e);
      onError(e);
    }
  };

  const close = () => {
    setActiveDelivery(null);
  };

  useEffect(() => {
    window.setTimeout(() => {
      document.getElementById("admin-search-submit").click();
    }, 500);
  }, []);

  return (<Container>
    <SearchInput
      placeholder="Hae kuljetusta tunnisteella"
      handleSearch={handleSearch}
      upperCase={true}
      style={{marginBottom: "15px"}}
    />

    {
      deliveries?
        (
          deliveries.length > 0?
            <>
              <Paginator
                pageCount={Math.max(1, Math.ceil(deliveries?.length / 10))}
                currentPage={page}
                setCurrentPage={setPage}
              />
              {
                deliveries.slice((page -1) * 10, (page) * 10).map((delivery) => (<DeliveryItem
                  key={delivery._id}
                  delivery={delivery}
                  setActiveDelivery={setActiveDelivery}
                />))
              }
              <Paginator
                pageCount={Math.max(1, Math.ceil(deliveries?.length / 10))}
                currentPage={page}
                setCurrentPage={setPage}
              />
            </>
            : <p>Ei hakutuloksia</p>
        )
        : null
    }



    <DeliveryModal
      delivery={activeDelivery ? activeDelivery : null}
      {...{
        close, // refresh
      }}
    />
  </Container>);
};