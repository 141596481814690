import { Col, Row } from "react-bootstrap";
import { ReactComponent as Weight } from "../../util/icons/weight.svg";
import { ReactComponent as Dimensions } from "../../util/icons/dimensions.svg";
import { formatAddress, formatDate, formatDateRange, formatPhone } from "../../util/format";
import { DeliveryStatus } from "../../util/constants.js";
import Map from "../map/Map";
import {useCallback, useRef, useState} from "react";
import {useUpdateEffect} from "../../hooks/useUpdateEffect";
import {globalState} from "../../util/globalState";
import {fitPoints} from "../../util/maps";
import {AmountWithTax, CurrencyWithTax} from "../common/PriceComponents";
import { Image } from "../common/Image";

const directionCacheStore = globalState({});

export const Delivery = (props) => {

  const { delivery } = props;
  const mapRef = useRef(null);

  const [center, setCenter] = useState({ latitude: 64.5, longitude: 24 });
  const [zoom, setZoom] = useState(4);

  const mapIdleCallback = useCallback(map => {
    const center = map.getCenter();

    setCenter({ latitude: center.lat(), longitude: center.lng() });
    const newZoom = map.getZoom();
    if (newZoom) {
      setZoom(newZoom);
    }
  }, []);

  const mapLoadCallback = useCallback(() => {
    window.setTimeout(() => {
      window.google.maps.event.trigger(mapRef.current, "resize");
    }, 2500);
  }, []);

  const [directions, setDirections] = useState(null);
  const [directionCache, setDirectionCache] = directionCacheStore.use();

  const directionsService = useRef(new window.google.maps.DirectionsService()).current;

  useUpdateEffect(([delivery, directionCache]) => () => {
    if (!delivery || !directionCache) {
      return;
    }

    let cancel = false;

    const originCoords = getDeliveryCoordinates("origin", delivery);
    const destinationCoords = getDeliveryCoordinates("destination", delivery);
    (async () => {
      try {
        const origin = `${originCoords.latitude},${originCoords.longitude}`;
        const destination = `${destinationCoords.latitude},${destinationCoords.longitude}`;

        const cacheKey = `${origin};${destination}`;

        let directions;
        if (directionCache[cacheKey]) {
          directions = directionCache[cacheKey];
        } else {
          directions = await directionsService.route({
            origin: delivery.origin.street + " " + delivery.origin.postOffice,
            destination: delivery.destination.street + " " + delivery.destination.postOffice,
            travelMode: window.google.maps.TravelMode.DRIVING,
          });

          setDirectionCache(old => ({
            ...old,
            [cacheKey]: directions
          }));
        }

        if (!cancel) {
          setDirections(directions);
        }
      } catch (e) {
        // No onError, silent
        console.log(e);
      } finally {
        if (!cancel) {
          fitPoints(mapRef.current, [originCoords, destinationCoords]);
        }
      }
    })();

    return () => {
      cancel = true;
    };
  }, [delivery, directionCache], [delivery]);

  if (!delivery) {
    return null;
  }

  return (
    <div>
      <Row>
        <Col lg={6}>
          <Map
            style={{
              width: "100%",
              minWidth: "100px",
              minHeight: "100px",
              aspectRatio: 16 / 9,
              borderRadius: 5
            }}
            mapTypeControl={false}
            scaleControl={false}
            zoomControl={true}
            streetViewControl={false}
            disableDefaultUI={false}
            draggable={true}
            scrollwheel={true}
            panControl={false}
            directions={directions}
            center={{
              lat: center.latitude,
              lng: center.longitude
            }}
            zoom={zoom}
            onRefChange={map => {
              mapRef.current = map;
              mapLoadCallback();
            }}
            onIdle={mapIdleCallback}
            markers={[]}
            onMarkerChange={_ => { }}
          />
        </Col>
        <Col lg={6}>
          <Row>
            {[
              "Kuljetuksen tunnus:", delivery.orderId,
              ...(
                delivery.privateCustomer ?
                  [<em>Yksityinen asiakas</em>, ""] : []
              ),
              ...(
                delivery.accepted?.date ?
                  [
                    "Sovittu noutopäivä:", <em>{formatDate(delivery.accepted.date)}</em>,
                    "Viimeinen toimituspäivä:", <em>{formatDate(delivery.dateRange.to)}</em>
                  ] :
                  [
                    "Toimituksen aikaväli:", <em>{formatDateRange(delivery.dateRange)}</em>
                  ]
              ),
              "Kuljetuksen hinta:",
              delivery.price ? <em>
                <AmountWithTax amt={delivery.price}/>
                <CurrencyWithTax/>               
              </em> : <em>ei määritetty</em>,
              "Noutopaikan osoite:", <em>{formatAddress(delivery.origin, delivery.status)}</em>,
              "Toimituspaikan osoite:",
              <em>{formatAddress(delivery.destination, delivery.status)}</em>,
              ...(
                delivery.customer?.name ?
                  [
                    ...(
                      delivery.customer.customerType === "company" ?
                        [
                          "Tilaava yritys:", delivery.customer.businessName,
                          "Yrityksen Y-tunnus:", delivery.customer.businessId,
                        ] :
                        []
                    ),
                    "Tilaaja:", delivery.customer.name,
                    "Tilaajan numero:", formatPhone(delivery.customer.phoneNumber),
                    "Tilaajan sähköposti:", delivery.customer.email,
                    "Lähettäjän numero:", formatPhone(delivery.senderPhonenumber),
                    "Vastaanottajan numero:", formatPhone(delivery.recipientPhonenumber),

                  
                  ] :
                  []
              ),
              "Purkutapa:", <em>{delivery.unloading}</em>,
              <span>
                <Weight style={{ width: "16px" }} /> Paino:
              </span>, <em>{parseFloat(delivery.weight.toFixed(2))}kg</em>,
              "Tilavuus:", <em>{parseFloat(delivery.volume.toFixed(2))}m3</em>,
              "Kollimäärä:", <em>
                {
                  delivery.goods.reduce(
                    (accumulator, object) => accumulator + object.amount, 0)
                }
              </em>,
              <span><Dimensions style={{ width: "16px" }} /> Kollit ja mitat:</span>,
              <em>
                {delivery.goods.map((g, i) =>
                  (
                    <div key={i}>
                      {g.amount} x {g.name}: {g.dimensions.x}m
                      x {g.dimensions.y}m x {g.dimensions.z}m
                      <span style={{ color: "red", fontWeight: "bold" }}>
                        {g.fragile ? " SÄRKYVÄÄ" : ""}
                      </span>
                    </div>
                  )
                )}
              </em>,
              "Rahtikirjan numero:", <em>{delivery.waybillNo}</em>,
              "Lisätiedot:", <em>{delivery.info}</em>,
              ...(
                delivery.status === DeliveryStatus.DELIVERED ?
                  [
                    "Kuva:",
                    <Image
                      src={`/api/images/${delivery._id}`}
                      alt="proof of delivery"
                      style={{width: "100%"}}
                    />
                  ] :
                  []
              )
            ].map((elem, i) =>
              <Col
                key={i}
                xs={12}
                sm={i % 2 === 0 ? 4 : 8}
                className="mb-3"
                style={i % 2 === 0 ? {fontWeight: "bold"} : {}}
              >{elem}</Col>)
            }
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const getDeliveryCoordinates = (type, delivery) => {
  const [longitude, latitude] = delivery[type].location.coordinates;

  console.log(latitude, longitude);

  return { latitude, longitude };
};
