import { useState, useEffect, useCallback } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import { useOrganizationService } from "../../../hooks/services/useOrganizationService";
import "./organization.css";
import { OrganizationInfo } from "./OrganizationInfo";
import { Members } from "./Members";
import { useNavigate, useParams } from "react-router-dom";
import { useLoadingContext } from "../../../contexts/LoadingContext";
import { useOnError } from "../../../hooks/useOnError";
import { OrganizationDeliveries } from "./Deliveries/OrganizationDeliveries";

export default function CourierOrganization(props) {
  const [key, setKey] = useState("");
  const [organization, setOrganization] = useState();

  const { tabKey } = useParams();
  const navigate = useNavigate();

  const onError = useOnError();

  const organizationService = useOrganizationService();
  const { addLoading, removeLoading } = useLoadingContext();

  const refreshOrganization = useCallback(async () => {
    try {
      addLoading("organization");
      setOrganization(await organizationService.get());
      removeLoading("organization");
    } catch (e) {
      removeLoading("organization");
      onError(e);
    }
  }, [onError]);

  useEffect(() => {
    setKey(tabKey);
  }, [tabKey]);

  useEffect(() => {
    refreshOrganization().then();
  }, [refreshOrganization]);

  return (
    <Container>
      <Tabs
        activeKey={key}
        onSelect={(k) => navigate(`/courier/organization/${k}`)}
        justify
      >
        <Tab eventKey="information" title="Tiedot">
          <OrganizationInfo
            organization={organization}
          />
        </Tab>
        <Tab eventKey="members" title="Jäsenet">
          <Members
            members={organization?.couriers}
            refreshOrganization={refreshOrganization}
          />
        </Tab>
        <Tab eventKey="deliveries" title="Kuljetukset">
          <OrganizationDeliveries />
        </Tab>
      </Tabs>
    </Container>
  );
}