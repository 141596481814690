import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUserContext } from "../../../../contexts/UserContext";
import { useCourierService } from "../../../../hooks/services/useCourierService";
import { useOrganizationService } from "../../../../hooks/services/useOrganizationService";
import { useOnError } from "../../../../hooks/useOnError";
import GradientDiv from "../../../Gradient";

export const OrganizationInvitation = ({
  invite
}) => {
  const organizationService = useOrganizationService();
  const navigate = useNavigate();
  const onError = useOnError();
  const { setUser } = useUserContext();
  const courierService = useCourierService();

  const handleAccept = async () => {
    try {
      await organizationService.handleInvitation({
        organizationId: invite._id,
        method: "accept"
      });
      setUser(await courierService.profile());

      toast.success("Kutsu hyväksytty");
      navigate("/courier/deliveries");
    } catch (e) {
      onError(e);
    }
  };

  const handleReject = async () => {
    try {
      await organizationService.handleInvitation({
        organizationId: invite._id,
        method: "reject"
      });
      setUser(await courierService.profile());

      toast.success("Kutsu hylätty");
    } catch (e) {
      onError(e);
    }
  };

  return (
    <GradientDiv>
      <Row>
        <Col
          xs={7}
          md={6}
          lg={6}
          style={{
            display: "flex"
          }}
        >
          <p
            style={{
              margin: 0,
              alignSelf: "center"
            }}
          >{invite.name}</p>
        </Col>

        <Col
          xs={5}
          md={6}
          lg={6}
          style={{
            display: "flex",
            justifyContent: "space-between"
          }}
        >
          <Button
            variant="success"
            className="btn-base-style"
            onClick={handleAccept}
          >
            <span className="material-symbols-outlined icon-button">
              done
            </span>
          </Button>
          <Button
            variant="danger"
            className="btn-base-style"
            onClick={handleReject}
          >
            <span className="material-symbols-outlined icon-button">
              close
            </span>
          </Button>
        </Col>
      </Row>
    </GradientDiv>
  );
};