import { Button } from "react-bootstrap";
import { useCountdown } from "../../hooks/services/useCountDown";

export const ResendButton = ({
  resendAction,
  countDownTime = 0
}) => {
  const countDown = useCountdown(countDownTime);

  return (
    <Button
      className="btn-outline"
      variant="outline-primary"
      disabled={countDown.timer ? true : false}
      onClick={() => {
        resendAction();
        countDown.resetInterval(60);
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <span className="material-symbols-outlined">mail</span>
      {`Lähetä uudelleen ${countDown.timer ?
        `(${countDown.timer})` : ""}`}
    </Button>
  );
};