import { useState } from "react";
import { Button, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useDeliveryService } from "../../../hooks/services/useDeliveryService";
import { useOnError } from "../../../hooks/useOnError";
import { formatDate, formatPriceToEuros } from "../../../util/format";
import { ConfirmDeleteModal } from "../../common/ConfirmDeleteModal";
import GradientDiv from "../../Gradient";

export const OfferItem = ({ deliveryId, offer, refreshAllDeliveries }) => {
  const deliveryService = useDeliveryService();
  const [showDelete, setShowDelete] = useState(false);

  const onError = useOnError();

  const remove = async () => {
    try {
      await deliveryService.deleteCounterOffer(deliveryId, offer._id);
    } catch (e) {
      onError(e);
      return;
    }
    toast.success("Tarjous poistettu onnistuneesti");
    await refreshAllDeliveries();
  };

  return (
    <Col
      xs={12}
      sm={6}
      lg={4}
    >
      <GradientDiv>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p
              className="fw-bold"
            >Kuljetuspäivä:</p>
            <p>{formatDate(offer.date)}</p>
          </div>
          <div>
            <p
              className="fw-bold"
            >Hinta:</p>
            <p>{formatPriceToEuros(offer.amount)}€</p>
          </div>
          <Button
            variant="outline-danger delete-icon-button"
            onClick={() => setShowDelete(true)}
          >
            <span
              className="material-symbols-outlined icon-button"
            >delete</span>
          </Button>
        </div>
        <ConfirmDeleteModal
          isOpen={showDelete}
          deleteAction={remove}
          close={() => setShowDelete(false)}
          targetItem={
            `${formatDate(offer.date)} - ${offer.amount}€`
          }
        />
      </GradientDiv>
    </Col>
  );
};