import {useEffect} from "react";
import { useUserContext } from "../../contexts/UserContext";
import useToken from "../../hooks/useToken";

export default function LogoutView() {
  const [,setToken] = useToken();
  const { setUser } = useUserContext();

  useEffect(() => {
    setToken(null);
    setUser(null);
  }, [setToken]);

  return null;
}