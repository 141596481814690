import "./Login.css";
import {
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { SubmitButton } from "./SubmitButton";
import { useOnError } from "../hooks/useOnError";
import { toast } from "react-toastify";
import useToken from "../hooks/useToken";
import { useCourierService } from "../hooks/services/useCourierService";
import useAppCheck from "../hooks/useAppCheck";
import { useCustomerService } from "../hooks/services/useCustomerService";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import { FormErrorMessage } from "./form/FormErrorMessage";
import { SocialMediaLogin } from "./SocialMediaLogin";
import { useLoadingContext } from "../contexts/LoadingContext";

export default function Login() {
  const {
    handleSubmit,
    formState,
    register,
    setValue,
    watch,
    control,
    clearErrors
  }
    = useForm({ defaultValues: { userType: "" } });
  const customerService = useCustomerService();
  const courierService = useCourierService();
  const onError = useOnError();
  const [, setToken] = useToken();
  const navigate = useNavigate();

  const [ isApp ] = useAppCheck();

  useEffect(() => {
    if(isApp) {
      setValue("userType", "courier");
    }
  }, [isApp]);

  const { addLoading, removeLoading } = useLoadingContext();

  const userType = watch("userType");

  const attemptLogin = useCallback(async data => {
    addLoading("login");
    if (userType === "consumer") {
      try {
        setToken(await customerService.login(data));

        toast.success("Kirjautuminen onnistui");
        navigate("/consumer/deliveries");
      } catch (e) {
        removeLoading("login");
        onError(e);
      }
    } else if (userType === "courier") {
      try {
        setToken(await courierService.login(data));

        toast.success("Kirjautuminen onnistunut");
        navigate("/courier/deliveries");
      } catch (e) {
        removeLoading("login");
        onError(e);
      }
    }
    removeLoading("login");
  }, [courierService, customerService, navigate,
    onError, setToken, userType]);

  return (
    <Container>
      <Col style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "40px"
      }}>
        <h1 style={{ margin: "0" }}>Kirjaudu sisään</h1>
      </Col>
      <Row style={{ justifyContent: "center" }}>
        <Form
          onSubmit={handleSubmit(attemptLogin)}
          className="col-sm-12 col-md-7 col-lg-4"
        >
          {!isApp ?
            <Form.Group className="mb-3">
              Valitse käyttäjän tyyppi
              <Form.Group controlId="userType">
                <button
                  onClick={() => setValue("userType", "consumer")}
                  type="button"
                  className={
                    `btn-primary-color userType ${userType === "consumer" ? "active" : ""}`
                  }
                  style={{
                    display: "flex", 
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <span className="material-symbols-outlined icon">person</span>
                  Tilaaja
                </button>
                <button
                  onClick={() => setValue("userType", "courier")}
                  type="button"
                  className={`btn-primary-color userType ${userType === "courier" ? "active" : ""}`}
                  style={{
                    display: "flex", 
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <span className="material-symbols-outlined icon">local_shipping</span>
                  Kuljettaja
                </button>
              </Form.Group>
            </Form.Group>
            : null
          }
          {
            userType === "consumer" ? (
              <EmailInput
                register={register}
              />
            )
              : userType === "courier" ? (
                <CourierPhoneInput
                  control={control}
                  formState={formState}
                  clearErrors={clearErrors}
                  handleSubmit={handleSubmit(attemptLogin)}
                />
              ) : null
          }
          { userType !== "" ?
            <>
              <Form.Group className="mb-3">
                <Form.Label htmlFor="password">Salasana</Form.Label>
                <InputGroup className="mb-4" md="2">
                  <FormControl
                    id="password"
                    type="password"
                    {...register("password")}
                    size="lg"
                  />
                </InputGroup>
              </Form.Group>
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <SubmitButton
                  formState={formState}
                  submitValue={"Kirjaudu sisään"}
                  icon={<span className="material-symbols-outlined">login</span>}
                />
              </Col>
              <Nav.Link
                as={Link}
                to="../forgot-password"
                href="forgot-password"
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: 16
                  }}
                >
                  Unohdin salasanani
                </div>
              </Nav.Link>
            </> :
            null
          }
          {
            isApp?
              <Nav.Link
                as={Link}
                to="../register"
                href="register"
              >
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: 16
                  }}
                >
                  Ei käyttäjää? Rekisteröidy tästä
                </div>
              </Nav.Link>
              :
              null
          }
        </Form>
        {
          userType === "consumer" && (
            <SocialMediaLogin />
          )
        }
      </Row>
    </Container>
  );
}

const EmailInput = ({ register }) => {
  return (
    <Form.Group className="mb-3">
      <Form.Label htmlFor="email">Sähköposti</Form.Label>
      <InputGroup className="mb-4" md="2">
        <FormControl
          id="email"
          type="text"
          {...register("email")}
          size="lg"
        />
      </InputGroup>
    </Form.Group>
  );
};

const CourierPhoneInput = ({
  control,
  formState,
  clearErrors,
  handleSubmit
}) => {
  return (
    <Form.Group className="mb-4" md="2">
      <Form.Label>Puhelinnumero</Form.Label>
      <Controller
        name="phoneNumber"
        control={control}
        rules={{
          required: "Vaadittu kenttä"
        }}
        render={(props) => {
          return (
            <PhoneInput
              value={props.field.value}
              onChange={phone => {
                clearErrors("phoneNumber");
                props.field.onChange(phone);
              }}
              containerStyle={{
                width: "100%"
              }}
              inputStyle={{
                width: "100%"
              }}
              autoFormat={true}
              onKeyDown={(evt) => evt.key === "Enter" && handleSubmit()}
              specialLabel="Puhelinnumero"
              preferredCountries={["fi"]}
              country={"fi"}
              masks={{ fi: ".. ......." }}
              isValid={() => !formState.errors.phoneNumber}
            />
          );
        }}
      />
      <FormErrorMessage
        error={formState.errors.phoneNumber}
        errorMessage={formState.errors.phoneNumber?.message}
      />
    </Form.Group>
  );
};