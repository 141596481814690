import { useState, useEffect, useContext, createContext } from "react";
import { useCustomerService } from "../hooks/services/useCustomerService";
import { useCourierService } from "../hooks/services/useCourierService";
import useToken from "../hooks/useToken";
import { getJwtRole } from "../util/jwt";
import { useAdminService } from "../hooks/services/useAdminService";
import { useLoadingContext } from "./LoadingContext";

const UserContext = createContext();

function UserProvider(props) {

  const { children } = props;

  const [user, setUser] = useState(null);
  const [token] = useToken();
  const customerService = useCustomerService();
  const courierService = useCourierService();
  const adminService = useAdminService();
  const { isLoading, addLoading, removeLoading } = useLoadingContext();

  useEffect(() => {
    const updateUser = async () => {
      if (!user) {
        const userType = getJwtRole(token);
        if (userType && !isLoading.includes("profile")) {
          addLoading("profile");
        }

        try {

          if (userType === "courier") {
            setUser(await courierService.profile());
          } else if (userType === "customer") {
            setUser(await customerService.get());
          } else if (userType === "admin") {
            setUser(await adminService.profile());
          }
          else {
            //TODO: this needs to be done better
            try{
              const customer = await customerService.get();
              if (customer) {
                setUser(customer);
              }
            } catch (e) {

            }

            try {
              const courier = await courierService.profile();
              if (courier) {
                setUser(courier);
              }
            } catch (e) {

            }
          }
          removeLoading("profile");
        } catch (e) {
          removeLoading("profile");
        }
      }
    };

    token && updateUser();
  }, [token, courierService, customerService, user]);

  //Remove verify warnings over time
  useEffect(() => {
    const interval = setInterval(async () => {
      if(user) {
        const userType = getJwtRole(token);
        if(
          (user.emailVerification && !user.emailVerification.completed) ||
          (user.smsVerification && !user.smsVerification.completed)
        ) {
          if (userType === "courier") {
            setUser(await courierService.profile());
          } else if (userType === "customer") {
            setUser(await customerService.get());
          }
        }
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [user]);

  const getOrganizationRole = () => {
    const foundSelf = user?.organization.couriers.find((memberData) =>
      memberData.courier === user._id
    );

    return foundSelf?.role;
  };

  return (
    <UserContext.Provider value={{
      user,
      setUser,
      getOrganizationRole
    }}>
      {children}
    </UserContext.Provider>
  );
}

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined)
    throw new Error("AuthContext must be used within it's provider");
  return context;
};


export default UserProvider;