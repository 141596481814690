import { useState } from "react";
import { useDeliveryService } from "../../../hooks/services/useDeliveryService";
import { useOnError } from "../../../hooks/useOnError";
import { useEffect } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useLoadingContext } from "../../../contexts/LoadingContext";
import { DeliveryModal } from "../DeliveryModal";
import { toast } from "react-toastify";


export default function Questions(props) {
  const [deliveries, setDeliveries] = useState([]);
  const deliveryService = useDeliveryService();
  const [activeDelivery, setActiveDelivery] = useState(null);

  

  const close = () => setActiveDelivery(null);

  const { addLoading, removeLoading } = useLoadingContext();

  console.log(deliveries);
  const questions = deliveries.map(delivery => delivery.questions).flat();
  const onError = useOnError();

  useEffect(() => {
    addLoading("questions");
    deliveryService.getQuestions() 
      .then(result => {
        setDeliveries(result.data);
        removeLoading("questions");
      })
      .catch((e) => {
        onError(e);
      });
  }, []);
  
  
  

  return (
    <div className="container" style={{
      paddingLeft: "15rem !important",
    }}>
      <h3>Kysymykset</h3>
      <Col>
        {
          questions.length > 0
            ? questions.sort((a, b) => new Date(b.date) - new Date(a.date))
              .map((question) => 
                <Card key={
                  question._id
                } style={{
                  padding: "1rem",
                  marginTop: "1rem",
                  marginBottom: "1rem",
                }}>
                  <Card.Title>Kysymys</Card.Title>
                  <Card.Text>{question.question}</Card.Text>
                  <Card.Title>Vastaus</Card.Title>
                  <Card.Text>{question.answer || "Ei vastausta"}</Card.Text>
                  <Card.Text>
                    <small className="text-muted">Lähetetty: {
                      new Date(question.date).toLocaleString()
                    }</small>
                  </Card.Text>
                  <Row>
                    <div>
                      <Button className="btn-primary-color" onClick={() => {
                        setActiveDelivery(
                          deliveries.find(delivery => delivery.questions.find(
                            q => q._id === question._id
                          )
                          ));
                     
                      }}>Näytä kuljetus</Button>
                      <Button className="btn-danger"
                        onClick={() => {
                          // get delivery id
                          const deliveryId = deliveries.find(delivery => delivery.questions.find(
                            q => q._id === question._id
                          ));

                          deliveryService.deleteQuestion(deliveryId._id,
                            question._id)
                            .then(() => {
                              setDeliveries(deliveries.filter(delivery => delivery.questions.find(
                                q => q._id !== question._id
                              )));
                              toast.success("Kysymys poistettu onnistuneesti");
                            })
                            .catch((e) => {
                              onError(e);
                            });
                        }}
                        style={{
                          backgroundColor: "red",
                          borderColor: "red",
                          marginLeft: "1rem",
      
                        }}>Poista</Button>
                    </div>
                  </Row>
            
                </Card>
              ) : <p>Ei kysymyksiä</p>
        }
      </Col>
      
      <DeliveryModal 
        delivery={activeDelivery ? activeDelivery : null}
        courierDelivery={null}
        requestId={null}
        close={close}
        refreshAllDeliveries={() => {}}
        editMode={false}
      />

    </div>
  );
    
}


