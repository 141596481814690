import {Button, Container, Form} from "react-bootstrap";
import {useState} from "react";
import {useOnError} from "../../hooks/useOnError";
import {useCourierService} from "../../hooks/services/useCourierService";
import useToken from "../../hooks/useToken";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

export default function DeleteAccount() {
  const onError = useOnError();
  const courierService = useCourierService();
  const [password, setPassword] = useState("");
  const [,setToken] = useToken();
  const navigate = useNavigate();

  return <Container>
    <h3>Poista tili</h3>
    <p>
      <br/>
      Tilin poisto on pysyvä, eikä sitä voi peruuttaa edes ylläpitäjän toimesta.<br/>
      <br/>
      Toiminnon suorittamiseksi on syötettävä tilin nykyinen salasana.
    </p>
    <Form>
      <input
        autoComplete="false" name="hidden" type="text"
        style={{display: "none"}}/>
      <Form.Label>Nykyinen salasana</Form.Label>
      <Form.Control
        type="password" placeholder="Salasana"
        aria-autocomplete="none"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <br/>
      <Button
        type="button" variant="danger"
        onClick={async () => {
          try {
            await courierService.deleteAccount(password);

            setToken(null);
            toast.success("Tili poistettu onnistuneesti");
            navigate("/");
          } catch (e) {
            onError(e);
          }
        }}
      >
        Poista tili
      </Button>
    </Form>
  </Container>;
}