import { Button, Form, Row, Col } from "react-bootstrap";
import Modal from "react-modal";
import { useAdminService } from "../../hooks/services/useAdminService";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { OrganizationFields } from "../register/OrganizationFields";
import { ConsumerBusinessFields } from "../register/ConsumerBusinessFields";
import { SubmitButton } from "../SubmitButton";
import { useOnError } from "../../hooks/useOnError";
import { toast } from "react-toastify";
import {defaultModalStyles} from "../../styles/defaultModalStyles";

const modalStyles = {
  content: {
    ...defaultModalStyles.content,
    width: "80%",
  }
};

export const BusinessModal = ({
  business,
  type,
  close,
  refresh
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const adminService = useAdminService();
  const onError = useOnError();

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState,
    control,
    clearErrors
  } = useForm({
    reValidateMode: "onSubmit"
  });

  useEffect(() => {
    if (business) {
      Object.entries(business).map(([key, value]) => setValue(key, value));
    }
  }, [business]);

  const modifyOrganization = async (data) => {
    try {
      await adminService.modifyOrganization(data);
      setIsEditing(false);
      refresh();
      toast.success("Muutokset tallennettu");
    } catch (e) {
      onError(e);
    }
  };


  const changeAccountStatus = async (accountStatus) => {
    try {
      await adminService.changeBusinessStatus(business._id, type, accountStatus);
      refresh();
      close();
      toast.success("Yrityksen tila muutettu");
    } catch (e) {
      onError(e);
    }
  };

  const handleClose = () => {
    setIsEditing(false);
    close();
  };

  return (
    <Modal
      isOpen={business !== null}
      onRequestClose={handleClose}
      style={modalStyles}
    >
      {
        business ?
          <>
            <Row>
              <Col className="mb-5">
                <h3>Yritys</h3>
              </Col>
            </Row>
            <Row>
              <Col className="mb-5">
                <Row style={{ justifyContent: "center" }}>
                  <Form
                    noValidate
                    onSubmit={handleSubmit(data => modifyOrganization(data))}
                    className="col-sm-8 col-md-6"
                  >
                    {type === "courier" ?
                      <OrganizationFields
                        control={control}
                        register={register}
                        errors={formState.errors}
                        clearErrors={clearErrors}
                        isReadOnly={!isEditing}
                        setValue={setValue}
                        getValues={getValues}
                      /> :
                      <ConsumerBusinessFields
                        register={register}
                        formState={formState}
                        clearErrors={clearErrors}
                        setValue={setValue}
                        getValues={getValues}
                        isReadOnly={true}
                        control={control}
                        admin={true}
                      />
                    }
                    {isEditing ? (
                      <SubmitButton
                        formState={formState}
                        submitValue="Tallenna"
                      />
                    ) : (
                      <BottomButtons
                        business={business}
                        type={type}
                        edit={() => setIsEditing(true)}
                        close={handleClose}
                        changeAccountStatus={changeAccountStatus}
                      />
                    )}
                  </Form>
                </Row>
              </Col>
            </Row>
          </> :
          null
      }
    </Modal>
  );
};

function BottomButtons(props) {
  const { business, type, edit, close, changeAccountStatus } = props;

  const accountStatus = type === "courier" ?
    business.accountStatus :
    business.billingInformation.accountStatus;

  let prevStatus;

  if (accountStatus === "rejected") {
    prevStatus = "unconfirmed";
  } else if (accountStatus === "banned") {
    prevStatus = "confirmed";
  }

  if (accountStatus === "unconfirmed") {
    return (
      <Row>
        <Col xs={4}>
          <Button
            id="edit"
            className="blue m-1 w-100"
            onClick={edit}
            disabled={type === "customer"}
          >
            Muokkaa
          </Button>
        </Col>
        <Col xs={4}>
          <Button
            className="blue m-1 w-100"
            onClick={() => changeAccountStatus("confirmed")}
          >
            Vahvista
          </Button>
        </Col>
        <Col xs={4}>
          <Button
            className="blue m-1 w-100"
            onClick={() => changeAccountStatus("rejected")}
          >
            Hylkää
          </Button>
        </Col>
        <Col xs={4}>
          <Button
            id="close"
            onClick={close}
            className="turquoise m-1 w-100"
          >
            Poistu
          </Button>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row>
        <Col xs={4}>
          <Button
            id="edit"
            className="blue m-1 w-100"
            onClick={edit}
            disabled={type === "customer"}
          >
            Muokkaa
          </Button>
        </Col>
        <Col xs={4}>
          <Button
            id="confirm"
            className="blue m-1 w-100"
            onClick={() => accountStatus === "confirmed" ?
              changeAccountStatus("banned") : changeAccountStatus(prevStatus)}
          >
            {accountStatus === "confirmed" ? "Estä" : "Palauta"}
          </Button>
        </Col>
        <Col xs={4}>
          <Button
            id="close"
            onClick={close}
            className="turquoise m-1 w-100">
            Poistu
          </Button>
        </Col>
      </Row >
    );
  }
}
