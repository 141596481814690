import {useEffect, useState, useCallback} from "react";
import {Col, Row} from "react-bootstrap";
import {DeliveryListings} from "../DeliveryListings";
import {DeliveryModal} from "../DeliveryModal";
import {DeliveryStatus} from "../../../util/constants";
import {useDeliveryService} from "../../../hooks/services/useDeliveryService";
import { useOnError } from "../../../hooks/useOnError";
import { useLoadingContext } from "../../../contexts/LoadingContext";

export default function CourierDeliveries(props) {
  const {delivered, loadTime} = props;

  const [deliveries, setDeliveries] = useState([]);
  const [activeDelivery, setActiveDelivery] = useState(null);

  const close = () => setActiveDelivery(null);

  const deliveryService = useDeliveryService();
  const { addLoading, removeLoading } = useLoadingContext();
  const onError = useOnError();

  useEffect(() => {
    let filters;
    if(delivered) {
      filters = {status: DeliveryStatus.DELIVERED};
    } else {
      filters = {status: DeliveryStatus.ACCEPTED};
    }

    addLoading("courierDeliveriesDeliveries");
    deliveryService.search({...filters, sortByUpdatedAt: true,
      ownCourier: true})
      .then(result => {
        removeLoading("courierDeliveriesDeliveries");
        setDeliveries(result);
      })
      .catch((e) => {
        removeLoading("courierDeliveriesDeliveries");
        onError(e);
      });
  }, [loadTime, delivered]);

  const refreshAllDeliveries = useCallback(async () => {
    let filters;
    if(delivered) {
      filters = {status: DeliveryStatus.DELIVERED};
    } else {
      filters = {status: DeliveryStatus.ACCEPTED};
    }

    addLoading("courierDeliveriesDeliveriesRefresh");
    deliveryService.search({...filters, sortByUpdatedAt: true,
      ownCourier: true})
      .then(result => {
        removeLoading("courierDeliveriesDeliveriesRefresh");
        setDeliveries(result);
      })
      .catch((e) => {
        removeLoading("courierDeliveriesDeliveriesRefresh");
        onError(e);
      });
  }, []);

  return <>

    <Row style={{margin: "40px"}}>
      <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <h3
          className="mb-4"
          style={{margin: "0"}}
        >{delivered ? "Toimitetut kuljetukset" : "Hyväksytyt kuljetukset"}</h3>
      </Col>
    </Row>
    <Row style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column"
    }}>
      {
        deliveries.map(delivery =>
          (delivered && delivery.status === DeliveryStatus.DELIVERED) ||
          (!delivered && delivery.status === DeliveryStatus.ACCEPTED)
            ?
            <DeliveryListings
              key={delivery.id}
              delivery={delivery}
              setActiveDelivery={setActiveDelivery}
            />
            :
            null
        )
      }
    </Row>
    <DeliveryModal
      delivery={activeDelivery}
      close={close}
      refreshAllDeliveries={refreshAllDeliveries}
    />
  </>;
}