import Modal from "react-modal";
import { Button, Alert } from "react-bootstrap";
import {defaultModalStyles} from "../../styles/defaultModalStyles";

export const ConfirmModal = ({
  isOpen,
  close,
  heading,
  confirmationText,
  confirmAction,
  confirmButtonText
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={defaultModalStyles}
    >
      <div>
        <Alert variant="light" style={{ padding: 0 }}>
          <Alert.Heading>
            {heading}
          </Alert.Heading>
          <p>{confirmationText}</p>
          <div className="d-flex justify-content-end mt-4">
            <Button
              id="close"
              variant="outline-primary"
              onClick={close}
              className="btn-outline me-3"
            >
              Peruuta
            </Button>
            <Button
              id="confirm"
              onClick={() => {
                confirmAction();
                close();
              }}
              className="btn-primary-color"
            >
              {confirmButtonText}
            </Button>
          </div>
        </Alert>
      </div>
    </Modal>
  );
};