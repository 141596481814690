import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useOrganizationService } from "../../../hooks/services/useOrganizationService";
import { useOnError } from "../../../hooks/useOnError";
import { ConfirmDeleteModal } from "../../common/ConfirmDeleteModal";
import GradientDiv from "../../Gradient";
import { InviteMembers } from "./InviteMembers";
import { MemberItem } from "./MemberItem";
import { MemberModal } from "./MemberModal";

export const Members = ({
  members,
  refreshOrganization
}) => {
  const [activeMember, setActiveMember] = useState(null);
  const [showDelete, setShowDelete] = useState(false);

  const organizationService = useOrganizationService();
  const onError = useOnError();

  const close = () => {
    setActiveMember(null);
  };

  const handleDelete = async () => {
    try {
      await organizationService.removeCourier({ inviteId: activeMember._id });
      refreshOrganization();
      close();
    } catch (e) {
      onError(e);
    }
  };

  return (
    <GradientDiv className="main">
      <InviteMembers
        refreshOrganization={refreshOrganization}
      />
      <hr />
      <Row>
        {
          members?.map((member, index) => (
            <Col
              key={index}
              xl={4}
              md={6}
              xs={12}
            >
              <MemberItem
                member={member}
                setActiveMember={setActiveMember}
              />
            </Col>
          ))
        }
      </Row>
      <MemberModal
        activeMember={activeMember}
        close={close}
        setShowDelete={setShowDelete}
        refreshOrganization={refreshOrganization}
      />
      <ConfirmDeleteModal
        isOpen={Boolean(showDelete && activeMember)}
        deleteAction={handleDelete}
        close={() => setShowDelete(false)}
        targetItem={`${activeMember?.courier?.lastName} ${activeMember?.courier?.firstName}`}
      />
    </GradientDiv>
  );
};