import { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useLoadingContext } from "../../../contexts/LoadingContext";
import { useDeliveryService } from "../../../hooks/services/useDeliveryService";
import { useOnError } from "../../../hooks/useOnError";
import GradientDiv from "../../Gradient";
import { DeliveryModal } from "../DeliveryModal";
import { DeliveryItem } from "./DeliveryItem";

export const CourierCounterOffers = ({loadTime}) => {
  const deliveryService = useDeliveryService();

  const [deliveries, setDeliveries] = useState([]);
  const [activeDelivery, setActiveDelivery] = useState(null);

  const { addLoading, removeLoading } = useLoadingContext();

  const onError = useOnError();

  const refreshAllDeliveries = useCallback(async () => {
    try {
      addLoading("searchOwnDeliveries");
      const resp = await deliveryService.search({
        ownOffers: true, open: true, sortByUpdatedAt: true });
      removeLoading("searchOwnDeliveries");
      setDeliveries(resp);
    } catch (e) {
      removeLoading("searchOwnDeliveries");
      onError(e);
    }
  }, [onError]);

  useEffect(() => {
    refreshAllDeliveries().then();
  }, [loadTime]);

  const close = () => {
    setActiveDelivery(null);
  };

  return <>
    <GradientDiv
      className="main"
    >
      <Col style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
        <h3 className="mb-4">Omat tarjoukset kuljetuksittain</h3>
      </Col>
      <Row>
        {
          deliveries.length > 0 ? (
            deliveries.map((delivery, index) => (
              <DeliveryItem
                key={index}
                delivery={delivery}
                setActiveDelivery={setActiveDelivery}
                refreshAllDeliveries={refreshAllDeliveries}
              />
            ))
          ) : <p>Ei omia tarjouksia avoimiin kuljetuksiin</p>
        }
      </Row>
      <DeliveryModal
        delivery={activeDelivery}
        close={close}
        hideButtons={true}
        refreshAllDeliveries={refreshAllDeliveries}
      />
    </GradientDiv>
  </>;
};