// noinspection CommaExpressionJS

import create from "zustand";
import {useCallback, useEffect} from "react";
import axios from "axios";

const useStore = create(set => ({
  token: localStorage["CG_TOKEN"],
  ready: false,
  setToken: newToken => {
    localStorage["CG_TOKEN"] = newToken;
    set({
      token: newToken,
      ready: true
    });
  }
}));

const useToken = (enableRefresh = false) => {
  const [token, ready] = useStore(({token, ready}) => [
    token, ready
  ]);

  const setToken = useStore(({setToken}) => setToken);

  useEffect(() => {
    if(!enableRefresh) {
      return;
    }
    const refresh = async () => {
      const oldToken = useStore.getState().token || localStorage["CG_TOKEN"];
      try {
        const token = (await axios.get(
          "/api/auth/renew", {headers: {"Authorization": `Bearer ${oldToken}`}}
        ))?.data;

        if(localStorage["CG_TOKEN"] === oldToken) {
          setToken(token);
        }
      } catch (e) {
        setToken(null);
      }
    };

    refresh().then();

    const interval = window.setInterval(refresh, 60000);
    return () => {
      window.clearInterval(interval);
    };
  }, [enableRefresh, setToken]);

  return useCallback(() => {
    return [token, setToken, ready];
  }, [token, setToken, ready])();
};

export default useToken;