import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import GradientDiv from "../../Gradient";
import { DeliveryListings } from "../DeliveryListings";
import { DeliveryModal } from "../DeliveryModal";
import { useOnError } from "../../../hooks/useOnError";
import { useCallback } from "react";
import { DeliveryStatus } from "../../../util/constants";
import AcceptCounterOfferModal from "../CounterOffers/AcceptCounterOfferModal";
import { useDeliveryService } from "../../../hooks/services/useDeliveryService";
import { useCourierService } from "../../../hooks/services/useCourierService";
import { useCourierDeliveryService } from "../../../hooks/services/useCourierDeliveryService";
import { ConfirmModal } from "../../common/ConfirmModal";
import moment from "moment";
import { toast } from "react-toastify";
import { useLoadingContext } from "../../../contexts/LoadingContext";

export default function ConsumerDeliveries({
  delivered
}) {
  const [deliveries, setDeliveries] = useState([]);
  const [activeDelivery, setActiveDelivery] = useState(null);
  const [activeOfferId, setActiveOfferId] = useState(null);
  const [activeCourierDelivery, setActiveCourierDelivery] = useState(null);
  const [ready, setReady] = useState(false);
  const [matchingCourierDeliveries, setMatchingCourierDeliveries] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const onError = useOnError();
  const deliveryService = useDeliveryService();
  const courierService = useCourierService();
  const courierDeliveryService = useCourierDeliveryService();

  const { addLoading, removeLoading } = useLoadingContext();

  const close = () => {
    setActiveDelivery(null);
    setActiveOfferId(null);
    setActiveCourierDelivery(null);
    setEditMode(false);
  };

  const refresh = useCallback(async () => {
    let results;
    try {
      addLoading("deliveries");
      results = await deliveryService.search({ own: true });
      removeLoading("deliveries");
    } catch (e) {
      removeLoading("deliveries");
      onError(e);
      return;
    }

    setDeliveries(results);
  }, [onError]);

  useEffect(() => {
    setReady(false);

    (async () => {
      await courierService.getCouriersByIds(
        [...new Set(
          deliveries
            .map(delivery => delivery?.accepted?.courier?._id)
            .filter(courier => !!courier)
        )]
      );

      setReady(true);
    })();

    return () => setReady(false);
  }, [deliveries]);

  useEffect(() => {
    refresh().then();
  }, [refresh]);

  useEffect(() => {
    const openDeliveriesIds = deliveries.filter((delivery) =>
      delivery.status === DeliveryStatus.OPEN
    ).map((delivery) => delivery.id);

    const getMatchingDeliveries = async () => {
      const resp = await courierDeliveryService.getByDeliveryIds(openDeliveriesIds);

      setMatchingCourierDeliveries(resp);
    };
    if (openDeliveriesIds.length > 0)
      getMatchingDeliveries();
  }, [deliveries]);

  if (!ready) {
    return <h3>Ladataan...</h3>;
  }

  const sendRequest = async () => {
    try {
      await courierDeliveryService.requestDelivery(activeCourierDelivery.id, activeDelivery.id);
    } catch (e) {
      onError(e);
      return;
    }
    toast.success("Kuljetuspyyntö lähetetty onnistuneesti");
    await refresh();
  };

  return (
    <Container>
      {
        (
          !delivered ? [
            {
              status: DeliveryStatus.ACCEPTED,
              title: "Hyväksytyt kuljetukset"
            },
            {
              status: DeliveryStatus.OPEN,
              title: "Omat avoimet ilmoitukset",
            }
          ] : [
            {
              status: DeliveryStatus.DELIVERED,
              title: "Toimitetut kuljetukset"
            }
          ]
        ).map(({ status, title }) => (
          <React.Fragment
            key={status}
          >
            <GradientDiv className="main" key={status}>
              <h3 className="mb-4">{title}</h3>
              <Row>
                <Col xs={12}>
                  {
                    (() => {
                      const filtered = deliveries.filter(delivery => delivery.status === status);

                      if (filtered.length === 0) {
                        return <h4 className="mb-3">Ei ilmoituksia</h4>;
                      }

                      return filtered.map(delivery =>
                        <DeliveryListings
                          key={delivery._id}
                          delivery={delivery}
                          setActiveDelivery={setActiveDelivery}
                          setActiveOfferId={setActiveOfferId}
                          setActiveCourierDelivery={setActiveCourierDelivery}
                          refresh={refresh}
                          matchingCourierDeliveries={
                            status === DeliveryStatus.OPEN ? matchingCourierDeliveries : []
                          }
                          setEditMode={setEditMode}
                        />) ?? [];
                    })()
                  }
                </Col>
              </Row>
            </GradientDiv>
          </React.Fragment>
        )) ?? []
      }
      <DeliveryModal
        delivery={(activeDelivery && editMode) ? activeDelivery : null}
        {...{ close, refresh }}
      />
      <AcceptCounterOfferModal
        delivery={(activeDelivery && activeOfferId !== null) ? activeDelivery : null}
        offerId={activeOfferId}
        {...{ close, refresh }}
      />
      <ConfirmModal
        isOpen={!!activeCourierDelivery}
        heading={"Lähetä kuljetuspyyntö"}
        confirmationText={activeCourierDelivery ?
          ` Olet lähettämässä kuljetuspyyntöä kuljetukselle
            ${activeCourierDelivery.origin} - ${activeCourierDelivery.destination}
            ${moment(activeCourierDelivery.deliveryDate).format("D.M.YYYY")}`
          : ""}
        confirmAction={sendRequest}
        confirmButtonText={"Lähetä"}
        close={close}
      />
    </Container>
  );
}