import { Stack } from "react-bootstrap";
import GradientDiv from "../../Gradient";

export const MemberItem = ({
  member,
  setActiveMember
}) => {
  const isTempUser = Object.keys(member).includes("tmpPhoneNumber");

  return (
    <GradientDiv
      onClick={() => setActiveMember(member)}
    >
      <Stack
        direction="horizontal"
        gap={2}
      >
        <span
          className="material-symbols-outlined icon-filled"
          style={{
            fontSize: "4em",
            backgroundColor: "#eee",
            fontWeight: 100,
            color: "#bbbbbb"
          }}
        >
          person
        </span>
        <Stack
          style={{
            overflow: "hidden"
          }}
        >
          <span
            style={{
              fontWeight: "bold",
              textDecoration: "underline",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis"
            }}
          >
            {isTempUser ? "Rekisteröitymätön" :
              member.courier?.lastName} {member.courier?.firstName}
          </span>
          <span className="text-capitalize">{member.role}</span>
          <span>
            {isTempUser ? `+${member.tmpPhoneNumber}` :
              `+${member.courier?.phoneNumber}`}
          </span>
        </Stack>
      </Stack>
    </GradientDiv >
  );
};