import {useNavigate, useParams} from "react-router-dom";
import {Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import Deliveries from "./Deliveries";
import CourierDeliveries from "./CourierDeliveries";
import {CourierCounterOffers} from "../CounterOffers/CourierCounterOffers";
import useAppCheck from "../../../hooks/useAppCheck";
import {useEffect, useState} from "react";

export default function CourierDeliveriesTabs() {
  const { tabKey } = useParams();
  const navigate = useNavigate();
  const [isApp] = useAppCheck();

  const [loadTime, setLoadTime] = useState(0);
  useEffect(() => {
    if(tabKey?.length > 0) {
      setLoadTime(new Date().getTime());
    }
  }, [tabKey]);

  return <Container>
    <Tabs
      activeKey={tabKey ?? "active"}
      onSelect={(key) => navigate(`/courier/deliveries/${key}`)}
      justify
    >
      <Tab eventKey="active" title={isApp? "Hyväksytyt" : "Aktiiviset"}>
        <Row style={{marginTop: !isApp? 27 : void 0}}>
          <Col>
            <Deliveries delivered={false} loadTime={loadTime}/>
          </Col>
        </Row>
        <Row>
          {
            !isApp?
              <Col>
                <CourierDeliveries/>
              </Col>
              : null
          }
        </Row>
        <Row>
          {
            !isApp?
              <Col>
                <CourierCounterOffers loadTime={loadTime} />
              </Col>
              : null
          }
        </Row>
      </Tab>
      {
        isApp ?
          [
            <Tab eventKey="offers" title={"Luodut"} key="2">
              <CourierDeliveries/>
            </Tab>,
            <Tab eventKey={"counter-offers"} title={"Tarjoukset"} key="1">
              <CourierCounterOffers loadTime={loadTime} />
            </Tab>
          ] :
          null
      }

      <Tab eventKey="delivered" title={isApp? "Arkisto" : "Toimitetut"}>
        <Deliveries delivered={true} loadTime={loadTime} />
      </Tab>
    </Tabs>
  </Container>;
}