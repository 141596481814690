import Modal from "react-modal";
import CreateDelivery from "./Create/CreateDelivery";
import {defaultModalStyles as modalStyles} from "../../styles/defaultModalStyles";
import useAppCheck from "../../hooks/useAppCheck";

export const EditDeliveryModal = (props) => {
  const {courierDelivery, close, refresh} = props;

  const [ isApp ] = useAppCheck();

  if(isApp) {
    modalStyles.content.width = "90%";
  }

  return (
    <Modal
      isOpen={courierDelivery !== null}
      onRequestClose={close}
      style={modalStyles}
    >
      {
        courierDelivery ?
          <CreateDelivery
            fullWidth={true}
            disabledFields={
              ["origin", "destination"]
            }
            {...{refresh, close, courierDelivery}}
          /> :
          null
      }
    </Modal>
  );
};
