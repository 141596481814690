import Buffer from "buffer";

const {Buffer: buffer} = Buffer;

// Get user id from jwt token
export const getJwtUser = (jwt) => {
  return getJwtPayLoad(jwt)?.user;
};

export const getJwtRole = (jwt) => {
  return getJwtPayLoad(jwt)?.role;
};

export const getJwtPayLoad = (jwt) => {
  if(typeof jwt !== "string") {
    return null;
  }

  const split = jwt.split(".", 3);
  if(split.length !== 3) {
    return null;
  }

  return JSON.parse(buffer.from(split[1], "base64").toString("utf-8"));
};
