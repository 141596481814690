import { Row, Col, Button } from "react-bootstrap";
import GradientDiv from "../Gradient";
import { formatDate, formatDateRange, formatAddress } from "../../util/format";
import { DeliveryStatus } from "../../util/constants";
import { BsArrowRight, BsPinMap } from "react-icons/bs";
import { AmountWithTax } from "../common/PriceComponents";
import { formatPhone } from "../../util/format";

export const DeliveryListings = ({
  delivery,
  setActiveDelivery,
  onClickLocate,
  highlight = false,
  refreshAllDeliveries,
  isOrganizationAdmin,
  ...rest
}) => {
  let details;
  if (delivery.status === DeliveryStatus.OPEN) {
    details = <OpenDeliveryDetails delivery={delivery} />;
  } else {
    details = (
      <AcceptedDeliveryDetails
        delivery={delivery}
        isOrganizationAdmin={isOrganizationAdmin}
      />
    );
  }

  return (
    <Col
      {...rest}
    >
      <div
        className="listing"
        style={{
          display: "grid"
        }}
        onClick={() => setActiveDelivery(delivery)}
        id={`delivery-${delivery._id}`}
      >
        <GradientDiv
          containerStyle={{
            boxShadow:
              `0px ${highlight ? "5px 5px" : "1px 1px"}
              0px rgba(0, 0, 0, ${highlight ? 0.4 : 0.22})`,
            gridArea: "1 / 1 / 2 / 2",
            overflow: "hidden"
          }}
        >
          <Row>
            <Col xs={12}>
              <h4 className="nowrap" style={{ fontWeight: "300" }}>
                {delivery.origin.postOffice} <BsArrowRight /> {delivery.destination.postOffice}
              </h4>
            </Col>

            {
              delivery.privateCustomer && (
                <Col xs={12}>
                  <em>Yksityisasiakas</em>
                </Col>
              )
            }
            <Col md={2}>
              {
                delivery.status === DeliveryStatus.OPEN && delivery.price !== 0 ?
                  <h4 className="primary-color">
                    <AmountWithTax amt={delivery.price} floor={true} />€
                  </h4> :
                  null
              }
            </Col>
          </Row>
          {details}
        </GradientDiv>

        <div
          style={{
            gridArea: "1 / 1 / 2 / 2",
            zIndex: 2,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "flex-end",
            width: "100%",
            height: "100%",
            paddingBottom: "2.5em",
            paddingRight: "1.8em"
          }}
        >
          {
            onClickLocate && (
              <Button
                variant="light"
                title="Näytä kartalla"
                onClick={e => {
                  e.stopPropagation();
                  onClickLocate(e);
                }}
              >
                <BsPinMap />
              </Button>
            )
          }
        </div>
      </div>
    </Col>
  );
};

function OpenDeliveryDetails({ delivery }) {
  const dateString = formatDateRange(delivery.dateRange);
  return (
    <>
      <Row>
        <Col style={{ marginBottom: "16px", marginTop: "16px" }}>
          <span className="medium-text">{dateString}</span>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: "16px" }}>
          <span className="light-text">Purku: {delivery.unloading}</span>
        </Col>
      </Row>
      <Row>
        <Col lg={12} style={{ marginBottom: "16px" }}>
          <span className="light-text">{parseFloat(delivery.weight.toFixed(2))}kg</span>
        </Col>
        <Col>
          <span className="light-text">{parseFloat(delivery.volume.toFixed(2))}m<sup>3</sup></span>
        </Col>
      </Row>
    </>
  );
}

function AcceptedDeliveryDetails({ delivery, isOrganizationAdmin }) {
  const dateString = formatDate(delivery.accepted.date);
  return (
    <>
      <Row>
        <Col md={12} className="light-text">
          <b>Noutopäivä: </b>{dateString}
        </Col>
      </Row>
      <Row>
        <Col md={12} className="light-text">
          <b>Lähtöpaikka: </b>{formatAddress(delivery.origin, delivery.status)}
        </Col>
      </Row>
      {
        delivery.customer && delivery.customer.name &&
          <>
            <Row className="mt-3">
              <Col md={12} className="light-text">
                <b>Lähettäjän puhelinnumero: </b>{formatPhone(delivery.senderPhonenumber)}
              </Col>
            </Row>
            <Row>
              <Col md={12} className="light-text">
                <b>Vastaanottajan puhelinnumero: </b>{formatPhone(delivery.recipientPhonenumber)}
              </Col>
            </Row>
          </> 
      }
      {
        isOrganizationAdmin && (
          <Row>
            <Col md={12} className="light-text mt-3">
              <b>Kuljettaja: </b>
              {delivery.accepted.courier?.firstName} {delivery.accepted.courier?.lastName}
            </Col>
          </Row>
        )}
    </>
  );
}