import { createContext, useContext, useState } from "react";

const VerifyContext = createContext(null);

const VerifyContextProvider = ({ children }) => {
  const [showEmailVerifyWarn, setShowEmailVerifyWarn] = useState(false);
  const [showSmsVerifyWarn, setShowSmsVerifyWarn] = useState(false);

  return <VerifyContext.Provider value={{
    showEmailVerifyWarn,
    setShowEmailVerifyWarn,
    showSmsVerifyWarn,
    setShowSmsVerifyWarn
  }}>{children}</VerifyContext.Provider>;
};

export const useVerifyContext = () => {
  const context = useContext(VerifyContext);
  if (context === undefined)
    throw new Error("AuthContext must be used within it's provider");
  return context;
};

export default VerifyContextProvider;