import {Navigate, Outlet} from "react-router-dom";
import useToken from "../../hooks/useToken";
import {getJwtRole} from "../../util/jwt";

function PrivateOutlet(props) {
  const {roles} = props;
  const [token] = useToken();

  return (token && token.length > 0) ?
    (() => {
      if (roles) {
        const role = getJwtRole(token);

        if (roles.indexOf(role) === -1) {
          return <Navigate to="/"/>;
        }
      }

      return <Outlet/>;
    })()
    :
    <Navigate to="/login"/>;
}

export default PrivateOutlet;