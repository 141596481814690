import {Button} from "react-bootstrap";

const PaginatorButton = ({page, currentPage, ...rest}) => {
  return <Button
    style={{
      width: "2.4em",
      height: "2.4em",
      backgroundColor: currentPage === page ? "#29b8f9" : "#FFF",
      color: "black",
      border: "none",
      padding: 6
    }}
    variant="primary"
    {...rest}
  >{page}</Button>;
};
export const Paginator = ({pageCount, currentPage, setCurrentPage}) => <div
  style={{
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "row",
    padding: "0.4em",
  }}
>
  {
    [
      pageCount > 0 && <PaginatorButton
        page={1}
        currentPage={currentPage}
        onClick={() => setCurrentPage(1)}
      >1</PaginatorButton>,
      pageCount > 1 && (
        currentPage > 4 ?
          <>...</>
          :
          <PaginatorButton
            page={2}
            currentPage={currentPage}
            onClick={() => setCurrentPage(2)}
          >
            2
          </PaginatorButton>
      ),
      ...[...new Array(3)].map((_, i) => {
        let rangeStart = Math.max(3, currentPage - 1);

        if (pageCount > 6) {
          rangeStart = Math.min(rangeStart, pageCount - 4);
        }

        const page = rangeStart + i;

        if (page > pageCount) {
          return null;
        }

        return <PaginatorButton
          page={page}
          currentPage={currentPage}
          onClick={() => setCurrentPage(page)}
          key={page}
        >
          {page}
        </PaginatorButton>;
      }),
      pageCount > 5 && (
        currentPage < pageCount - 3 ?
          <>...</>
          :
          <PaginatorButton
            page={pageCount - 1}
            currentPage={currentPage}
            onClick={() => setCurrentPage(pageCount - 1)}
          >
            {pageCount - 1}
          </PaginatorButton>
      ),
      pageCount > 6 && <PaginatorButton
        page={pageCount}
        currentPage={currentPage}
        onClick={() => setCurrentPage(pageCount)}
      >
        {pageCount}
      </PaginatorButton>
    ].map((el, i) => el ? <div
      key={i}
      style={{
        width: "2.4em",
        height: "2.4em",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      {el}
    </div> : null)
  }
</div>;