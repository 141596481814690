import {Route, Routes, useNavigate, useSearchParams} from "react-router-dom";

import CourierCreateDelivery
  from "./components/courier/Create/CreateDelivery.js";
import CourierSearch from "./components/courier/Search/CourierSearch.js";
import CourierProfile from "./components/courier/Profile/Profile.js";
import Questions from "./components/courier/Questions/Questions.js";
import CourierOrganization
  from "./components/courier/Organization/Organization.js";

import CreateDelivery from "./components/consumer/Create/CreateDelivery.js";
import ConsumerDeliveries
  from "./components/consumer/Deliveries/ConsumerDeliveries.js";
import ConsumerProfile from "./components/consumer/Profile/Profile.js";
import Verify from "./components/Account/Verify.js";
import ForgotPassword from "./components/Account/ForgotPassword.js";
import RecoverPassword from "./components/Account/RecoverPassword.js";
import PaymentStatus from "./components/consumer/Deliveries/PaymentStatus.js";

import NavigationBar from "./components/NavigationBar.js";
import Register from "./components/register/Register.js";
import Login from "./components/Login.js";
import Admin from "./components/admin/Admin.js";
import "./App.css";
import {ToastContainer} from "react-toastify";
import {Wrapper} from "@googlemaps/react-wrapper";
import {useEffect, useState} from "react";
import {useOnError} from "./hooks/useOnError";
import axios from "axios";
import useToken from "./hooks/useToken";
import PrivateOutlet from "./components/auth/PrivateOutlet";
import LogoutView from "./components/auth/LogoutView";
import SideBar from "./components/SideBar.js";
import {useCourierService} from "./hooks/services/useCourierService";
import {useDeliveryService} from "./hooks/services/useDeliveryService";
import useAppCheck from "./hooks/useAppCheck.js";
import {AdminLogin} from "./components/AdminLogin.js";
import {VerificationWarning} from "./components/Account/VerificationWarning.js";
import {Deliveries} from "./components/admin/Deliveries/Deliveries.js";
import {useLoadingContext} from "./contexts/LoadingContext.js";
import {MobileNavBar} from "./components/MobileNavBar.js";
import {
  JoinOrganizationProcess
} from "./components/courier/Organization/Join/JoinOrganizationProcess.js";
import {
  CheckOrganization
} from "./components/courier/Organization/CheckOrganization.js";
import PushNotifications from "./components/courier/PushNotifications";
import DeleteAccount from "./components/courier/DeleteAccount";
import CourierDeliveriesTabs
  from "./components/courier/Deliveries/CourierDeliveriesTabs";

function App() {
  const [mapsKey, setMapsKey] = useState(null);
  const [token, , ready] = useToken(true);
  const onError = useOnError();
  const navigate = useNavigate();

  useEffect(() => {
    window.navigateTo = to => navigate(to);
  }, [navigate]);

  const [isApp, setIsApp] = useAppCheck();

  //set up environment for the mobile application
  const [searchParams] = useSearchParams();
  const mobileAppParam = searchParams.get("mobileapp");
  useEffect(() => {
    if (mobileAppParam === "true") {
      setIsApp(true);
    }
  }, [mobileAppParam, setIsApp]);

  useEffect(() => {
    if (!isApp) {
      return;
    }
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        action: "LOGIN_STATUS",
        loggedIn: token?.length > 0
      })
    );
  }, [isApp, token]);

  const courierServiceReady = typeof useCourierService()?.getCouriersByIds === "function";
  const deliveryServiceReady = typeof useDeliveryService()?.getDeliveriesByIds === "function";

  const servicesReady = courierServiceReady && deliveryServiceReady;

  const {isLoading} = useLoadingContext();

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setMapsKey((await axios.get("/api/env/gmaps-key"))?.data);
      } catch (e) {
        onError(e);
      }
    })();
  }, [onError]);

  return (
    <>
      {
        (mapsKey && servicesReady && ready) ?
          <Wrapper apiKey={mapsKey?.toString()} libraries={["geometry"]}>
            <div
              style={{
                ...(isLoading.length > 0 ?
                  {overflow: "hidden", maxHeight: "100vh"} : {}),
                ...(isApp ? {/*overflowX: "hidden",*/ width: "100%"} : {}),
              }}
            >
              {
                !isApp ?
                  <>
                    {
                      screenWidth > 1000 ? (
                        <>
                          <NavigationBar/>
                          <SideBar/>
                        </>
                      ) : (
                        <MobileNavBar/>
                      )
                    }
                  </> : null
              }
              <Routes>
                <Route
                  path="/courier/"
                  element={
                    <>
                      <VerificationWarning verifyType="email"/>
                      <VerificationWarning verifyType="sms"/>
                      <PrivateOutlet roles={["courier"]}/>
                    </>
                  }
                >
                  <Route
                    path="delete-account/"
                    element={<DeleteAccount/>}
                  />
                  <Route path="" element={<CheckOrganization/>}>
                    <Route path="deliveries/">
                      <Route path=":tabKey" element={
                        <CourierDeliveriesTabs />}/>
                      <Route path="" element={
                        <CourierDeliveriesTabs />}/>
                    </Route>
                    <Route path="search/">
                      <Route path=":orderId" element={<CourierSearch/>}/>
                      <Route path="" element={<CourierSearch/>}/>
                    </Route>
                    <Route path="create/" element={<CourierCreateDelivery/>}/>
                    <Route path="questions/" element={<Questions/>}/>
                    <Route path="organization/">
                      <Route path=":tabKey" element={<CourierOrganization/>}/>
                      <Route path="" element={<CourierOrganization/>}/>
                    </Route>
                    <Route path="profile/" element={<CourierProfile/>}/>
                    <Route
                      path="join-organization/"
                      element={<JoinOrganizationProcess/>}/>
                    <Route
                      path="push-notifications"
                      element={<PushNotifications/>}
                    />
                  </Route>
                </Route>
                {/* <Route path="courier/join-organization"
                element={<JoinOrganizationProcess />} /> */}

                <Route
                  path="/consumer/"
                  element={
                    <>
                      <VerificationWarning verifyType="email"/>
                      <PrivateOutlet roles={["customer"]}/>
                    </>
                  }
                >
                  <Route
                    path="create/"
                    element={<CreateDelivery wizardOn={true}/>}/>
                  <Route
                    path="deliveries/"
                    element={<ConsumerDeliveries delivered={false}/>}/>
                  <Route
                    path="delivered/"
                    element={<ConsumerDeliveries delivered={true}/>}/>
                  <Route path="profile/" element={<ConsumerProfile/>}/>
                </Route>

                <Route
                  path="/admin/"
                  element={<PrivateOutlet roles={["admin"]}/>}
                >
                  <Route path="users/" element={<Admin/>}/>
                  <Route path="deliveries/" element={<Deliveries/>}/>
                </Route>

                <Route path="/register" element={<Register/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route
                  path="/login/admin"
                  element={<AdminLogin loginType={"admin"}/>}/>
                <Route
                  path="verify/:userType/:verifyType/:hash"
                  element={<Verify/>}/>
                <Route path="/forgot-password" element={<ForgotPassword/>}/>
                <Route
                  path="/recover-password/:userType/:hash"
                  element={<RecoverPassword/>}
                />
                <Route
                  path="/payment-status"
                  element={<PaymentStatus success={true}/>}/>
                <Route
                  path="/payment-notify"
                  element={<PaymentStatus success={false}/>}/>
                <Route
                  path="/"
                  element={<PrivateOutlet
                    roles={["admin", "customer", "courier"]}/>}
                >
                  <Route path="logout/" element={<LogoutView/>}/>
                </Route>
              </Routes>
            </div>
          </Wrapper> : <h3>Ladataan...</h3>
      }
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        style={{
          zIndex: 10e9
        }}
      />
    </>
  );
}

export default App;
