import { useEffect } from "react";
import { Button, Container, Form, Row, Stack } from "react-bootstrap";
import Modal from "react-modal";
import { useForm } from "react-hook-form";
import { useOrganizationService } from "../../../hooks/services/useOrganizationService";
import { useOnError } from "../../../hooks/useOnError";
import {defaultModalStyles} from "../../../styles/defaultModalStyles";

export const MemberModal = ({
  activeMember,
  close,
  setShowDelete,
  refreshOrganization
}) => {
  const onError = useOnError();
  const organizationService = useOrganizationService();

  const isTempUser = activeMember ? Object.keys(activeMember).includes("tmpPhoneNumber") : false;

  const {
    handleSubmit,
    register,
    setValue,
    watch
  } = useForm({});

  useEffect(() => {
    if (activeMember) {
      setValue("role", activeMember.role);
    }
  }, [activeMember]);

  const handleClose = () => {
    close();
  };

  const saveRole = async (data) => {
    try {
      await organizationService.changeRole({
        memberItemId: activeMember._id,
        ...data
      });
      refreshOrganization();
      close();
    } catch (e) {
      onError(e);
    }
  };

  return (
    <Modal
      isOpen={activeMember !== null}
      onRequestClose={handleClose}
      style={defaultModalStyles}
    >
      <Container fluid>
        <Row style={{ justifyContent: "center" }}>
          <h3 className="mb-4">Jäsenen tiedot</h3>
          <Form
            noValidate
            onSubmit={handleSubmit(saveRole)}
          >
            <Form.Group>
              <p>Nimi: {isTempUser ? "Ei tiedossa" :
                `${activeMember?.courier.lastName} ${activeMember?.courier.firstName}`}
              </p>
              <p>Puh: {isTempUser ? activeMember?.tmpPhoneNumber :
                activeMember?.courier.phoneNumber}</p>
              <p>Sähköposti: {isTempUser ? "Ei tiedossa" : activeMember?.courier.email}</p>

              <Form.Label htmlFor="rolePossibilities">Rooli</Form.Label>
              <Form.Select
                id="rolePossibilities"
                name="role"
                {...register("role")}
              >
                <option value="admin">{"Admin"}</option>
                <option value="user">{"Kuljettaja"}</option>
              </Form.Select>
            </Form.Group>

            < BottomButtons
              close={close}
              setShowDelete={setShowDelete}
              watch={watch}
              activeMember={activeMember}
            />
          </Form>
        </Row>
      </Container>
    </Modal>
  );
};

const BottomButtons = ({
  close,
  setShowDelete,
  watch,
  activeMember
}) => {
  return (
    <div
      className="mt-3"
    >
      {
        watch("role") === activeMember?.role ? (
          <Stack
            direction="horizontal"
            gap={4}
            style={{
              justifyContent: "flex-end"
            }}
          >
            <Button
              id="delete"
              onClick={() => setShowDelete(true)}
              className="btn-base-style"
              variant="danger"
            >
              Poista
            </Button>
            <Button
              id="close"
              onClick={close}
              className="btn-primary-color"
            >
              Sulje
            </Button>
          </Stack>
        ) : (
          <Stack
            direction="horizontal"
            gap={5}
            style={{ justifyContent: "flex-end" }}
          >
            <Button
              id="save"
              type="submit"
              className="btn-primary-color"
            >
              Tallenna
            </Button>
          </Stack>
        )
      }
    </div>
  );
};