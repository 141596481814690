import { useEffect } from "react";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { useCourierService } from "../../hooks/services/useCourierService";
import { useCustomerService } from "../../hooks/services/useCustomerService";
import useToken from "../../hooks/useToken";
import { getJwtRole } from "../../util/jwt";
import { ResendButton } from "../common/ResendButton";
import { toast } from "react-toastify";
import { useVerifyContext } from "../../contexts/VerifyContext";
import { useUserContext } from "../../contexts/UserContext";

export const VerificationWarning = ({ verifyType }) => {
  const [token] = useToken();
  const customerService = useCustomerService();
  const courierService = useCourierService();
  const { user } = useUserContext();
  const {
    showEmailVerifyWarn,
    setShowEmailVerifyWarn,
    showSmsVerifyWarn,
    setShowSmsVerifyWarn
  } = useVerifyContext();

  const role = getJwtRole(token);

  useEffect(() => {
    const checkVerify = () => {
      if (role === "customer") {
        setShowEmailVerifyWarn(!user.emailVerification || !user.emailVerification.completed);
      } else if (role === "courier") {
        if (verifyType === "email" && user.primaryCommunication === "email") {
          setShowEmailVerifyWarn(!user.emailVerification || !user.emailVerification.completed);
        } else {
          setShowSmsVerifyWarn(!user.smsVerification || !user.smsVerification.completed);
        }
      }
    };
    if (user) checkVerify();
  }, [user]);

  const renewVerify = async () => {
    role === "customer" ?
      await customerService.renewVerifyWithCreds() :
      await courierService.renewVerifyWithCreds(verifyType);
    toast.success("Uusi koodi lähetetty");
  };

  if (verifyType === "email" ? showEmailVerifyWarn : showSmsVerifyWarn) {
    return (
      <Container
        style={{
          position: "sticky",
          top: 0,
          zIndex: 1
        }}
      >
        <Row>
          <Col>
            <Alert
              variant="warning"
              onClose={
                () => verifyType === "email" ?
                  setShowEmailVerifyWarn(false) : setShowSmsVerifyWarn(false)
              }
              dismissible
            >
              <Row
                style={{
                  alignItems: "center"
                }}
              >
                <Col lg={9}>
                  <span>
                    {`${verifyType === "email" ? "Sähköpostiosoitetta" : "Puhelinnumeroa"}
                  ei ole vielä vahvistettu. Painiketta painamalla sinulle
                  lähetetään uusi vahvistusviesti.`}
                  </span>
                </Col>
                <Col lg={3}>
                  <ResendButton
                    resendAction={renewVerify}
                  />
                </Col>
              </Row>
            </Alert>
          </Col>
        </Row>
      </Container >
    );
  }
};