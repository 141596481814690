import { Row, Col, Button } from "react-bootstrap";
import GradientDiv from "../Gradient";
import { formatDate } from "../../util/format";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useDeliveryService } from "../../hooks/services/useDeliveryService";
import { ConfirmDeleteModal } from "../common/ConfirmDeleteModal";
import { useCourierDeliveryService } from "../../hooks/services/useCourierDeliveryService";
import { useOnError } from "../../hooks/useOnError";
import { CourierDeliveryStatusText, DeliveryStatus } from "../../util/constants";

export const CourierDeliveryListings = ({
  courierDelivery,
  setActiveDelivery,
  setActiveCourierDelivery,
  setActiveRequestId,
  refreshAllDeliveries,
  setEditMode
}) => {
  const [customerDeliveries, setCustomerDeliveries] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deliveryService = useDeliveryService();
  const courierDeliveryService = useCourierDeliveryService();

  const onError = useOnError();

  const edit = () => {
    setActiveDelivery(courierDelivery);
    setEditMode(true);
  };

  const remove = async () => {
    try {
      await courierDeliveryService.delete(courierDelivery.id);
    } catch (e) {
      onError(e);
      return;
    }
    toast.success("Kuljetus poistettu onnistuneesti");
    await refreshAllDeliveries();
  };

  useEffect(() => {
    const fetchDeliveryInfo = async () => {
      const deliveries = await deliveryService.getDeliveriesByIds(
        courierDelivery.deliveryRequests.map((request) => request.deliveryId)
      );
      setCustomerDeliveries(deliveries);
    };
    if (courierDelivery.deliveryRequests.length > 0) fetchDeliveryInfo();
  }, [courierDelivery]);

  const dateString = formatDate(courierDelivery.deliveryDate);

  return (
    <GradientDiv>
      <Row className="mb-2">
        <Col>
          <h5 className="nowrap" style={{ marginBottom: "13px" }}>
            {courierDelivery.origin + " - " + courierDelivery.destination}
            <span style={{ marginLeft: "16px", fontSize: "16px" }}>
              {dateString}
            </span>
          </h5>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col lg={5} xl={4}>
          <Button
            className="btn-primary-color"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "1rem"
            }}
            onClick={edit}
          >
            <span className="material-symbols-outlined">
              {
                "edit"
              }
            </span>
            {
              "Muokkaa"
            }
          </Button>
        </Col>
        {
          <Col lg={2}>
            <Button
              className="btn-text" style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1rem"
              }}
              onClick={() => setShowDeleteModal(true)}
            >
              <span className="material-symbols-outlined">delete</span>
              Poista
            </Button>
            <ConfirmDeleteModal
              isOpen={showDeleteModal}
              deleteAction={remove}
              close={() => setShowDeleteModal(false)}
              targetItem={
                `${courierDelivery.origin} -
                ${courierDelivery.destination} ${dateString}`
              }
            />
          </Col>
        }
      </Row>
      <DeliveryRequests
        courierDelivery={courierDelivery}
        customerDeliveries={customerDeliveries}
        setActiveDelivery={setActiveDelivery}
        setActiveCourierDelivery={setActiveCourierDelivery}
        setActiveRequestId={setActiveRequestId}
      />
    </GradientDiv>
  );
};

const DeliveryRequests = ({
  courierDelivery,
  customerDeliveries,
  setActiveDelivery,
  setActiveCourierDelivery,
  setActiveRequestId
}) => {
  return (
    <Row>
      <Col xs={12} className="mt-4">
        <h5 className="nowrap mb-3">Kuljetuspyynnöt:</h5>
        <Row>
          {
            courierDelivery.deliveryRequests.length > 0 ? (
              courierDelivery.deliveryRequests.map((request, index) => {
                const foundDelivery = customerDeliveries.find((delivery) =>
                  delivery && delivery._id === request.deliveryId
                );

                return (
                  foundDelivery?.status === DeliveryStatus.OPEN && (
                    <Col
                      key={index}
                      xs={12}
                      sm={6}
                      lg={4}
                    >
                      <GradientDiv>
                        <Row className="mb-3">
                          <Col>
                            {`Pyyntö ${index + 1}`}
                          </Col>
                          <Row>
                            <Col>
                              {`Tila: ${CourierDeliveryStatusText[request.status]}`}
                            </Col>
                          </Row>
                        </Row>
                        <Row>
                          <Col xs={4}>
                            <Button
                              className="btn-primary-color"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "1rem"
                              }}
                              onClick={() => {
                                setActiveDelivery(foundDelivery ?? null);
                                setActiveCourierDelivery(courierDelivery);
                                setActiveRequestId(request._id);
                              }}
                            >
                              <span className="material-symbols-outlined">
                                {
                                  "visibility"
                                }
                              </span>
                              {
                                "Näytä"
                              }
                            </Button>
                          </Col>
                        </Row>
                      </GradientDiv>
                    </Col>
                  )
                );
              })
            )
              : (
                <p>Ei kuljetuspyyntöjä</p>
              )
          }
        </Row>
      </Col>
    </Row>
  );
};