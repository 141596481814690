import Modal from "react-modal";
import { Button, Row, Col, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { agreementTexts } from "./AgreementTexts";
import {defaultModalStyles} from "../../styles/defaultModalStyles";
import useAppCheck from "../../hooks/useAppCheck";

const modalStyles = {
  content: {
    ...defaultModalStyles.content,
    width: "60%",
  }
};

export const AgreementModal = ({
  activeAgreement,
  setActiveAgreement
}) => {
  const [isOpen, setIsOpen] = useState(false);
  
  const [ isApp ] = useAppCheck();
  if(isApp) {
    modalStyles.content.width = "95%";
  }

  useEffect(() => {
    if (activeAgreement)
      setIsOpen(true);
    else
      setIsOpen(false);
  }, [activeAgreement]);

  let headerText;
  if (activeAgreement === "tos") {
    headerText = "Yleiset sopimusehdot";
  } else if(activeAgreement === "privacy") {
    headerText = "Tietosuojaseloste";
  } else if(activeAgreement === "visma") {
    headerText = "Toimitusehdot";
  }

  return (
    <Modal
      isOpen={isOpen}
      style={modalStyles}
    >
      <Container fluid>
        <Row
          style={{
            maxHeight: "70vh",
            overflowY: "scroll",
            padding: "1rem"
          }}
        >
          <Col
            xs={12}
            className="mb-3"
          >
            <h1>{headerText}</h1>
          </Col>
          <Col xs={12}>
            {activeAgreement && agreementTexts[activeAgreement]()}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-end mt-4">
            <Button
              id="close"
              className="btn-primary-color"
              onClick={() => setActiveAgreement("")}
            >
              Sulje
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};
