import { Form, FormControl} from "react-bootstrap";
import { FormErrorMessage } from "../form/FormErrorMessage";

import { BankingInformationInput } from "./BillingInformationInput";

export const OrganizationFields = (props) => {
  const { register, errors, clearErrors, isReadOnly, setValue, getValues } = props;

  const adminCourier = getValues("couriers").find(courier => courier.role === "admin")?.courier;

  return (
    <>
      <Form.Group className="mb-2" md="2">
        <Form.Label htmlFor="name">Yrityksen nimi</Form.Label>
        <FormControl
          id="name"
          type="text"
          isInvalid={errors.name}
          onInput={() => clearErrors("name")}
          {...register("name", {
            required: "Vaadittu kenttä"
          })}
          readOnly={isReadOnly}
        />
        <FormErrorMessage
          error={errors.name}
          errorMessage={errors.name?.message}
        />
      </Form.Group>

      <Form.Group className="mb-2" md="2">
        <Form.Label htmlFor="address">Osoite</Form.Label>
        <FormControl
          id="address"
          type="text"
          isInvalid={errors.address}
          onInput={() => clearErrors("address")}
          {...register("address", {
            required: "Vaadittu kenttä"
          })}
          readOnly={isReadOnly}
        />
        <FormErrorMessage
          error={errors.address}
          errorMessage={errors.address?.message}
        />
      </Form.Group>

      <Form.Group className="mb-2" md="2">
        <Form.Label htmlFor="businessId">Y-tunnus</Form.Label>
        <FormControl
          id="businessId"
          type="text"
          isInvalid={errors.businessId}
          onInput={() => clearErrors("businessId")}
          {...register("businessId", {
            required: "Vaadittu kenttä",
            minLength: {
              value: 5,
              message: "Y-tunnus liian lyhyt"
            },
            maxLength: {
              value: 16,
              message: "Y-tunnus liian pitkä"
            }
          })}
          readOnly={isReadOnly}
        />
        <FormErrorMessage
          error={errors.businessId}
          errorMessage={errors.businessId?.message}
        />
      </Form.Group>

      <Form.Group className="mb-4">
        <Form.Check
          type="checkbox"
          id="trustedPartner"
          label="Luotettava kumppani"
          disabled={isReadOnly}
          {...register("trustedPartner", {})}
        />
      </Form.Group>

      <div>
        <h5>Pääkäyttäjän tiedot:</h5>
        <p>{adminCourier?.firstName + " " + adminCourier?.lastName}</p>
        <p>{adminCourier ? "+" + adminCourier.phoneNumber : ""}</p>
        <p>{adminCourier?.email}</p>
        <p>Kommunikointitapa: {adminCourier?.primaryCommunication}</p>
      </div>

      <div className="mb-4">
        <BankingInformationInput
          register={register}
          setValue={setValue}
          getValues={getValues}
          isReadOnly={isReadOnly}
        />
      </div>
    </>
  );
};