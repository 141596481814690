import "./SideBar.css";
import { NavLinksCourier } from "./courier/NavLinks";
import { NavLinksConsumer } from "./consumer/NavLinks";
import { NavLinksAdmin } from "./admin/NavLinksAdmin";
import { Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import useToken from "../hooks/useToken";
import {getJwtRole} from "../util/jwt";

export default function SideBar({ mobile }) {
  const [token] = useToken();
  const role = getJwtRole(token);

  return (
    <Navbar className="sidebar">
      <Nav className="sidebar-items">
        <Navbar.Brand as={Link} to={`/${role === "courier"? "courier" : "consumer"}/deliveries`}>
          <img src="/cargo.png" className="logo" alt="logo" />
        </Navbar.Brand>
        {role === "courier" ? <NavLinksCourier></NavLinksCourier> : null}
        {role === "customer" ? <NavLinksConsumer></NavLinksConsumer> : null}
        {role === "admin" ? <NavLinksAdmin></NavLinksAdmin> : null}
      </Nav>
    </Navbar>
  );
}