import { Col, Container, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { SubmitButton } from "../SubmitButton";
import { useOnError } from "../../hooks/useOnError";
import { toast } from "react-toastify";
import { useCourierService } from "../../hooks/services/useCourierService";
import { useCustomerService } from "../../hooks/services/useCustomerService";
import { useUserContext } from "../../contexts/UserContext";
import useAppCheck from "../../hooks/useAppCheck";
import { useCallback } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { FormErrorMessage } from "../form/FormErrorMessage";

export default function ForgotPassword() {
  const {
    handleSubmit,
    formState,
    register,
    setValue,
    watch,
    reset,
    control,
    clearErrors
  }
    = useForm({ defaultValues: { userType: "consumer" } });
  const customerService = useCustomerService();
  const courierService = useCourierService();
  const onError = useOnError();

  const [ isApp ] = useAppCheck();

  const { user } = useUserContext();

  const userType = watch("userType");

  const attemptRecoverPassword = useCallback(async data => {
    try {
      const message =
        await ((userType === "courier" || isApp)?
          courierService : customerService).forgotPassword(data);

      toast.success(message);
    } catch (e) {
      onError(e);
    }
  }, [courierService, customerService, userType, onError, user]);

  return (
    <Container>
      <Col style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "40px"
      }}>
        <h1 style={{ margin: "0" }}>Palauta salasana</h1>
      </Col>
      <Row style={{ justifyContent: "center" }}>
        <Form
          onSubmit={handleSubmit(attemptRecoverPassword)}
          className="col-sm-12 col-md-7 col-lg-4"
        >
          {!isApp ?
            <Form.Group className="mb-3">
              Valitse käyttäjän tyyppi
              <Form.Group controlId="userType">
                <Form.Check
                  name="userType"
                  type="radio"
                  value="Tilaaja"
                  aria-label="radio 2"
                  id="radio 2"
                  label="Tilaaja"
                  checked={watch("userType") === "consumer"}
                  onChange={() => {
                    reset();
                    setValue("userType", "consumer");
                  }}
                />
              </Form.Group>
              <Form.Check
                name="userType"
                type="radio"
                value="Kuljettaja"
                aria-label="radio 1"
                id="radio 1"
                label="Kuljettaja"
                checked={watch("userType") === "courier"}
                onChange={() => {
                  reset();
                  setValue("userType", "courier");
                }}
              />
            </Form.Group>
            : null
          }
          {
            watch("userType") === "consumer" && !isApp? (
              <Form.Group className="mb-3">
                <Form.Label htmlFor="email">Sähköposti</Form.Label>
                <InputGroup className="mb-4" md="2">
                  <FormControl
                    id="email"
                    type="text"
                    {...register("email")}
                    size="lg"
                  />
                </InputGroup>
              </Form.Group>
            )
              : (
                <Form.Group className="mb-4" md="2">
                  <Form.Label>Puhelinnumero</Form.Label>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    rules={{
                      required: "Vaadittu kenttä"
                    }}
                    render={(props) => {
                      return (
                        <PhoneInput
                          value={props.field.value}
                          onChange={phone => {
                            clearErrors("phoneNumber");
                            props.field.onChange(phone);
                          }}
                          containerStyle={{
                            width: "100%"
                          }}
                          inputStyle={{
                            width: "100%"
                          }}
                          autoFormat={true}
                          onKeyDown={(evt) => evt.key === "Enter" && handleSubmit()}
                          specialLabel="Puhelinnumero"
                          preferredCountries={["fi"]}
                          country={"fi"}
                          masks={{ fi: ".. ......." }}
                          isValid={() => !formState.errors.phoneNumber}
                        />
                      );
                    }}
                  />
                  <FormErrorMessage
                    error={formState.errors.phoneNumber}
                    errorMessage={formState.errors.phoneNumber?.message}
                  />
                </Form.Group>
              )
          }

          <Col style={{ display: "flex", justifyContent: "center" }}>
            <SubmitButton
              formState={formState}
              submitValue="Palauta salasana"
            />
          </Col>
        </Form>
      </Row>
    </Container>
  );
}