import axios from "axios";
import { useCallback } from "react";
import { useAuth } from "../useAuth";

export const useOrganizationService = () => {
  const auth = useAuth();
  const basePath = "/api/organizations";

  return useCallback(() => ({
    get: async () => (await axios.get(`${basePath}`, auth))?.data,
    create: async newObject => (await axios.post(basePath, newObject, auth))?.data,
    update: async data => (await axios.patch(basePath, data, auth))?.data,
    addCourier: async data => (await axios.post(`${basePath}/couriers`, data, auth))?.data,
    removeCourier: async data => (
      await axios.delete(`${basePath}/couriers`, { ...auth, data: data })
    )?.data,
    changeRole: async data => (await axios.patch(`${basePath}/change-role`, data, auth))?.data,
    getInvitations: async data => (
      await axios.post(`${basePath}/get-invitations`, data, auth)
    )?.data,
    handleInvitation: async data => (
      await axios.post(`${basePath}/handle-invitation`, data, auth)
    )?.data,
  }), [auth])();
};