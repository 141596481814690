import {Container, Form} from "react-bootstrap";
import {useEffect, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import {useOnError} from "../../hooks/useOnError";
import axios from "axios";

export default function PushNotifications() {
  const auth = useAuth();
  const [pushTokens, setPushTokens] = useState([]);
  const [pushToken, setPushToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const onError = useOnError();

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({action: "PUSH_TOKEN_FETCH"})
    );

    (async () => {
      setLoading(true);

      try {
        const courier = (await axios.get(`/api/couriers/profile`, auth))?.data;
        setPushTokens(courier?.pushNotificationTokens ?? []);
      } catch (e) {
        onError(e);
      }

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    window.setCurrentPushToken = async token => {
      setPushToken(token);
    };
  }, []);


  return <Container>
    <h3>Push-ilmoitukset</h3>

    <Form.Check
      type={"checkbox"}
      label={"Ota käyttöön push-ilmoitukset"}
      checked={pushToken?.length > 0 && pushTokens.includes(pushToken)}
      disabled={loading}
      onClick={async () => {
        if (pushTokens.includes(pushToken)) {
          setLoading(true);

          try {
            setPushTokens((await axios.post(
              "/api/couriers/update-push-notification-tokens",
              {
                action: "REMOVE",
                token: pushToken
              },
              auth
            ))?.data);
          } catch (e) {
            onError(e);
          }

          setLoading(false);
        } else {
          setLoading(true);
          try {
            setPushTokens((await axios.post(
              "/api/couriers/update-push-notification-tokens",
              {
                action: "ADD",
                token: pushToken
              },
              auth
            ))?.data);
          } catch (e) {
            onError(e);
          }

          setLoading(false);
        }
      }}
    >

    </Form.Check>
  </Container>;
}