/* eslint-disable max-len */
// TODO: Remove eslint-disable max-len
import Modal from "react-modal";
import {defaultModalStyles} from "../../styles/defaultModalStyles";
import { Col, Form, Row, Button} from "react-bootstrap";
import { useDeliveryService } from "../../hooks/services/useDeliveryService";
import { useOnError } from "../../hooks/useOnError";
import { useState } from "react";
import { toast } from "react-toastify";


const modalStyles = {
  content: {
    ...defaultModalStyles.content,
  }
};

export const AnswerModal = ({
  isOpen,
  delivery,
  close,
  questionId,
  refreshAllDeliveries,
}) => {
  const deliveryService = useDeliveryService();
  const onError = useOnError();

  const toastMessage = "Vastaus lähetetty onnistuneesti";

  const [answer, setAnswer] = useState("");

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      style={modalStyles}
    >
      {
        delivery ?
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              deliveryService.answerQuestion(
                delivery.id,
                questionId,
                answer,
              ).then(() => {
                toast.success(toastMessage);
                refreshAllDeliveries();
                close();
              }
              ).catch(onError);
            }}>
            <Row style={{ height: "60px" }}>
              <Col className="mb-5">
                <h4>Olet tekemässä vastausta</h4>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Vastaus</Form.Label>
                  <Form.Control as="textarea" rows={3} value={answer} onChange={
                    (e) => {
                      setAnswer(e.target.value);
                    }
                  } />
                </Form.Group>
              </Col>
            </Row>
       
            

            <Row style={{ marginTop: "60px" }}>
              <Col xs={{ span: 2, offset: 0 }}>
                <Button
                  className="btn-primary-color"
                  type="submit"
                >
                Lähetä vastaus
                </Button>
              </Col>
              <Col xs={4}>
                <Button
                  className="btn-text" onClick={close}
                  style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <span className="material-symbols-outlined">close</span>
              Peruuta
                </Button>
              </Col>
            </Row>
          </Form>
          : null 
      }

    </Modal>
  );
};