import {useState, useEffect} from "react";
import ConsumerRegister, {Modes} from "../../register/ConsumerRegister";
import { useCustomerService } from "../../../hooks/services/useCustomerService";

export default function Profile(props) {
  const customerService = useCustomerService();
  const [defaultValues, setDefaultValues] = useState();

  useEffect(() => {
    const getDefaultValues = async () => {
      setDefaultValues(await customerService.get());
    };

    getDefaultValues();
  }, []);

  return (
    <div className="p-2">
      <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3">
        <h3>Muokkaa tietojasi</h3>
      </div>
      {defaultValues &&
        <ConsumerRegister mode={Modes.EDIT} defaultValues={defaultValues}/>
      }
    </div>
  );
}