import { Col, Form, FormControl, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { SubmitButton } from "../SubmitButton.js";
import { RegisterModal } from "./RegisterModal";
import { useOrganizationService } from "../../hooks/services/useOrganizationService";
import { useOnError } from "../../hooks/useOnError";
import { toast } from "react-toastify";
import { FormErrorMessage } from "../form/FormErrorMessage.js";
import { BankingInformationInput } from "./BillingInformationInput";
import { useUserContext } from "../../contexts/UserContext";


export default function OrganizationRegister({
  editMode,
  defaultValues,
  fullWidth,
  backButton
}) {
  const onError = useOnError();

  const form = useForm({
    reValidateMode: "onSubmit",
    defaultValues: defaultValues ? defaultValues : {}
  });
  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
    getValues
  } = form;

  const [showBusinessIdMsg, setShowBusinessIdMsg] = useState(false);
  const [registrationSuccessful, setRegistrationSuccessful] = useState(false);

  const organizationService = useOrganizationService();
  const { user, setUser } = useUserContext();

  const attemptCreate = async data => {
    try {
      const organization = await organizationService.create(data);
      setRegistrationSuccessful(true);
      setUser({...user, organization: {...organization, accountStatus: "unconfirmed"}});
      toast.success("Organisaation luominen onnistui");
    } catch (e) {
      onError(e);
    }
  };

  const attemptSave = async data => {
    try {
      await organizationService.update(data);
      setRegistrationSuccessful(true);
      toast.success("Päivittäminen onnistui");
    } catch (e) {
      onError(e);
    }
  };

  let submitFunction;
  if (editMode) {
    submitFunction = attemptSave;
  } else {
    submitFunction = attemptCreate;
  }

  return (
    <>
      <Form
        noValidate
        onSubmit={handleSubmit(submitFunction)}
        className={fullWidth ? "100-w" : "col-sm-8 col-md-6"}
      >
        <Form.Group className="mb-2" md="2">
          <Form.Label htmlFor="name">Yrityksen nimi</Form.Label>
          <FormControl
            id="name"
            type="text"
            isInvalid={formState.errors.name}
            onInput={() => clearErrors("name")}
            {...register("name", {
              required: "Vaadittu kenttä"
            })}
          />
          <FormErrorMessage
            error={formState.errors.name}
            errorMessage={formState.errors.name?.message}
          />
        </Form.Group>

        <Form.Group className="mb-2" md="2">
          <Form.Label htmlFor="address">Osoite</Form.Label>
          <FormControl
            id="address"
            type="text"
            isInvalid={formState.errors.address}
            onInput={() => clearErrors("address")}
            {...register("address", {
              required: "Vaadittu kenttä"
            })}
          />
          <FormErrorMessage
            error={formState.errors.address}
            errorMessage={formState.errors.address?.message}
          />
        </Form.Group>

        <OverlayTrigger
          placement="top"
          onToggle={(show) => editMode ? setShowBusinessIdMsg(show) : null}
          show={showBusinessIdMsg}
          overlay={
            <Tooltip id="tooltip">
              Y-tunnuksen vaihtamista varten ota yhteyttä ylläpitoon
            </Tooltip>
          }
        >
          <Form.Group className="mb-2" md="2">
            <Form.Label htmlFor="businessId">Y-tunnus</Form.Label>
            <FormControl
              id="businessId"
              type="text"
              disabled={editMode}
              isInvalid={formState.errors.businessId}
              onInput={() => clearErrors("businessId")}
              {...register("businessId", {
                required: "Vaadittu kenttä",
                minLength: {
                  value: 5,
                  message: "Y-tunnus liian lyhyt"
                },
                maxLength: {
                  value: 16,
                  message: "Y-tunnus liian pitkä"
                }
              })}
            />
            <FormErrorMessage
              error={formState.errors.businessId}
              errorMessage={formState.errors.businessId?.message}
            />
          </Form.Group>
        </OverlayTrigger>
        <BankingInformationInput
          register={register}
          setValue={setValue}
          getValues={getValues}
        />
        <Row style={{ justifyContent: "space-between" }}>
          {/* <Col style={{ display: "flex", justifyContent: "center", margin: 20 }}> */}
          {backButton ? backButton() : null}
          <Col
            xs={12}
            xl={backButton ? 5 : 12}
            className="mt-4"
            style={
              backButton ? { display: "flex", alignItems: "flex-end" }
                : { display: "flex", justifyContent: "center" }
            }>
            <SubmitButton
              formState={formState}
              submitValue={editMode ? "Tallenna" : "Luo organisaatio"}
              icon={<span className="material-symbols-outlined">group</span>} />
          </Col>
          {/* </Col> */}
        </Row>
        <RegisterModal
          registrationSuccessful={registrationSuccessful}
          userType={"courier"}
          editMode={editMode}
        />
      </Form>
    </>
  );
}