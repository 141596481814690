import axios from "axios";
import { useAuth } from "../useAuth";
import { useCallback } from "react";
import { useCache } from "../useCache";

export const useDeliveryService = () => {
  const auth = useAuth();
  const basePath = "/api/deliveries";

  const getDeliveriesByIds = useCache(basePath, "deliveries").current;

  return useCallback(() => ({
    search: async params => (await axios.get(basePath, { params, ...auth }))?.data,
    create: async newObject => (await axios.post(basePath, newObject, auth))?.data,
    delete: async id => (await axios.delete(`${basePath}/${id}`, auth))?.data,
    modify: async (id, delivery) => (await axios.put(`${basePath}/${id}`, delivery, auth))?.data,
    acceptCounterOffer: async (id, counterOfferId) => (
      await axios.post(`${basePath}/${id}/counteroffers/${counterOfferId}/accept`, {}, auth)
    )?.data,
    counterOffer: async (id, counterOffer, requestId) => (
      await axios.post(`${basePath}/${id}/counteroffers`, { counterOffer, requestId }, auth)
    )?.data,
    delivered: async (id, formData) =>
      (await axios.post(
        `${basePath}/${id}/delivered`,
        formData,
        {headers: {...auth.headers, "Content-Type": "multipart/form-data"}}
      ))?.data,
    deleteCounterOffer: async (id, counterOfferId) =>
      (await axios.delete(`${basePath}/${id}/counteroffers/${counterOfferId}`, auth))?.data,
    getDeliveriesByOrderId: async orderId => (
      await axios.get(`${basePath}/orderid/${orderId}`, auth)
    )?.data,
    changeCourier: async (id, courierId) => (
      await axios.post(`${basePath}/${id}/change-courier`, {courierId}, auth)
    )?.data,
    createQuestion: async (id, question) => (
      await axios.post(`${basePath}/${id}/questions`, {question}, auth)
    ),
    getQuestions: async () => (
      await axios.get(`${basePath}/questions`, auth)
    ),
    deleteQuestion: async (id, questionId) => (
      await axios.delete(`${basePath}/${id}/questions/${questionId}`, auth)
    ),
    answerQuestion: async (id, questionId, answer) => (
      await axios.post(`${basePath}/${id}/questions/${questionId}/answer`, {answer}, auth)
    ),
    getDeliveriesByIds
  }), [getDeliveriesByIds, auth])();
};