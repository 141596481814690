import { useState, useEffect } from "react";
import { Container, Form, FormControl, InputGroup, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import { Controller, useForm } from "react-hook-form";
import { SubmitButton } from "../../SubmitButton";
import { getMunicipalities } from "../../../util/publicapis";
import { Unloading } from "../../../util/constants";
import { ConfirmationModal } from "./ConfirmationModal";
import { useOnError } from "../../../hooks/useOnError";
import { useCourierDeliveryService } from "../../../hooks/services/useCourierDeliveryService";
import { FormErrorMessage } from "../../form/FormErrorMessage";
import { toast } from "react-toastify";
import { SingleDateInput } from "../../consumer/Create/CreateDeliveryFormElements";
import useAppCheck from "../../../hooks/useAppCheck";

export default function CreateDelivery({
  fullWidth,
  courierDelivery,
  refresh,
  disabledFields = []
}) {
  const courierDeliveryService = useCourierDeliveryService();

  const {
    register,
    handleSubmit,
    formState,
    control,
    clearErrors
  } = useForm({
    defaultValues: {
      ...(
        courierDelivery ? {
          origin: courierDelivery.origin,
          destination: courierDelivery.destination,
          dimensions: courierDelivery.dimensions,
          weight: courierDelivery.weight,
          deliveryDate: new Date(courierDelivery.deliveryDate.substring(0, 10)),
          unloading: courierDelivery.unloading,
          price: courierDelivery.price,
          info: courierDelivery.info
        } : {}
      )
    }
  });

  const onError = useOnError();
  const navigate = useNavigate();
  const [isApp] = useAppCheck();

  const [municipalities, setMunicipalities] = useState([]);
  const [delivery, setDelivery] = useState(null);

  useEffect(() => {
    getMunicipalities("fi")
      .then(municipalities => {
        setMunicipalities(municipalities);
      });
  }, []);

  const addOrModifyDelivery = async (data) => {
    if (!courierDelivery) {
      let result;
      try {
        result = await courierDeliveryService.create(data);
      } catch (e) {
        onError(e);
        return;
      }
      setDelivery(result);
    } else {
      try {
        await courierDeliveryService.modify(courierDelivery.id, data);
      } catch (e) {
        onError(e);
        return;
      }
      toast.success("Kuljetuksen tietoja muutettu onnistuneesti");
      close();
      refresh();
    }
  };

  const close = () => {
    setDelivery(null);
    navigate("/courier/deliveries" + (isApp ? "/offers" : ""));
  };

  return (
    <Container fluid>
      <Row style={{ margin: "40px" }}>
        <Col style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <span className="material-symbols-outlined" style={{ fontSize: "24px" }}>
            {courierDelivery ? "edit" : "add"}
          </span>
          <h3 style={{ margin: "0" }}>{courierDelivery ? "Muokkaa kuljetusta" : "Luo kuljetus"}</h3>
        </Col>
      </Row>
      <Row style={{ justifyContent: "center" }}>
        <Form
          onSubmit={handleSubmit((data) => addOrModifyDelivery(data))}
          className={fullWidth ? "col-12" : "col-sm-8 col-md-6 mb-3"}
        >
          <Form.Group className="mb-3">
            <Form.Label htmlFor="origin">Lähtöpaikka</Form.Label>
            <Controller
              name="origin"
              control={control}
              rules={{
                required: "Vaadittu kenttä"
              }}
              render={(props) => {
                return (
                  <Typeahead
                    id="origin"
                    selected={courierDelivery?.origin ? [courierDelivery?.origin] : null}
                    onChange={e => {
                      clearErrors("origin");
                      props.field.onChange(e[0]);
                    }}
                    disabled={disabledFields.includes("origin")}
                    isInvalid={Boolean(formState.errors.origin)}
                    options={municipalities}
                  />
                );
              }}
            />
            <FormErrorMessage
              error={formState.errors.origin}
              errorMessage={formState.errors.origin?.message}
            />
            <br></br>
            <Form.Label htmlFor="destination">Määränpää</Form.Label>
            <Controller
              name="destination"
              control={control}
              rules={{
                required: "Vaadittu kenttä"
              }}
              render={(props) => {
                return (
                  <Typeahead
                    id="destination"
                    selected={courierDelivery?.destination ? [courierDelivery?.destination] : null}
                    onChange={e => {
                      clearErrors("destination");
                      props.field.onChange(e[0]);
                    }}
                    disabled={disabledFields.includes("destination")}
                    isInvalid={Boolean(formState.errors.destination)}
                    options={municipalities}
                  />
                );
              }}
            />
            <FormErrorMessage
              error={formState.errors.destination}
              errorMessage={formState.errors.destination?.message}
            />
            <br></br>
            <Form.Label htmlFor="dimensions">Tilan määrä</Form.Label>
            <Row>
              <Col>
                <Form.Group className="mb-2">
                  <InputGroup>
                    <FormControl
                      id="dimensionX"
                      type="number"
                      isInvalid={formState.errors.dimensions?.x}
                      {...register("dimensions.x", {
                        required: "Vaadittu kenttä"
                      })}
                    />
                    <InputGroup.Text>m</InputGroup.Text>
                  </InputGroup>
                  <FormErrorMessage
                    error={formState.errors.dimensions?.x}
                    errorMessage={formState.errors.dimensions?.x?.message}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2">
                  <InputGroup>
                    <FormControl
                      id="dimensionY"
                      type="number"
                      isInvalid={formState.errors.dimensions?.y}
                      {...register("dimensions.y", {
                        required: "Vaadittu kenttä"
                      })}
                    />
                    <InputGroup.Text>m</InputGroup.Text>
                  </InputGroup>
                  <FormErrorMessage
                    error={formState.errors.dimensions?.y}
                    errorMessage={formState.errors.dimensions?.y?.message}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-2">
                  <InputGroup>
                    <FormControl
                      id="dimensionZ"
                      type="number"
                      isInvalid={formState.errors.dimensions?.z}
                      {...register("dimensions.z", {
                        required: "Vaadittu kenttä"
                      })}
                    />
                    <InputGroup.Text>m</InputGroup.Text>
                  </InputGroup>
                  <FormErrorMessage
                    error={formState.errors.dimensions?.z}
                    errorMessage={formState.errors.dimensions?.z?.message}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-2" md="2">
              <Form.Label htmlFor="weight">Paino</Form.Label>
              <InputGroup>
                <FormControl
                  id="weight"
                  type="number"
                  isInvalid={formState.errors.weight}
                  {...register("weight", {
                    required: "Vaadittu kenttä"
                  })}
                />
                <InputGroup.Text>kg</InputGroup.Text>
              </InputGroup>
              <FormErrorMessage
                error={formState.errors.weight}
                errorMessage={formState.errors.weight?.message}
              />
            </Form.Group>
            <Form.Group className="mb-2" md="2">
              <Form.Label htmlFor="deliveryDate">Matkan ajankohta</Form.Label>
              <SingleDateInput
                name="deliveryDate"
                control={control}
                errors={formState.errors}
                clearErrors={clearErrors}
                disabledFields={disabledFields}
                isRequired={true}
              />
              <FormErrorMessage
                error={formState.errors.deliveryDate}
                errorMessage={formState.errors.deliveryDate?.message}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="unloadPossibilities">Purkumahdollisuudet</Form.Label>
              <Form.Select
                id="unloadPossibilities"
                name="unloading"
                {...register("unloading")}
              >
                <option>{Unloading.ALL}</option>
                <option>{Unloading.CRANE}</option>
                <option>{Unloading.TAILBOARD}</option>
                <option>{Unloading.NONE}</option>
              </Form.Select>
            </Form.Group>
            <br></br>
            <Form.Label htmlFor="info">Lisätiedot</Form.Label>
            <Form.Control
              id="info"
              as="textarea"
              rows={3}
              {...register("info")}
            />
          </Form.Group>
          <SubmitButton
            formState={formState}
            submitValue={courierDelivery ? "Tallenna" : "Luo kuljetus"}
          />
        </Form>
        <ConfirmationModal delivery={delivery} close={close} />
      </Row>
    </Container>
  );
}
