import { Row, Col } from "react-bootstrap";
import GradientDiv from "../Gradient";

export const BusinessItem = ({ business, setActiveBusiness, type }) => {
  return (
    <GradientDiv className="listing" onClick={() => setActiveBusiness({business, type})}>
      <Row className="mb-2">
        <Col>
          <h6 className="nowrap">
            <div><b>{type === "courier" ? business.name : business.businessName}</b></div>
            {type === "customer" ? business.name : ""}
            <div>{business.businessId}</div>
          </h6>
        </Col>
      </Row>
    </GradientDiv>
  );
};
