import { Form } from "react-bootstrap";
import { useState } from "react";
import { FormErrorMessage } from "../form/FormErrorMessage";

const billingTypes = {
  eInvoice: "Verkkolaskutus",
  email: "Sähköposti"
};

export function BillingInformationInput (props) {

  const { register, setValue, getValues, isReadOnly, formState } = props;

  const defaultValue =
    getValues("billingInformation.email") ?
      billingTypes.email :
      billingTypes.eInvoice;
  
  const [billingType, setBillingType] = useState(defaultValue);
  
  return (
    <div style={{marginTop: 16}}>
      <Form.Group style={{marginBottom: 8}}>
        <Form.Label> Laskutus</Form.Label>
        <Form.Check
          name="billingType"
          type="radio"
          value={billingTypes.eInvoice}
          aria-label="radio_eInvoice"
          id="radio_eInvoice"
          label="Verkkolaskutus"
          onChange={evt => {
            setValue("billingInformation.email", "");
            setBillingType(billingTypes.eInvoice);
          }}
          checked={billingType === billingTypes.eInvoice}
          style={{ marginRight: "1.5rem" }}
          disabled={isReadOnly}
        />
        <Form.Check
          name="billingType"
          type="radio"
          value={billingTypes.email}
          aria-label="radio_billing_email"
          id="radio_billing_email"
          label="Sähköpostilaskutus"
          onChange={evt => {
            setValue("billingInformation.eInvoicingAddress", "");
            setValue("billingInformation.eInvoicingOperator", "");
            setBillingType(billingTypes.email);
          }}
          checked={billingType === billingTypes.email}
          style={{ marginRight: "1.5rem" }}
          disabled={isReadOnly}
        />
      </Form.Group>
      {
        billingType === billingTypes.email ?
          <Form.Group>
            <Form.Label>Laskutusosoite</Form.Label>
            <Form.Control
              id="billingEmail"
              type="email"
              {...register("billingInformation.email")}
              readOnly={isReadOnly}
            />
          </Form.Group> :
          <>
            <Form.Group>
              <Form.Label>Verkkolaskutusosoite</Form.Label>
              <Form.Control
                id="eInvoicingAddress"
                type="text"
                {...register("billingInformation.eInvoicingAddress",
                  {
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Syötä verkkolaskutusosoitteen numero"
                    }
                  })}
                readOnly={isReadOnly}
              />
              <FormErrorMessage
                error={formState.errors.billingInformation?.eInvoicingAddress}
                errorMessage={formState.errors.billingInformation?.eInvoicingAddress?.message}
              />
            </Form.Group>
            <Form.Group style={{marginTop: 8}}>
              <Form.Label>Verkkolaskutusoperaattori</Form.Label>
              <Form.Control
                id="eInvoicingOperator"
                type="text"
                {...register("billingInformation.eInvoicingOperator",
                  {
                    pattern: {
                      value: /^[0-9]*$/,
                      message: "Syötä nimen sijaan verkkolaskutusoperaattorin numero"
                    }
                  })
                }
                readOnly={isReadOnly}
              />
              <FormErrorMessage
                error={formState.errors.billingInformation?.eInvoicingOperator}
                errorMessage={formState.errors.billingInformation?.eInvoicingOperator?.message}
              />
            </Form.Group>
          </>
      }
    </div>
  );
};

export function BankingInformationInput (props) {

  const { register, isReadOnly } = props;

  return (
    <div style={{marginTop: 16}}>
      <>
        <Form.Group>
          <Form.Label>Tilinumero</Form.Label>
          <Form.Control
            id="bankAccount"
            type="text"
            {...register("bankingInformation.account")}
            readOnly={isReadOnly}
          />
        </Form.Group>
        <Form.Group style={{marginTop: 8}}>
          <Form.Label>Pankin BIC/SWIFT koodi</Form.Label>
          <Form.Control
            id="bankBic"
            type="text"
            {...register("bankingInformation.bic")}
            readOnly={isReadOnly}
          />
        </Form.Group>
      </>
    </div>
  );
};
