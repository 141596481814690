import {useCallback, useEffect, useRef, useState} from "react";
import {Container} from "react-bootstrap";
import {DeliveryModal} from "../DeliveryModal";
import {useOnError} from "../../../hooks/useOnError";
import {useDeliveryService} from "../../../hooks/services/useDeliveryService";
import {fitPoints} from "../../../util/maps";
import {useUpdateEffect} from "../../../hooks/useUpdateEffect";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";
import CourierSearchListing from "./CourierSearchListing";
import CourierSearchMapAndSearchBar from "./CourierSearchMapAndSearchBar";
import { useLoadingContext } from "../../../contexts/LoadingContext";
import useAppCheck from "../../../hooks/useAppCheck";

export default function CourierSearch() {
  const onError = useOnError();
  const deliveryService = useDeliveryService();

  const navigate = useNavigate();
  const {orderId = null} = useParams();

  const [searchParams, setSearchParams] = useState(null);
  const mapRef = useRef(null);
  const mapParentRef = useRef(null);
  const [coordinates, setCoordinates] = useState(null);
  const [deliveries, setDeliveries] = useState([]);
  const [activeDelivery, setActiveDelivery] = useState(null);
  const [searchBounds, setSearchBounds] = useState({
    ne: {latitude: 90, longitude: 180}, sw: {latitude: -90, longitude: -180}
  });
  const [highlightCoordinates, setHighlightCoordinates] = useState(null);
  const [highlightedMarkerPosition, setHighlightedMarkerPosition] = useState(null);
  const registeredMarkers = useRef();

  const { addLoading, removeLoading } = useLoadingContext();

  useUpdateEffect(([deliveryService, orderId]) => () => {
    if (!orderId) {
      setActiveDelivery(null);
      return;
    }

    let cancel = false;
    (async () => {
      addLoading("deliveryById");
      const delivery = (await deliveryService.getDeliveriesByOrderId(orderId));
      removeLoading("deliveryById");

      if (cancel) {
        return;
      }

      if (!delivery) {
        toast.error("Kuljetusta ei löytynyt");
        return;
      }

      setActiveDelivery(delivery);
    })();
    return () => {
      cancel = true;
    };
  }, [deliveryService, orderId], [orderId]);

  const search = useCallback(async params => {
    setSearchParams(params);
    try {
      addLoading("deliverySearch");
      const newDeliveries = (
        await deliveryService.search(
          {...params, open: true, idAndOriginOnly: true})
      ).map(d => ({
        longitude: d.origin.location.coordinates[0],
        latitude: d.origin.location.coordinates[1],
        _id: d._id
      }));
      removeLoading("deliverySearch");

      setDeliveries(newDeliveries ?? []);
      if (newDeliveries.length > 0) {
        fitPoints(mapRef.current, newDeliveries);
      } else {
        mapRef.current.setCenter(new window.google.maps.LatLng(64.5, 25));
        mapRef.current.setZoom(4);
      }
    } catch (e) {
      removeLoading("deliverySearch");
      onError(e);
    }
  }, [deliveryService, onError]);

  useUpdateEffect(([search, setSearchBounds, searchBounds]) => _ => {
    search({}).then(() => setSearchBounds({...searchBounds}));
  }, [search, setSearchBounds, searchBounds], []);

  const refreshAllDeliveries = useCallback(async () => {
    console.log("REFRESH C");
    await search(searchParams);
  }, [searchParams, search]);

  useEffect(() => {
    if (!highlightedMarkerPosition) {
      return;
    }

    const markerEntry = registeredMarkers?.current?.find(
      ({options: {position: {lat: latitude, lng: longitude}}}) =>
        latitude === highlightedMarkerPosition.latitude
        && longitude === highlightedMarkerPosition.longitude
    );

    if (!markerEntry) {
      return;
    }

    markerEntry.marker.setAnimation(window.google.maps.Animation.BOUNCE);

    return () => {
      markerEntry.marker.setAnimation(null);
    };
  }, [highlightedMarkerPosition]);

  const refreshDeliveries = useCallback((ids) => {
    (async () => {
      await deliveryService.getDeliveriesByIds(ids, true);
    })();
  }, [deliveryService]);

  const screenIsXl = window.innerWidth >= 1200;

  let listingMargin = 0;
  if(!screenIsXl && mapParentRef.current) {
    listingMargin = mapParentRef.current.clientHeight + 16;
  }

  const [isMobileApp] = useAppCheck();

  return (
    <Container id="search" style={{padding: 0}}>
      <div className="main" style={{
        display: "grid",
        ...(isMobileApp? {
          padding: 0,
          display: "block",
        } : {})
      }}>
        {
          ((elements) => {
            if(!isMobileApp) {
              return elements;
            }

            return elements.reverse();
          })(
            [
              <div style={{gridArea: "1 / 1 / 2 / 2",
                marginTop: isMobileApp? 0 : listingMargin}}>
                <CourierSearchListing
                  {...{
                    coordinates,
                    highlightCoordinates, setHighlightCoordinates,
                    setHighlightedMarkerPosition,
                    searchBounds,
                    deliveries,
                    mapRef,
                    refreshAllDeliveries,
                    refreshDeliveries,
                  }}
                />
              </div>,
              <div style={{gridArea: "1 / 1 / 2 / 2", pointerEvents: "none",}}>
                <CourierSearchMapAndSearchBar
                  {...{
                    coordinates, setCoordinates,
                    setSearchBounds,
                    setHighlightCoordinates,
                    mapRef,
                    mapParentRef,
                    search,
                    deliveries,
                    registeredMarkers,
                    screenIsXl
                  }}
                />
              </div>
            ]
          )
        }

        <DeliveryModal
          delivery={activeDelivery}
          close={() => navigate("/courier/search")}
          refreshAllDeliveries={refreshAllDeliveries}
        />
      </div>
    </Container>
  );
}



