import { useEffect, useState } from "react";
import { Container, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { SubmitButton } from "../../SubmitButton";
import { getMunicipalities } from "../../../util/publicapis";
import { DeliveryStatus } from "../../../util/constants";
import { ConfirmationModal } from "./ConfirmationModal";
import { useOnError } from "../../../hooks/useOnError";
import { toast } from "react-toastify";
import { useDeliveryService } from "../../../hooks/services/useDeliveryService";
import { useNavigate } from "react-router-dom";
import {
  AddressInput,
  GoodsInput,
  OffloadingInput,
  PriceInput,
  WaybillInput,
  DateRangeInputs
} from "./CreateDeliveryFormElements";
import Wizard from "./Wizard";
import { FormErrorMessage } from "../../form/FormErrorMessage";
import { useVerifyContext } from "../../../contexts/VerifyContext";
import { ConfirmModal } from "../../common/ConfirmModal";
import { useUserContext } from "../../../contexts/UserContext";
import { getAmountWithTax, RemovePotentialTax } from "../../common/PriceComponents";

export default function CreateDelivery(props) {
  const { delivery, wizardOn } = props;
  if (!delivery) {
    return (
      <Container>
        <DeliveryForm wizardOn={wizardOn} />
      </Container>
    );
  } else {
    return (
      <DeliveryForm {...props}></DeliveryForm>
    );
  }
}

function DeliveryForm({
  delivery, wizardOn, fullWidth, disabledFields = [], close, refresh
}) {
  const onError = useOnError();
  const deliveryService = useDeliveryService();
  const { user } = useUserContext();
  const navigate = useNavigate();
  const { setShowEmailVerifyWarn } = useVerifyContext();
  const [counterOfferWarning, setCounterOfferWarning] = useState({ showConfirm: false, data: {} });

  const props = {};
  if (delivery?.goods) {
    props.defaultValues = {
      ...delivery,
      price: delivery.price ? getAmountWithTax(delivery.price, user) : "",
      dateRange: {
        from: new Date(delivery.dateRange.from),
        to: new Date(delivery.dateRange.to)
      }
    };
  }

  const [wizard, setWizard] = useState(wizardOn);

  const {
    register,
    handleSubmit,
    setValue,
    formState,
    getValues,
    control,
    trigger,
    clearErrors,
    watch
  } = useForm({
    reValidateMode: "onSubmit",
    ...props
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "goods",
  });

  const [municipalities, setMunicipalities] = useState([]);
  const [addedDelivery, setAddedDelivery] = useState(null);


  useEffect(() => {
    getMunicipalities("fi")
      .then(municipalities => {
        setMunicipalities(municipalities);
      });
  }, []);

  const addOrModifyDelivery = async (data) => {
    data.price = RemovePotentialTax(data.price, user);
    //If creating new one and not editing
    if (!delivery) {
      data.status = DeliveryStatus.OPEN;
      let result;
      try {
        result = await deliveryService.create(data);
        navigate("/consumer/deliveries");
      } catch (e) {
        if (e.response.status === 403) {
          setShowEmailVerifyWarn(true);
        } else {
          onError(e);
        }
        return;
      }
      setAddedDelivery(result);
    } else {
      try {
        await deliveryService.modify(delivery.id, data);
      } catch (e) {
        onError(e);
        return;
      }
      toast.success("Kuljetuksen tietoja muutettu onnistuneesti");
      close();
      refresh();
    }
  };

  const checkForCounterOffers = (data) => {
    if (data.counterOffers?.length > 0) {
      setCounterOfferWarning({ showConfirm: true, data });
    } else {
      addOrModifyDelivery(data);
    }
  };

  if (!municipalities) {
    return <h3>Ladataan...</h3>;
  }

  if (wizard) {
    return (
      <Wizard
        shutWizard={() => setWizard(false)}
        user={user}
        triggerValidation={trigger}
        setValue={setValue}
        delivery={delivery}
        municipalities={municipalities}
        register={register}
        append={append}
        remove={remove}
        fields={fields}
        getValues={getValues}
        disabledFields={disabledFields}
        errors={formState.errors}
        control={control}
        clearErrors={clearErrors}
        watch={watch}
      />
    );
  }

  return (
    <Row style={{ justifyContent: "center" }}>
      <Form
        onSubmit={handleSubmit((data) => checkForCounterOffers(data))}
        className={fullWidth ? "col-12" : "col-sm-8 col-md-6 mb-2"}
      >
        {
          user.customerType === "company" && (
            <WaybillInput
              register={register}
              disabledFields={disabledFields}
            />
          )
        }
        <Form.Group className="mb-3">
          <AddressInput
            title="origin"
            setValue={setValue}
            delivery={delivery}
            municipalities={municipalities}
            register={register}
            disabledFields={disabledFields}
            getValues={getValues}
            errors={formState.errors}
            control={control}
            clearErrors={clearErrors}
          />
          <Form.Group className="mb-2">
            <Form.Label htmlFor="senderPhonenumber">Lähettäjän puhelinnumero</Form.Label>
            <InputGroup>
              <FormControl
                id="senderPhonenumber"
                type="text"
                isInvalid={formState.errors.senderPhonenumber}
                onInput={() => clearErrors("senderPhonenumber")}
                {...register("senderPhonenumber", {
                  required: "Vaadittu kenttä"
                })}
              />
            </InputGroup>
            <FormErrorMessage
              error={formState.errors.senderPhonenumber}
              errorMessage={formState.errors.senderPhonenumber?.message}
            />
          </Form.Group>
          <AddressInput
            title="destination"
            setValue={setValue}
            delivery={delivery}
            municipalities={municipalities}
            register={register}
            disabledFields={disabledFields}
            getValues={getValues}
            errors={formState.errors}
            control={control}
            clearErrors={clearErrors}
          />
          <Form.Group className="mb-2">
            <Form.Label htmlFor="recipientPhonenumber">Vastaanottajan puhelinnumero</Form.Label>
            <InputGroup>
              <FormControl
                id="recipientPhonenumber"
                type="text"
                isInvalid={formState.errors.recipientPhonenumber}
                onInput={() => clearErrors("recipientPhonenumber")}
                {...register("recipientPhonenumber", {
                  required: "Vaadittu kenttä"
                })}
              />
            </InputGroup>
            <FormErrorMessage
              error={formState.errors.recipientPhonenumber}
              errorMessage={formState.errors.recipientPhonenumber?.message}
            />
          </Form.Group>
          <GoodsInput
            register={register}
            fields={fields}
            append={append}
            remove={remove}
            disabledFields={disabledFields}
            errors={formState.errors}
            clearErrors={clearErrors}
          />
          <br></br>
          <DateRangeInputs
            errors={formState.errors}
            clearErrors={clearErrors}
            disabledFields={disabledFields}
            watch={watch}
            control={control}
          />
          <OffloadingInput
            register={register}
            disabledFields={disabledFields}
            user={user}
          />
          <PriceInput
            register={register}
            disabledFields={disabledFields}
            errors={formState.errors}
            clearErrors={clearErrors}
            notOpenStatus={delivery && delivery?.status !== DeliveryStatus.OPEN}
          />
          <Form.Label htmlFor="info">Lisätiedot</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            {...register("info")}
            disabled={disabledFields.includes("info")}
          />
        </Form.Group>
        {
          !disabledFields.includes("save") &&
          <SubmitButton
            formState={formState}
            submitValue={!delivery ? "Luo tilaus" : "Tallenna"}
          />

        }
      </Form>
      <ConfirmationModal delivery={addedDelivery} close={() => setAddedDelivery(null)} />
      <ConfirmModal
        isOpen={counterOfferWarning.showConfirm}
        heading={"Kuljetuksella on vastatarjouksia"}
        confirmationText={
          `Kuljetuksella olevat vastatarjoukset poistetaan
          muokkauksen yhteydessä. Haluatko varmasti jatkaa?`
        }
        confirmAction={() => addOrModifyDelivery(counterOfferWarning.data)}
        confirmButtonText={"Jatka"}
        close={() => setCounterOfferWarning({ showConfirm: false, data: {} })}
      />
    </Row>
  );
}
