import axios from "axios";

export async function getMunicipalities(language) {
  const res = await axios.get(
    "https://rajapinnat.ymparisto.fi/api/Hakemistorajapinta/1.0/odata/Kunta"
  );
  const kunnat = res.data.value;

  if(language === "fi" || language === "en") {
    return kunnat.map(kunta => kunta.Nimi);
  } else if (language === "se") {
    return kunnat.map(kunta => kunta.NimiRuo);
  }
}