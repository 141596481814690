import {useEffect, useRef} from "react";

export const useUpdateEffect = (fnGen, deps, updateDeps) => {
  const fnRef = useRef();

  useEffect(() => {
    fnRef.current = fnGen(deps);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    if(fnRef.current) {
      fnRef.current(updateDeps);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, updateDeps);
};