import { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { useOrganizationService } from "../../../hooks/services/useOrganizationService";
import { useOnError } from "../../../hooks/useOnError";

export const InviteMembers = ({
  refreshOrganization
}) => {
  const [phoneNumberInput, setPhoneNumberInput] = useState("");

  const onError = useOnError();

  const organizationService = useOrganizationService();

  const AddToOrganization = async () => {
    try {
      await organizationService.addCourier({ phoneNumber: phoneNumberInput });
      refreshOrganization();
    } catch (e) {
      onError(e);
    }
  };

  return (
    <Row style={{ justifyContent: "space-between" }}>
      <Col xs={12} lg={7}>
        <Row style={{ alignItems: "flex-end" }}>
          <Col xs={8} md={8}>
            <Form.Group className="mb-3">
              <Form.Label htmlFor="email">Uuden jäsenen puhelinnumero</Form.Label>
              <PhoneInput
                value={phoneNumberInput}
                onChange={setPhoneNumberInput}
                containerStyle={{
                  width: "100%"
                }}
                inputStyle={{
                  width: "100%"
                }}
                autoFormat={true}
                specialLabel="Puhelinnumero"
                preferredCountries={["fi"]}
                country={"fi"}
                masks={{ fi: ".. ......." }}
                placeholder={"+358 45 1234567"}
              />
            </Form.Group>
          </Col>
          <Col
            xs={4}
            md={4}
            className="mb-3"
            style={{
              display: "flex",
              justifyContent: "flex-end"
            }}
          >
            <Button
              onClick={AddToOrganization}
              className="btn-primary-color"
            >
              Lisää
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};