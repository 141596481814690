import Modal from "react-modal";
import moment from "moment";
import { useState } from "react";
import {Button, Row, Col} from "react-bootstrap";
import {useOnError} from "../../../hooks/useOnError";
import {toast} from "react-toastify";
import {useDeliveryService} from "../../../hooks/services/useDeliveryService";
import {CurrencyWithTax, AmountWithTax} from "../../common/PriceComponents";
import { useUserContext } from "../../../contexts/UserContext";
import {defaultModalStyles as modalStyles} from "../../../styles/defaultModalStyles";
import VismaPayInfo from "./VismaPayInfo";

export default function AcceptCounterOfferModal(props) {
  const {delivery, offerId, close, refresh} = props;
  const deliveryService = useDeliveryService();

  const offer = delivery?.counterOffers.find((offer) => offer._id === offerId);

  const { user } = useUserContext();

  const [displayPayInfo, setDisplayPayInfo] = useState(false);

  const onError = useOnError();

  const canBill = user && user?.billingInformation?.accountStatus === "confirmed";

  // TODO: Show courier information
  return <Modal
    isOpen={Boolean(delivery && offer)}
    onRequestClose={close}
    style={modalStyles}
  >
    {displayPayInfo ?
      <VismaPayInfo
        delivery={delivery}
        offerId={offerId}
        setDisplayPayInfo={setDisplayPayInfo}
      /> :
      (delivery && offer) ? <>
        <h4>{delivery.privateCustomer ? "Maksa ja h" : "H"}yväksy vastatarjous</h4>
        <h6>Toimituspäivä: {moment(offer.date).format("D.M.YYYY")}</h6>
        <h6>
          Hinta: <AmountWithTax amt={offer.amount}/>
          <CurrencyWithTax/>
        </h6>
        <Row>
          { !delivery.privateCustomer ?
            <Col
              xs={6}
              lg={4}
              xl={3}
              title={
                !canBill ?
                  `Laskulla tilaamiseen tarvitaan ylläpidon hyväksyntä.
                  Odota hyväksyntää tai maksa heti.` : ""
              }
            >
              <Button
                className="btn-primary-color"
                onClick={async _ => {
                  try {
                    await deliveryService.acceptCounterOffer(delivery._id, offer._id);
                    toast.success("Vastatarjous hyväksytty onnistuneesti");
                    close();
                    refresh().then();
                  } catch (e) {
                    onError(e);
                  }
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "1rem"
                }}
                disabled={!canBill}
              >
                <span className="material-symbols-outlined">request_quote</span>
                <span>Tilaa laskulle</span>
              </Button>
            </Col> :
            null
          }
          <Col xs={6}>
            <Button
              className="btn-primary-color"
              onClick={() => {
                setDisplayPayInfo(true);
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "1rem",
              }}
            >
              <span className="material-symbols-outlined">payment</span>
              <span>Maksa heti</span>
            </Button>
          </Col>
        </Row>
      </> : []
    }
  </Modal>;
}