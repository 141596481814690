import { Spinner } from "react-bootstrap";

export const LoadingOverlay = () => {
  return (
    <div
      style={{
        position: "absolute",
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 10,
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        width: "100vw"
      }}
    >
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};