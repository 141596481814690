import axios from "axios";
import {useAuth} from "../useAuth";
import {useCallback} from "react";

export const usePaymentService = () => {
  const auth = useAuth();
  const basePath = "/api/payments";

  return useCallback(() => ({
    create: async (delivery, offer) =>
      (await axios.post(`${basePath}/create-charge`, {delivery, offer}, auth))?.data,
    updateStatus: async data => (await axios.put(`${basePath}/status`, data, auth))?.data
  }), [auth])();
};