import "./Gradient.css";

export default function GradientDiv (props) {
  const {onClick, children, className, containerStyle = {}, ...rest} = props;
  const classes = 
  `gradient_wrapper ${onClick ? "gradient_clickable" : ""}`;
  return (
    <div
      className={classes}
      onClick={onClick}
      style={containerStyle}
    >
      <div className={`gradient_child ${className}`} {...rest}>
        {children}
      </div>
    </div>
  );
}