import {useCallback, useEffect, useMemo, useRef} from "react";
import {Col, Row} from "react-bootstrap";
import SearchBar from "./SearchBar";
import Map from "../../map/Map";
import {useState} from "react";
import useAppCheck from "../../../hooks/useAppCheck";

export default function CourierSearchMapAndSearchBar(
  {
    coordinates, setCoordinates,
    setSearchBounds,
    setHighlightCoordinates,
    search,
    mapRef,
    mapParentRef,
    deliveries,
    registeredMarkers,
    screenIsXl
  }
) {
  const [mapZoom, setMapZoom] = useState(null);

  const mapIdleCallback = useCallback(async map => {
    const {center, zoom} = map;
    const [longitude, latitude] = [center.lng(), center.lat()];

    const [coordinatesChanged, zoomChanged] = await Promise.all([
      new Promise(resolve => {
        setCoordinates(oldCoordinates => {
          const coordinatesChanged = !oldCoordinates
            || Math.abs(longitude - oldCoordinates?.longitude) > 0.0001
            || Math.abs(latitude - oldCoordinates?.latitude) > 0.0001;

          resolve(coordinatesChanged);

          if (coordinatesChanged) {
            return {latitude, longitude};
          }

          return oldCoordinates;
        });
      }),
      new Promise(resolve => {
        setMapZoom(oldZoom => {
          const zoomChanged = oldZoom !== zoom;

          resolve(zoomChanged);

          return zoom;
        });
      })
    ]);

    if (coordinatesChanged || zoomChanged) {
      const bounds = map.getBounds();
      const [ne, sw] = [bounds.getNorthEast(), bounds.getSouthWest()];
      setSearchBounds({
        ne: {
          latitude: ne.lat(),
          longitude: ne.lng()
        },
        sw: {
          latitude: sw.lat(),
          longitude: sw.lng()
        }
      });
    }
  }, [setCoordinates, setSearchBounds]);


  // Marker click handler
  const clickHandlerRef = useRef();
  useEffect(() => {
    clickHandlerRef.current = async (latitude, longitude, _id) => {
      mapRef.current.panTo(
        new window.google.maps.LatLng(latitude, longitude)
      );
    };
  }, [mapRef, clickHandlerRef]);

  const markers = useMemo(() => deliveries.map(({latitude, longitude, _id}) => ({
    position: {
      lat: latitude, lng: longitude
    },
    deliveryId: _id,
    onClick: () => clickHandlerRef.current(latitude, longitude, _id),
    onMouseOver: () => setHighlightCoordinates({latitude, longitude}),
    onMouseOut: () => setHighlightCoordinates(oldCoordinates => {
      // Don't update if new coordinates are set
      if (oldCoordinates?.latitude !== latitude || oldCoordinates?.longitude !== longitude) {
        return oldCoordinates;
      }

      return null;
    })
  })), [deliveries, setHighlightCoordinates]);

  const [isMobileApp] = useAppCheck();

  /*
      Attempt to fix a state related bug. Doesn't work perfectly.
      https://github.com/facebook/react/issues/18178
   */
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    setFirstRender(false);
  }, [firstRender]);
  if(firstRender) {
    return null;
  }

  const MapElement = <Map
    style={{
      width: "100%",
      aspectRatio: 16 / 9,
      borderRadius: 5,
      marginTop: 25,
      pointerEvents: "auto",
      ...(isMobileApp? {
        height: window.innerHeight/2.5,
        position: "fixed",
        top: "0",
        left: 0,
        margin: 0,
      } : false)
    }}
    scaleControl={true}
    zoomControl={true}
    streetViewControl={false}
    disableDefaultUI={true}
    draggable={true}
    scrollwheel={true}
    panControl={true}
    center={{
      lat: coordinates?.latitude || 64.5,
      lng: coordinates?.longitude || 26
    }}
    defaultZoom={mapZoom || 4}
    onRefChange={map => mapRef.current = map}
    onIdle={mapIdleCallback}
    markers={markers}
    onMarkerChange={markers => registeredMarkers.current = markers}
  />;

  return <div
    style={{
      position: screenIsXl ? "sticky" : "relative",
      top: 0,
      zIndex: 100,
    }}
  >
    {
      isMobileApp? MapElement : null
    }
    <Row style={{
      ...(isMobileApp? {
        paddingLeft: 12,
        paddingRight: 12,
      } : {})
    }}>
      <SearchBar doSearch={search} style={{
        backgroundColor: "#FFF",
        borderRadius: 5,
        paddingTop: 6,
        pointerEvents: "auto",
        boxShadow: "0px 1px 0px 0px rgba(0,0,0,0.22)"
      }}/>
    </Row>
    <Row ref={mapParentRef}>
      <Col xl={6}/>
      <Col xl={6}>
        {
          !isMobileApp? MapElement : null
        }
      </Col>
    </Row>
  </div>;
}
