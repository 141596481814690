import { Stack } from "react-bootstrap";
import { GoogleLogin } from "@react-oauth/google";
import { useState } from "react";
import { SocialMediaRegister } from "./register/SocialMediaRegister";
import { useOnError } from "../hooks/useOnError";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useCustomerService } from "../hooks/services/useCustomerService";
import useToken from "../hooks/useToken";
import { useLoadingContext } from "../contexts/LoadingContext";

export const SocialMediaLogin = () => {
  const [socialRegister, setSocialRegister] = useState(false);
  const [profileData, setProfileData] = useState(null);
  const [creds, setCreds] = useState(null);

  const onError = useOnError();
  const [, setToken] = useToken();
  const navigate = useNavigate();
  const customerService = useCustomerService();
  const { addLoading, removeLoading } = useLoadingContext();

  const handleClose = () => {
    setCreds(null);
    setProfileData(null);
    setSocialRegister(false);
  };

  const onSuccessLogin = async (socialMedia, response) => {
    try {
      let resp;
      addLoading("socialLogin");
      if (socialMedia === "facebook") {
        resp = await customerService.socialMediaLogin({
          socialMedia,
          credentials: response
        });
      } else if (socialMedia === "google") {
        resp = await customerService.socialMediaLogin({
          socialMedia,
          credentials: {
            accessToken: response.credential
          }
        });
      }
      if (resp.loginStatus === "success") {
        setToken(resp.loginToken);

        toast.success("Kirjautuminen onnistui");
        navigate("/consumer/deliveries");
      } else if (resp.loginStatus === "register") {
        setProfileData(resp.profileData);
        setSocialRegister(true);
        setCreds(response);
      }
      removeLoading("socialLogin");
    } catch (e) {
      removeLoading("socialLogin");
      onError(e);
    }
  };

  return (
    <>
      <div style={{ padding: "40px 0px" }}>
        <div className="center">
          <div className="line" />
          <div className="or">TAI</div>
        </div>
      </div>
      <Stack
        className="center"
        gap={3}
      >
        <GoogleLogin
          onSuccess={(response) => onSuccessLogin("google", response)}
          onError={() => {
            console.log("Login Failed");
          }}
          shape="circle"
          locale="fi_FI"
        />
        {/*<FacebookLogin
          appId={process.env.REACT_APP_FACEBOOK_APP_ID}
          onSuccess={(response) => onSuccessLogin("facebook", response)}
          onFail={(error) => {
            console.log("Login Failed!", error);
          }}
          children="Kirjaudu Facebookilla"
          style={{
            backgroundColor: "#4267b2",
            color: "#fff",
            fontSize: "14px",
            padding: "8px 20px",
            border: "none",
            borderRadius: "20px",
          }}
        />*/}
      </Stack>
      <SocialMediaRegister
        isOpen={socialRegister}
        close={handleClose}
        profileData={profileData}
        creds={creds}
      />
    </>
  );
};